import useApi from 'components/apiRequest/useApi';
import React, { createContext } from 'react';

// Create the context
export const DataContext = createContext();

// Provider component
export const DataProvider = ({ children }) => {
  const { data, loading, error } = useApi({ url: '/general/config' });
  return (
    <DataContext.Provider value={{ data, loading, error }}>
      {children}
    </DataContext.Provider>
  );
};
