import {
  Backdrop,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Select,
  TextField,
  TextareaAutosize,
  makeStyles,
} from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Button2 from 'react-bootstrap/Button';
import { Button, Tooltip } from '@mui/material';
import { Translate } from 'react-redux-i18n';
import {
  Link,
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import {
  SERVER,
  addLabourAddressDetails,
  addLabourBankDetails,
  addLabourFamilyDetails,
  addLabourPersonalDetails,
  erroraddLabourBankDetails,
  addLabour90DaysDetails,
  getInspectorDetails,
  finalSubmit,
  getApplicationNo,
  getFile,
  getUser,
  getUserRegistrationDetails,
  saveAcknowledgementId,
  updateReviewData,
  uploadFile,
  setLocaleWithFallback,
} from 'store/actions/user.actions';
import { Required, getTokenCookie, showToast } from 'utils/tools';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png';
import language from 'assets/images/translate (1).svg';
import Header from './Header';
import back from 'assets/images/Icon awesome-arrow-circle-left.svg';
import closeIcon from 'assets/images/close.png';
import aadhar from 'assets/images/Aadhar black-01.svg';
import personal from 'assets/images/f1-01.svg';
import pan from 'assets/images/PAN Black-01.svg';
import mobilepic from 'assets/images/Icon awesome-mobile-alt.svg';
// import checkGreen from 'assets/images/Tick-01.svg'
import checkGreen from 'assets/images/Tick-01.png';
// import pan from 'assets/images/credit-card.svg'
import edit from 'assets/images/edit-01.svg';
import male from 'assets/images/Mask Group 1.svg';
import female from 'assets/images/Mask Group 2.svg';
import checkgreen from 'assets/images/Subtraction 1.svg';
import checkgrey from 'assets/images/Subtraction 2.svg';
import nintydays from 'assets/images/f5-01.svg';
import family from 'assets/images/f3-01.svg';
// import address from 'assets/images/apartment.svg'
import address from 'assets/images/f2-01.svg';
import bank from 'assets/images/f4-01.svg';
import {
  AddCircleRounded,
  CameraAlt,
  CheckCircle,
  Close,
  PhoneAndroid,
  Visibility,
} from '@material-ui/icons';
import Webcam from 'react-webcam';
import folder from 'assets/images/Folderwhite-01-01.svg';
// import webcam from 'assets/images/web-cam.svg'
import webcam from 'assets/images/web cam-01.svg';

import radioon from 'assets/images/Icon ionic-ios-radio-button-on-1.svg';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import uploadFileAPI from 'utils/fileupload';
import FolderIcon from '@mui/icons-material/Folder';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

import avatar from 'assets/images/avatar.png';
import moment from 'moment';
import VerifiedIcon from '@mui/icons-material/Verified';
import { useDispatch, useSelector } from 'react-redux';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CancelIcon from '@mui/icons-material/Cancel';
import Select2 from 'react-select';
import mobile from 'assets/images/Phone 1-01.svg';
import { hasEmptyPropertiesEmployerDetails } from 'utils/checkEmptyArray';
import SimpleBackdrop from 'components/loadingScreen/Backdrop';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Moment from 'react-moment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const Review = () => {
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: '#ffffffee',
      color: '#000',
      display: 'block',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    backdropCheck: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#6CC17A',
    },
    backdropCheckp: {
      fontSize: '30px',
      fontWeight: 'bold',
    },
  }));

  const { labourUserID } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const users = useSelector((state) => state.users);

  // const [certificateDocs, setcertificateDocs] = React.useState({});
  const [educationDocs, setEducationDocs] = React.useState(null);
  const [educationDocBase64, setEducationDocBase64] = useState(null);

  const [bankPassbookDoc, setBankPassbookDoc] = React.useState(null);
  const [bankPassbookDocBase64, setBankPassbookDocBase64] = useState(null);

  const [nomineeBankPassbookDoc, setNomineeBankPassbookDoc] =
    React.useState(null);
  const [nomineeBankPassbookDocBase64, setNomineeBankPassbookDocBase64] =
    useState(null);

  const [certificateDoc, setCertificateDoc] = React.useState(null);
  const [certificateDocBase64, setCertificateDocBase64] = useState(null);

  const [rationCardDoc, setRationCardDoc] = React.useState(null);
  const [rationCardDocBase64, setRationCardDocBase64] = useState(null);

  const [ackDetails, setAckDetails] = useState(null);

  const [personalDetails, setPersonalDetails] = useState(null);
  const [addressDetails, setAddressDetails] = useState(null);
  const [bankDetails, setBankDetails] = useState(null);
  const [familyDetails, setFamilyDetails] = useState(null);
  const [rationCardDetails, setRationCardDetails] = useState(null);
  const [employerDetails, setEmployerDetails] = useState(null);
  const [certificateDetails, setCertificateDetails] = useState(null);
  const [userPhotoID, setUserPhotoID] = useState(null);
  const [base64UserPhoto, setBase64UserPhoto] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [content, setContent] = useState(null);

  const [selfDeclarationDoc, setSelfDeclarationDoc] = useState([]);
  const selfDeclarationFileRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [AckLetterImg, setAckLetterImg] = React.useState('');

  const [dec1, setDec1] = useState(false);
  const [dec2, setDec2] = useState(false);
  const [dec3, setDec3] = useState(false);
  const [dec4, setDec4] = useState(false);
  const classes = useStyles();

  const [permanentAddress, setPermanentAddress] = useState({
    state: null,
    district: null,
    taluk: null,
    city: null,
    village: null,
    streetName: null,
    houseNumber: null,
    landmark: null,
    pinCode: null,
  });

  const handleViewImage = (event, from) => {
    setAnchorEl(event.currentTarget);
    setContent(from);
  };

  // console.log(
  //   'BANKDE',
  //   bankDetails &&
  //     bankDetails.length > 0 &&
  //     bankDetails.filter((data) => data.is_nominee),
  // );

  // console.log(
  //   'BANKDE2',
  //   bankDetails && bankDetails.length > 0 && bankDetails.map((data) => data),
  // );

  const handlePDF = (event, from, fileID) => {
    if (from === 'eduDoc') {
      const url = `${SERVER}/upload/file/${fileID}`;
      window.open(url, '_blank', 'noopener');
      // let pdfWindow = window.open('');
      // pdfWindow.document.write(
      //   "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
      //     encodeURI(educationDocBase64) +
      //     "'></iframe>",
      // );
    } else if (from === 'bankDoc') {
      const url = `${SERVER}/upload/file/${fileID}`;
      window.open(url, '_blank', 'noopener');
      // let pdfWindow = window.open('');
      // pdfWindow.document.write(
      //   "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
      //     encodeURI(bankPassbookDocBase64) +
      //     "'></iframe>",
      // );
    } else if (from === 'nomineeBankDoc') {
      const url = `${SERVER}/upload/file/${fileID}`;
      window.open(url, '_blank', 'noopener');
      // let pdfWindow = window.open('');
      // pdfWindow.document.write(
      //   "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
      //     encodeURI(nomineeBankPassbookDocBase64) +
      //     "'></iframe>",
      // );
    } else if (from === 'certificateDoc') {
      const url = `${SERVER}/upload/file/${fileID}`;
      window.open(url, '_blank', 'noopener');
      // let pdfWindow = window.open('');
      // pdfWindow.document.write(
      //   "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
      //     encodeURI(certificateDocBase64) +
      //     "'></iframe>",
      // );
    } else if (from === 'rationCardDoc') {
      // let pdfWindow = window.open('');
      const url = `${SERVER}/upload/file/${fileID}`;
      window.open(url, '_blank', 'noopener');
      // pdfWindow.document.write(
      //   "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
      //     encodeURI(rationCardDocBase64) +
      //     "'></iframe>",
      // );
    }
  };

  console.log({ users });

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const callRegistrationDetails = () => {
    if (labourUserID) {
      const payLoad = {
        board_id: 1,
        key: 'user_id',
        value: labourUserID,
        procedure_name: 'all',
      };

      axios
        .post(`${SERVER}/user/get-user-registration-details`, payLoad, {
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
        })
        .then(({ data }) => {
          const { data: _data } = data;

          const applicationDetails = _data.application_details[0];
          const gscNo = _data.employer_details[0].gsc_no;

          if (gscNo) {
            setAckDetails({ ...applicationDetails, gsc_no: gscNo });
          }
        });
    }
  };

  useEffect(() => {
    if (users?.generate_otp?.data?.phone_number)
      dispatch(getUser(users.generate_otp.data.phone_number));
  }, []);

  useEffect(() => {
    if (labourUserID) {
      const payLoad = {
        board_id: 1,
        key: 'user_id',
        value: labourUserID,
        procedure_name: 'all',
      };

      axios
        .post(`${SERVER}/user/get-user-registration-details`, payLoad, {
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
        })
        .then(({ data }) => {
          const { data: _data } = data;

          if (
            _data.personal_details &&
            Array.isArray(_data.personal_details) &&
            _data.personal_details.length > 0
          ) {
            const eduDoc = JSON.parse(
              _data.personal_details[0].qualification_document_id,
            );

            if (Array.isArray(eduDoc) && eduDoc.length > 0) {
              setEducationDocs(eduDoc);
            }

            setUserPhotoID(_data.personal_details[0].user_photo_id);
          }

          if (
            _data.bank_details &&
            Array.isArray(_data.bank_details) &&
            _data.bank_details.length > 0
          ) {
            const bankPassBookDoc = JSON.parse(
              _data.bank_details[0].bank_upload_files_xml,
            );

            if (Array.isArray(bankPassBookDoc) && bankPassBookDoc.length > 0) {
              setBankPassbookDoc(bankPassBookDoc);
            }

            if (_data.bank_details.length > 1) {
              const nomineeBankPassbook = JSON.parse(
                _data.bank_details[1].bank_upload_files_xml,
              );

              if (
                Array.isArray(nomineeBankPassbook) &&
                nomineeBankPassbook.length > 0
              ) {
                setNomineeBankPassbookDoc(nomineeBankPassbook);
              }
            }
          }

          if (
            _data.ration_card_details &&
            Array.isArray(_data.ration_card_details) &&
            _data.ration_card_details.length > 0
          ) {
            const rationCardDocument =
              _data.ration_card_details[0].ration_card_files_xml &&
              JSON.parse(_data.ration_card_details[0].ration_card_files_xml);

            if (
              Array.isArray(rationCardDocument) &&
              rationCardDocument.length > 0
            ) {
              setRationCardDoc(rationCardDocument);
            }
          }

          if (
            _data.certificate_details &&
            Array.isArray(_data.certificate_details) &&
            _data.certificate_details.length > 0
          ) {
            const employmentCertificate =
              _data.certificate_details[0].document_uplaod_files_xml &&
              JSON.parse(
                _data.certificate_details[0].document_uplaod_files_xml,
              );

            if (
              Array.isArray(employmentCertificate) &&
              employmentCertificate.length > 0
            ) {
              setCertificateDoc(employmentCertificate);
            }

            const gscNo = _data.employer_details[0].gsc_no;

            if (gscNo) {
              setAckDetails((prev) => ({
                ...prev,
                gsc_no: gscNo,
              }));
            }
          }

          if (
            _data.application_details &&
            Array.isArray(_data.application_details) &&
            _data.application_details.length > 0
          ) {
            const applicationDetails = _data.application_details[0];

            if (applicationDetails.application_no !== null) {
              setAckDetails((prev) => ({
                ...prev,
                ...applicationDetails,
              }));
            }
          }

          setPersonalDetails(_data.personal_details);
          setAddressDetails(_data.address_details);
          setFamilyDetails(_data.family_details);
          setBankDetails(_data.bank_details);
          setRationCardDetails(_data.ration_card_details);
          setEmployerDetails(_data.employer_details);
          setCertificateDetails(_data.certificate_details);
        });
    }
  }, [labourUserID, dispatch]);

  useEffect(() => {
    if (labourUserID) {
      const payLoad = {
        labour_user_id: labourUserID,
      };
      axios
        .post(`${SERVER}/user/get_ad_details`, payLoad, {
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
        })
        .then((res) => {
          if (res.data.data.length > 0) {
            const { data } = res.data;
            setPermanentAddress((prev) => ({
              ...prev,
              state: data[0].state,
              district: data[0].district,
              taluk: data[0].sub_district,
              city: data[0].district,
              village: data[0].village_city_town,
              streetName: data[0].street ?? '',
              houseNumber: data[0].house_no,
              landmark: data[0].landmark,
              pinCode: data[0].pincode,
            }));
          }
        })
        .catch((err) => {
          if (err) {
            showToast('ERROR', 'Something went wrong');
          }
        });
    }
  }, [labourUserID]);

  useEffect(() => {
    if (educationDocs) {
      // console.log('educationDocs', educationDocs);
      axios
        .get(`${SERVER}/upload/download_base_64/${educationDocs[0].file_id}`)
        .then((res) => {
          setEducationDocBase64(res.data.data.base_64_content);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (bankPassbookDoc) {
      axios
        .get(`${SERVER}/upload/download_base_64/${bankPassbookDoc[0].file_id}`)
        .then(({ data }) => {
          setBankPassbookDocBase64(data.data.base_64_content);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (nomineeBankPassbookDoc) {
      axios
        .get(
          `${SERVER}/upload/download_base_64/${nomineeBankPassbookDoc[0].file_id}`,
        )
        .then((res) => {
          setNomineeBankPassbookDocBase64(res.data.data.base_64_content);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (certificateDoc) {
      axios
        .get(`${SERVER}/upload/download_base_64/${certificateDoc[0].file_id}`)
        .then((res) => {
          setCertificateDocBase64(res.data.data.base_64_content);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (userPhotoID) {
      axios
        .get(`${SERVER}/upload/download_base_64/${userPhotoID}`)
        .then((res) => {
          setBase64UserPhoto(res.data.data.base_64_content);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (rationCardDoc) {
      axios
        .get(`${SERVER}/upload/download_base_64/${rationCardDoc[0].file_id}`)
        .then((res) => {
          setRationCardDocBase64(res.data.data.base_64_content);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [
    educationDocs,
    bankPassbookDoc,
    nomineeBankPassbookDoc,
    certificateDoc,
    userPhotoID,
    rationCardDoc,
  ]);

  const handleSelfDeclarationFile = (ev) => {
    if (
      ev.target.files[0].type === 'image/png' ||
      ev.target.files[0].type === 'image/jpeg' ||
      ev.target.files[0].type === 'image/jpg' ||
      ev.target.files[0].type === 'application/pdf'
    ) {
      if (ev.target.files[0].size < 2000001) {
        const newFiles = [...selfDeclarationDoc, ...ev.target.files];
        setSelfDeclarationDoc(newFiles);
      } else {
        showToast('ERROR', 'Please upload file size less than 2MB');
      }
    } else {
      showToast('ERROR', 'Please upload file in the PNG or JPEG/JPG format');
    }
  };

  const handleRemoveSelfDeclarationFile = (index) => {
    const newFiles = [...selfDeclarationDoc];
    newFiles.splice(index, 1);
    setSelfDeclarationDoc(newFiles);
    selfDeclarationFileRef.current.value = '';
  };

  const uploadFiles = () => {
    if (selfDeclarationDoc && selfDeclarationDoc.length > 0) {
      const promises = [];
      selfDeclarationDoc.forEach((doc) => {
        promises.push(uploadFileAPI(doc));
      });
      Promise.all(promises)
        .then((promiseResponse) => {
          const mappedData = promiseResponse.map((doc) => doc.image);

          insertSubmitRegistration(mappedData);
        })
        .catch((err) => {
          console.log('ERROR', err);
        });
    } else {
      insertSubmitRegistration([]);
    }
  };

  const insertSubmitRegistration = (selfDeclaration) => {
    if (selfDeclarationDoc && selfDeclarationDoc.length > 0) {
      const selfDeclarationDoc = selfDeclaration.map((doc) => ({
        file_id: doc.fileId,
        file_name: doc.originalFileName,
      }));

      const payLoad = {
        labourUserId: labourUserID,
        boardId: 1,
        certificateDetailsId: certificateDetails[0].id,
        driveUserId:
          users && +users?.user?.inspection_id
            ? +users?.user?.inspection_id
            : 0,
        selfDeclaration: selfDeclarationDoc,
      };

      axios
        .post(`${SERVER}/user/registration/submit`, payLoad, {
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
        })
        .then((res) => {
          setSubmitLoading(false);
          setSubmitSuccess(true);
          showToast('SUCCESS', 'Labour registered successfully');

          callRegistrationDetails();
          // setOpenBackdrop(false);
        })
        .catch((err) => {
          if (err.response.data.errors.msg) {
            showToast('ERROR', `${err.response.data.errors.msg}`);
            setSubmitLoading(false);
            setOpenBackdrop(false);
            setSubmitSuccess(false);
          } else {
            showToast('ERROR', 'Something went wrong');
            setSubmitLoading(false);
            setOpenBackdrop(false);
            setSubmitSuccess(false);
          }
        });
    } else {
      const payLoad = {
        labourUserId: labourUserID,
        boardId: 1,
        certificateDetailsId: certificateDetails[0].id,
        driveUserId:
          users && +users?.user?.inspection_id
            ? +users?.user?.inspection_id
            : 0,
        selfDeclaration: [],
      };

      axios
        .post(`${SERVER}/user/registration/submit`, payLoad, {
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
        })
        .then((res) => {
          setSubmitLoading(false);
          setSubmitSuccess(true);
          showToast('SUCCESS', 'Labour registered successfully');

          // callRegistrationDetails();
          // setOpenBackdrop(false);
        })
        .catch((err) => {
          if (err.response.data.errors.msg) {
            showToast('ERROR', `${err.response.data.errors.msg}`);
            setSubmitLoading(false);
            setOpenBackdrop(false);
            setSubmitSuccess(false);
          } else {
            showToast('ERROR', 'Something went wrong');
            setSubmitLoading(false);
            setOpenBackdrop(false);
            setSubmitSuccess(false);
          }
        });
    }
  };

  const handleSubmit = () => {
    if (dec1 && dec2 && dec3 && dec4) {
      if (
        users &&
        (!users.user.inspection_id || users.user.inspection_id === 0)
      ) {
        if (selfDeclarationDoc && selfDeclarationDoc.length > 0) {
          setSubmitLoading(true);
          setOpenBackdrop(true);
          uploadFiles();
        } else {
          showToast('ERROR', 'Please upload self declaration');
        }
      } else {
        setSubmitLoading(true);
        setOpenBackdrop(true);
        uploadFiles();
      }
    } else {
      showToast('ERROR', 'Please accept all the declaration');
    }
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const handleChange = (event) => {
    if (event.target.name === 'language')
      dispatch(setLocaleWithFallback(event.target.value));
  };

  const handleEditProfile = () => {
    if (
      users &&
      users.getUserRegistrationDetails.personal_details &&
      users.getUserRegistrationDetails.personal_details[0]
        .is_renewal_scheme_claimed === 2 &&
      users &&
      users.getUserRegistrationDetails.personal_details &&
      users.getUserRegistrationDetails.personal_details[0]
        .is_renewal_claimed === 1
    ) {
      showToast(
        'ERROR',
        'Edit profile available after the approval or rejection of the schemes',
      );
    } else {
      history.push(`/profile/edit/${labourUserID}`);
    }
  };

  return (
    <>
      {submitLoading ? (
        <Backdrop
          className={classes.backdrop}
          open={openBackdrop}
          onClick={submitLoading ? null : handleCloseBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}

      {submitSuccess ? (
        <Backdrop
          className={classes.backdrop}
          open={openBackdrop}
          onClick={submitLoading ? null : handleCloseBackdrop}
        >
          <div style={{ display: 'block' }}>
            <CheckCircle
              className={classes.backdropCheck}
              style={{ height: 'auto', width: '200px' }}
            />
            <p className="final-success-title">
              <Translate value="success1" />
              <br />
              <Translate value="success2" />
              <br />
              <Translate value="success3" />
            </p>

            <p className="final-success-link-p">
              <Link
                to={`/view/registrationack/${labourUserID}`}
                style={{ flexGrow: '0.5' }}
                target="_blank"
              >
                <p className="final-success-link-p">
                  View Acknowledgement Letter{' '}
                  {/* <span variant="outline-primary" className="final-success-link">
                  {' '}
                  Click Here
                </span> */}
                </p>
              </Link>
            </p>
          </div>
        </Backdrop>
      ) : null}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {content === 'eduDoc' && (
          <img
            width={'300'}
            height={'200'}
            src={`data:image/jpeg;base64,${educationDocBase64}`}
            alt="..."
          />
        )}
        {content === 'bankDoc' && (
          <img
            width={'300'}
            height={'200'}
            src={`data:image/jpeg;base64,${bankPassbookDocBase64}`}
            alt="..."
          />
        )}
        {content === 'nomineeBankDoc' && (
          <img
            width={'300'}
            height={'200'}
            src={`data:image/jpeg;base64,${nomineeBankPassbookDocBase64}`}
            alt="..."
          />
        )}
        {content === 'certificateDoc' && (
          <img
            width={'300'}
            height={'200'}
            src={`data:image/jpeg;base64,${certificateDocBase64}`}
            alt="..."
          />
        )}
        {content === 'rationCardDoc' && (
          <img
            width={'300'}
            height={'200'}
            src={`data:image/jpeg;base64,${rationCardDocBase64}`}
            alt="..."
          />
        )}
      </Popover>
      <Row className="personal-div">
        {/* {submitLoading ? <SimpleBackdrop /> : null} */}
        <Row className="topbar-row">
          <Col xs={12} md={8} lg={9} className="top-bar-col">
            <div className="logo-div-profile">
              <Link to="/dashboard-user">
                <img id="logo" src={logo} alt="..." className="logo-img" />
              </Link>
              <h1 className="logo-text">
                <Translate value="header.title" /> <br />
                <Translate value="header.sub" />
              </h1>
            </div>
          </Col>
          <Col xs={12} md={4} lg={3}>
            <Select
              className="select-language"
              style={{ width: '100%' }}
              variant="outlined"
              labelId="demo-simple-select-required-label"
              value={language}
              name="language"
              displayEmpty
              onChange={handleChange}
            >
              <MenuItem value="">
                <ListItemIcon>
                  <img alt="..." src={language} className="language-img" />
                </ListItemIcon>
                <ListItemText primary="Select Language" />
              </MenuItem>
              {/* <MenuItem value="en">English</MenuItem>
                                    <MenuItem value="ka">Kannada</MenuItem> */}
              <MenuItem value="en">
                <ListItemText primary="English" />
              </MenuItem>
              <MenuItem value="ka">
                <ListItemText primary="ಕನ್ನಡ" />
              </MenuItem>
            </Select>
          </Col>
        </Row>
        {users && users.user.is_approved ? (
          <Row className="d-flex justify-content-center align-items-center">
            <Col xs={12} md={4} lg={3}>
              <Button2
                variant="warning"
                className="w-100"
                onClick={handleEditProfile}
              >
                Click here to edit your profile details
              </Button2>
            </Col>
          </Row>
        ) : null}
        <Row className="final all-details-subtitle-row">
          <Col xs={12} md={12} className="profile-title">
            <img alt="..." src={personal} />

            <h1>
              <Translate value="personalDetails" />
            </h1>
            {/* {!allValues.finalSubmitted ? ( */}
            {(users && users.user.is_sent_approval === true) ||
            submitSuccess ? (
              <p
                style={{
                  backgroundColor: '#F3F6F8',
                  boxShadow: 'none',
                  color: 'black',
                }}
              >
                Submitted{' '}
                <CheckCircle
                  className={classes.backdropCheck}
                  style={{ height: 'auto', width: '20px' }}
                />
              </p>
            ) : (
              <p
                onClick={() =>
                  history.push(`${`/profile/personal/${labourUserID}/edit`}`)
                }
              >
                <Translate value="edityourdetails" />
                <img src={edit} />
              </p>
            )}
            {/* ) : ( */}
            {/* <p
                style={{
                  backgroundColor: '#F3F6F8',
                  boxShadow: 'none',
                  color: 'black',
                }}
              >
                Submitted{' '}
                <CheckCircle
                  className={classes.backdropCheck}
                  style={{ height: 'auto', width: '20px' }}
                />
              </p> */}

            {/* )
            } */}
            {/* <p onClick={() => props.editButtonPressed(1)}>Edit your details<img src={edit}/></p> */}
          </Col>
        </Row>
        {personalDetails && personalDetails.length > 0 && (
          <Row className="form-row-final mt-4">
            <Col xs={12} md={4} className="final-personal-col-1 px-3">
              {/* <Required className="mb-0" title="First Name review" /> */}
              <Required
                className="mb-0"
                title={<Translate value="labourName" />}
              />
              <FormControl fullWidth>
                <TextField
                  name="firstname"
                  value={`${personalDetails[0].first_name} ${
                    personalDetails[0].middle_name ?? ''
                  } ${personalDetails[0].last_name ?? ''}`}
                  disabled
                />
              </FormControl>

              {/* <p className="mt-3 mb-0">Middle Name </p> */}

              {/* <p className="mt-3 mb-0">Enter Your PAN Card Number</p> */}
              <p className="mt-3 mb-0">
                <Translate value="enterYourPANCardNumber" />
              </p>

              <FormControl fullWidth className="pan-div">
                <TextField
                  disabled
                  value={personalDetails[0].pan_no}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img alt="..." src={pan} />
                      </InputAdornment>
                    ),
                    // endAdornment:
                    //     <InputAdornment position="start">
                    //         {
                    //             users.getUserRegistrationDetails.personal_details[0].pancheck ?
                    //                 (<img alt="..." src={checkgreen} />)
                    //                 :
                    //                 (<img alt="..." src={checkgrey} />)
                    //         }
                    //     </InputAdornment>,
                  }}
                />
              </FormControl>

              {personalDetails[0].is_ayushman_card_holder ? (
                <>
                  <p className="mt-3 mb-0">
                    <Translate value="ayushmancardnumber" />
                  </p>
                  <FormControl fullWidth>
                    <TextField
                      value={personalDetails[0].ayushman_card_number}
                      disabled
                    />
                  </FormControl>
                </>
              ) : (
                <FormControl
                  className="mt-2 mb-2 interstateRadio formcontrol9"
                  variant="outlined"
                  fullWidth
                  component="fieldset"
                >
                  <RadioGroup
                    row
                    name="isMigrant"
                    value={
                      personalDetails[0].is_ayushman_card_holder === false
                        ? '0'
                        : '1'
                    }
                  >
                    <div className="d-flex justify-content-center align-align-items-center flex-column">
                      <p className="">
                        <Translate value="ayushmanholder" />
                      </p>
                      <div className="d-flex justify-content-start align-align-items-center flex-row">
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label={<Translate value="yes" />}
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label={<Translate value="no" />}
                        />
                      </div>
                    </div>
                  </RadioGroup>
                </FormControl>
              )}
            </Col>

            <Col xs={12} md={4} className="final-personal-col-2 px-3">
              {/* <Required className="mb-0" title="Enter Your Mobile Number" /> */}
              <Required
                className="mb-0"
                title={<Translate value="enterYourMobileNumber" />}
              />

              <FormControl fullWidth className="formcontrol2">
                <TextField
                  disabled
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneAndroid className="phone-icon" />
                        +91
                      </InputAdornment>
                    ),
                  }}
                  value={personalDetails[0].mobile_no}
                />
              </FormControl>

              {/* <p className="mt-3 mb-0">Enter Email Address <span className="optional">(Optional)</span></p> */}
              <p className="mt-3 mb-0">
                <Translate value="enterEmailAddress" />{' '}
                <span className="optional">(Optional)</span>
              </p>

              <FormControl fullWidth className="formcontrol3">
                <TextField
                  name="email"
                  value={personalDetails[0].mail_id}
                  disabled
                />
              </FormControl>

              {personalDetails[0].is_inter_state_migrant ? (
                <>
                  <p className="mt-3 mb-0">
                    <Translate value="migrantFromState" />
                  </p>
                  <FormControl fullWidth>
                    <TextField value={personalDetails[0].state} disabled />
                  </FormControl>
                </>
              ) : (
                <FormControl
                  className="mb-2 interstateRadio formcontrol9"
                  variant="outlined"
                  fullWidth
                  component="fieldset"
                >
                  <RadioGroup
                    row
                    name="isMigrant"
                    value={
                      personalDetails[0].is_inter_state_migrant === false
                        ? '0'
                        : '1'
                    }
                  >
                    <div className="d-flex justify-content-center align-align-items-center flex-column">
                      <p className="">
                        <Translate value="interstateDistict" />
                      </p>
                      <div className="d-flex justify-content-start align-align-items-center flex-row">
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label={<Translate value="yes" />}
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label={<Translate value="no" />}
                        />
                      </div>
                    </div>
                  </RadioGroup>
                </FormControl>
              )}

              {personalDetails[0].is_inter_district ? (
                <>
                  <p className="mt-3 mb-0">
                    <Translate value="migrantFromDistrict" />
                  </p>
                  <FormControl fullWidth>
                    <TextField
                      value={personalDetails[0].inter_district_name}
                      disabled
                    />
                  </FormControl>
                </>
              ) : (
                <FormControl
                  className="mb-2 interstateRadio formcontrol9"
                  variant="outlined"
                  fullWidth
                  component="fieldset"
                >
                  <RadioGroup
                    row
                    name="isMigrant"
                    value={
                      personalDetails[0].is_inter_district === false ? '0' : '1'
                    }
                  >
                    <div className="d-flex justify-content-center align-align-items-center flex-column">
                      <p className="">
                        <Translate value="interstateMigrantWorker" />
                      </p>
                      <div className="d-flex justify-content-start align-align-items-center flex-row">
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label={<Translate value="yes" />}
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label={<Translate value="no" />}
                        />
                      </div>
                    </div>
                  </RadioGroup>
                </FormControl>
              )}
              <p className="mt-3 mb-0">
                <Translate value="education" />
              </p>
              <FormControl fullWidth>
                <TextField
                  placeholder="Education"
                  name="education"
                  value={personalDetails[0].qualification}
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4} className="final-personal-col-3 px-3">
              <div className="picupload">
                <img
                  src={`data:image/jpeg;base64,${
                    base64UserPhoto && base64UserPhoto
                  }`}
                  width={'180'}
                  height={'160'}
                  alt="..."
                />
              </div>

              <div className="mt-3 final-display-flex">
                {/* <p className="m-0" >Marital Status*</p> */}
                <p className="m-0">
                  <Translate value="maritalStatus" />*
                </p>

                <p className="m-0 p-0">{personalDetails[0].marital_status}</p>
              </div>

              <div className="mt-3 final-display-flex">
                {/* <p className="m-0" >Gender*</p> */}
                <p className="m-0">
                  <Translate value="gender" />*
                </p>
                {personalDetails[0].catalog_value_gender_id === 27 && (
                  <p className="m-0 p-0">
                    <img alt="..." src={male} />
                    <span>Male</span>
                  </p>
                )}
                {personalDetails[0].catalog_value_gender_id === 28 && (
                  <p className="m-0 p-0">
                    <img alt="..." src={female} />
                    <span>Female</span>
                  </p>
                )}
                {personalDetails[0].catalog_value_gender_id === 29 && (
                  <p className="m-0 p-0">
                    <span>Others</span>
                  </p>
                )}
              </div>

              <div className="mt-3 final-display-flex">
                {/* <p className="m-0" >DOB*</p> */}
                <p className="m-0">
                  <Translate value="dOB" />*
                </p>

                <p className="m-0 p-0">
                  <Moment format="DD.MM.YYYY">
                    {personalDetails[0].date_of_birth}
                  </Moment>
                </p>
              </div>

              <div className="mt-3 final-display-flex">
                <p className="m-0">
                  <Translate value="category" />
                </p>
                <p className="m-0 p-0">{personalDetails[0].caste}</p>
              </div>

              <div className="mt-3 final-display-flex">
                {/* <p className="m-0" >Category</p> */}
                <p className="m-0">
                  <Translate value="religion" />
                </p>
                <p className="m-0 p-0">
                  {personalDetails[0].catalog_value_religion_id === 1143
                    ? 'Hindu'
                    : personalDetails[0].catalog_value_religion_id === 1144
                      ? 'Muslim'
                      : personalDetails[0].catalog_value_religion_id === 1145
                        ? 'Christian'
                        : personalDetails[0].catalog_value_religion_id === 1146
                          ? 'Others'
                          : ''}
                </p>
              </div>

              <div className="mt-3 final-display-flex">
                {/* <p className="m-0" >Category</p> */}
                <p className="m-0">
                  <Translate value="rdNumber" />
                </p>
                {personalDetails[0].rd_no}
              </div>
            </Col>
            <Col xs={12} md={6} className="final-personal-col-1 px-3 mt-3">
              <Required
                className="mb-0"
                title={<Translate value="qualificationDoc" />}
              />

              {educationDocs &&
                educationDocs.length > 0 &&
                educationDocs.map((file, index) => (
                  <FormControl
                    style={{ border: '1px solid black' }}
                    fullWidth
                    className="formcontrol3 w-100"
                  >
                    <TextField
                      className="w-100"
                      variant="outlined"
                      placeholder="Click Browse to Upload"
                      // name="mail_id"
                      disabled
                      value={file.file_name}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {!file.file_name.includes('.pdf') && (
                              <IconButton
                                onClick={(ev) => handleViewImage(ev, 'eduDoc')}
                              >
                                <VisibilityIcon />
                              </IconButton>
                            )}
                            {file.file_name.includes('.pdf') && (
                              <IconButton
                                onClick={(ev) =>
                                  handlePDF(ev, 'eduDoc', file.file_id)
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                ))}
            </Col>
          </Row>
        )}
        <Row className="mt-5 mb-0 final all-details-subtitle-row">
          <Col xs={12} md={12} className="profile-title mmb-0">
            <img alt="..." src={address} />
            {/* <h1>Address Details </h1> */}
            <h1>
              <Translate value="addressDetails" />
            </h1>
            {/* <p onClick={() => props.editButtonPressed(2)}>Edit your details<img src={edit}/></p> */}

            {(users && users.user.is_sent_approval === true) ||
            submitSuccess ? (
              <p
                style={{
                  backgroundColor: '#F3F6F8',
                  boxShadow: 'none',
                  color: 'black',
                }}
              >
                Submitted{' '}
                <CheckCircle
                  className={classes.backdropCheck}
                  style={{ height: 'auto', width: '20px' }}
                />
              </p>
            ) : (
              <p
                onClick={() =>
                  history.push(`${`/profile/address/${labourUserID}/edit`}`)
                }
              >
                <Translate value="edityourdetails" />
                <img src={edit} />
              </p>
            )}

            {/* <p
                style={{
                  backgroundColor: '#F3F6F8',
                  boxShadow: 'none',
                  color: 'black',
                }}
              >
                Submitted{' '}
                <CheckCircle
                  className={classes.backdropCheck}
                  style={{ height: 'auto', width: '20px' }}
                />
              </p>  */}
          </Col>
        </Row>
        {addressDetails && addressDetails.length > 0 && (
          <Row className="form-row-final with-border mt-0">
            <Col xs={12} className="profile-title">
              {/* <h2>Residential Address</h2> */}
              <h2>
                <Translate value="residentialAddress" />
              </h2>
            </Col>
            <Col xs={12} md={4} className="final-personal-col-1 px-3">
              <Required
                className="mt-0 mb-2"
                title={<Translate value="typeOfresidence" />}
              />
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  value={addressDetails[0].address_base_type}
                  disabled
                />
              </FormControl>

              {/* <p className="mt-3 mb-0">City Name </p> */}
              <p className="mt-3 mb-0">
                <Translate value="cityName" />*
              </p>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  value={addressDetails[0].panhayat_city_town}
                  disabled
                />
              </FormControl>

              {/* <p className="mt-3 mb-0">Village Name </p> */}
              <p className="mt-3 mb-0">
                <Translate value="villageName" />*
              </p>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  value={addressDetails[0].ward_area_village}
                  disabled
                />
              </FormControl>
            </Col>

            <Col xs={12} md={4} className="final-personal-col-1 px-3">
              {/* <Required className="mb-0" title="House/Building Number" /> */}
              <Required
                className="mb-0"
                title={<Translate value="houseBuildingNumber" />}
              />
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  value={addressDetails[0].door_no}
                  disabled
                />
              </FormControl>

              {/* <p className="mt-3 mb-0">District</p> */}
              <p className="mt-3 mb-0">
                <Translate value="district" />*
              </p>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  value={addressDetails[0].district}
                  disabled
                />
              </FormControl>

              {/* <p className="mt-3 mb-0">Taluk</p> */}
              <p className="mt-3 mb-0">
                <Translate value="taluk" />*
              </p>

              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  value={addressDetails[0].taluk}
                  disabled
                />
              </FormControl>
            </Col>

            <Col xs={12} md={4} className="final-personal-col-1 px-3">
              {/* <Required className="mb-0" title="Street/Road Name" /> */}
              <Required
                className="mb-0"
                title={<Translate value="streetRoadName" />}
              />
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  value={addressDetails[0].street_name}
                  disabled
                />
              </FormControl>

              {/* <p className="mt-3 mb-0">Landmark</p> */}
              <p className="mt-3 mb-0">
                <Translate value="landmark" />
              </p>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  value={addressDetails[0].land_mark}
                  disabled
                />
              </FormControl>

              <Row>
                <Col xs={6} className="final-personal-col-1 ">
                  {/* <p className="mt-3 mb-0">State</p> */}
                  <p className="mt-3 mb-0">
                    <Translate value="state" />*
                  </p>
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      value={addressDetails[0].state}
                      disabled
                    />
                  </FormControl>
                </Col>
                <Col xs={6} className="final-personal-col-1 ">
                  {/* <p className="mt-3 mb-0">Pincode</p> */}
                  {/* <Required className="mt-3 mb-2" title={<Translate value="pincode"/>} /> */}

                  <p className="mt-3 mb-0">
                    <Translate value="pin_code" />*
                  </p>
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      value={addressDetails[0].pin_code}
                      disabled
                    />
                  </FormControl>
                </Col>
              </Row>
            </Col>

            <Col xs={12} className="profile-title permanentAddress">
              {/* <h2>Permanent Address</h2> */}
              <h2>
                <Translate value="permanentAddress" />
              </h2>
            </Col>
            <Col xs={12} md={4} className="final-personal-col-1 px-3">
              {/* <Required className="mt-0 mb-2" title={<Translate value="typeOfresidence"/>} /> */}

              {/* <FormControl fullWidth>
                                        <TextField
                                            variant="outlined"
                                            // value={users.getUserRegistrationDetails.address_details[0].address_base_type}
                                            value={users.getUserRegistrationDetails.address_details[0].address_base_type}

                                            disabled
                                        />
                                    </FormControl> */}

              {/* <p className="mt-3 mb-0">City Name </p> */}
              <Required
                className="mb-0"
                title={<Translate value="houseBuildingNumber" />}
              />

              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  // value={users.getUserRegistrationDetails.address_details[0].door_no}
                  value={permanentAddress.houseNumber}
                  disabled
                />
              </FormControl>

              <p className="mt-3 mb-0">
                <Translate value="district" />*
              </p>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  // value={users.getUserRegistrationDetails.address_details[0].district}
                  value={permanentAddress.district}
                  disabled
                />
              </FormControl>

              <p className="mt-3 mb-0">
                <Translate value="taluk" />*
              </p>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  // value={users.getUserRegistrationDetails.address_details[0].taluk}
                  value={permanentAddress.taluk}
                  disabled
                />
              </FormControl>
            </Col>

            <Col xs={12} md={4} className="final-personal-col-1 px-3">
              {/* <Required className="mb-0" title="House/Building Number" /> */}
              <Required
                className="mb-0"
                title={<Translate value="streetRoadName" />}
              />
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  // value={users.getUserRegistrationDetails.address_details[0].street_name}
                  value={permanentAddress.streetName}
                  disabled
                />
              </FormControl>

              {/* <p className="mt-3 mb-0">District</p> */}
              <p className="mt-3 mb-0">
                <Translate value="landmark" />
              </p>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  // value={users.getUserRegistrationDetails.address_details[0].land_mark}
                  value={permanentAddress.landmark}
                  disabled
                />
              </FormControl>

              <Row>
                <Col xs={6} className="final-personal-col-1 ">
                  {/* <p className="mt-3 mb-0">State</p> */}
                  <p className="mt-3 mb-0">
                    <Translate value="state" />*
                  </p>
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      // value={users.getUserRegistrationDetails.address_details[0].state}
                      value={permanentAddress.state}
                      disabled
                    />
                  </FormControl>
                </Col>
                <Col xs={6} className="final-personal-col-1 ">
                  {/* <p className="mt-3 mb-0">pin_code</p> */}
                  <p className="mt-3 mb-0">
                    <Translate value="pin_code" />*
                  </p>
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      // value={users.getUserRegistrationDetails.address_details[0].pin_code}
                      value={permanentAddress.pinCode}
                      disabled
                    />
                  </FormControl>
                </Col>
              </Row>
            </Col>

            <Col xs={12} md={4} className="final-personal-col-1 px-3">
              {/* <Required className="mb-0" title="Street/Road Name" /> */}
              <p className="mb-0">
                <Translate value="cityName" />*
              </p>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  // value={users.getUserRegistrationDetails.address_details[0].panhayat_city_town}
                  value={permanentAddress.city}
                  disabled
                />
              </FormControl>

              {/* <p className="mt-3 mb-0">Landmark</p> */}
              <p className="mt-3 mb-0">
                <Translate value="villageName" />*
              </p>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  // value={users.getUserRegistrationDetails.address_details[0].ward_area_village}
                  value={permanentAddress.village}
                  disabled
                />
              </FormControl>
            </Col>
          </Row>
        )}
        <Row className="mt-5 mb-0 final all-details-subtitle-row">
          <Col xs={12} md={12} className="profile-title">
            <img alt="..." src={family} />
            {/* <h1>Family Details </h1> */}
            <h1>
              <Translate value="familyDetails" />
            </h1>

            {(users && users.user.is_sent_approval === true) ||
            submitSuccess ? (
              <p
                style={{
                  backgroundColor: '#F3F6F8',
                  boxShadow: 'none',
                  color: 'black',
                }}
              >
                Submitted{' '}
                <CheckCircle
                  className={classes.backdropCheck}
                  style={{ height: 'auto', width: '20px' }}
                />
              </p>
            ) : (
              <p
                onClick={() =>
                  history.push(`${`/profile/family/${labourUserID}/edit`}`)
                }
              >
                <Translate value="edityourdetails" />
                <img src={edit} />
              </p>
            )}
          </Col>
        </Row>
        {rationCardDetails && rationCardDetails.length > 0 && (
          <Row className="form-row-final mt-4">
            {rationCardDetails[0]?.ration_card_no && (
              <Col xs={12} className="profile-title">
                {/* <h2>Ration Card Details</h2> */}
                <h2>
                  <Translate value="rationCardDetails" />
                </h2>
              </Col>
            )}
            {rationCardDetails[0]?.ration_card_no && (
              <Col xs={6} md={4} className="final-personal-col-1 px-3">
                {/* <Required className="mb-0" title="Ration Card Number" /> */}
                <p
                  className="mb-0"
                  title={<Translate value="rationCardNumber" />}
                />
                <FormControl fullWidth>
                  <TextField
                    value={rationCardDetails[0].ration_card_no}
                    disabled
                  />
                </FormControl>
              </Col>
            )}
            {rationCardDetails[0]?.ration_card_type && (
              <Col xs={6} md={4} className="final-personal-col-1 px-3">
                {/* <p className="mb-0" title="Ration Card Type" /> */}
                <p
                  className="mb-0"
                  title={<Translate value="rationCardType" />}
                />
                <FormControl fullWidth>
                  <TextField
                    value={rationCardDetails[0].ration_card_type}
                    // value={
                    //     users.getUserRegistrationDetails.family_details.rationCardTypes[
                    //         users.family_details.rationCardTypes.map(function(e) {
                    //             return e.value_id;
                    //         }).indexOf(users.family_details.rationCardType)
                    //     ].value
                    // }
                    disabled
                  />
                </FormControl>
              </Col>
            )}
            {rationCardDoc && rationCardDoc.length > 0 && (
              <>
                {' '}
                <Col xs={4} className="final-personal-col-1 px-3">
                  <p
                    className="mb-0"
                    title={<Translate value="rationCardDocument" />}
                  />
                </Col>
                <Col xs={12} md={6} className="final-personal-col-1 px-3 mt-3">
                  <Required
                    className="mb-0"
                    title={<Translate value="rationCardDocument" />}
                  />
                  {rationCardDoc &&
                    rationCardDoc.length > 0 &&
                    rationCardDoc.map((file, index) => (
                      <FormControl
                        fullWidth
                        className="formcontrol3 w-100"
                        style={{ border: '1px solid black' }}
                      >
                        <TextField
                          className="w-100"
                          variant="outlined"
                          placeholder="Click Browse to Upload"
                          // name="mail_id"
                          disabled
                          value={file.file_name}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {!file.file_name.includes('.pdf') && (
                                  <IconButton
                                    onClick={(ev) =>
                                      handleViewImage(ev, 'rationCardDoc')
                                    }
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                )}
                                {file.file_name.includes('.pdf') && (
                                  <IconButton
                                    onClick={(ev) =>
                                      handlePDF(
                                        ev,
                                        'rationCardDoc',
                                        file.file_id,
                                      )
                                    }
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    ))}
                </Col>
              </>
            )}
            <Col xs={12} className="profile-title mmb-0">
              <h2>
                <Translate value="familyMemberDetails" />
              </h2>
            </Col>
          </Row>
        )}

        {familyDetails &&
          familyDetails.length &&
          familyDetails.map((data) => {
            return (
              <Row className="form-row-final mt-4">
                <Row className={'mt-0 familymember-card final row'}>
                  <Col xs={12} md={6}>
                    <Row>
                      <Col xs={4} className="member-p">
                        {/* <p >Relation :</p> */}
                        <p>
                          <Translate value="relation" />* :
                        </p>
                      </Col>
                      <Col xs={8}>
                        <FormControl fullWidth>
                          <TextField
                            value={data.parent_child_relation}
                            disabled
                          />
                        </FormControl>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={6}>
                    <Row>
                      <Col xs={4} className="member-p">
                        {/* <p >First Name :</p> */}
                        <p>
                          <Translate value="firstName" />* :
                        </p>
                      </Col>
                      <Col xs={8}>
                        <FormControl fullWidth>
                          <TextField
                            value={`${data.first_name} ${
                              data.middle_name ?? ''
                            } ${data.last_name ?? ''}`}
                            disabled
                          />
                        </FormControl>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={12} md={6}>
                    <Row>
                      <Col xs={4} className="member-p">
                        {/* <p >DOB :</p> */}
                        <p>
                          <Translate value="dOB" />* :
                        </p>
                      </Col>
                      <Col xs={8}>
                        <FormControl fullWidth>
                          <TextField
                            value={moment(data.date_of_birth).format(
                              'DD.MM.YYYY',
                            )}
                            disabled
                          />
                        </FormControl>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={6}>
                    <Row>
                      <Col xs={4} className="member-p">
                        {/* <p >Profession :</p> */}
                        <p>
                          <Translate value="profession" />* :
                        </p>
                      </Col>
                      <Col xs={8}>
                        <FormControl fullWidth>
                          <FormControl fullWidth>
                            <TextField value={data.proffession} disabled />
                          </FormControl>
                        </FormControl>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={6}>
                    <Row>
                      <Col xs={4} className="member-p">
                        {/* <p >Education :</p> */}
                        <p>
                          <Translate value="education" />* :
                        </p>
                      </Col>
                      <Col xs={8}>
                        <FormControl fullWidth>
                          <FormControl fullWidth>
                            <TextField value={data.education} disabled />
                          </FormControl>
                        </FormControl>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={10} className="member-p">
                    <FormControlLabel
                      control={
                        <>
                          <Checkbox checked={data.is_nominee} disabled />
                        </>
                      }
                      // label="Nominee"
                      label={<Translate value="nominee" />}
                    />
                  </Col>
                  <Col xs={10} className="member-p">
                    {/* <Row>
                      <Col xs={12} md={8} className="member-p mt-2">
                        <FormControl
                          className="interstateRadio"
                          variant="outlined"
                          fullWidth
                          component="fieldset"
                        >
                          <RadioGroup
                            row
                            value={data.is_regisrered_user}
                            name={'alreadyRegistered'}
                            disabled
                          >
                           
                            <p className="pad-new mt-2 mb-2">
                              <Translate value="alreadyRegisteredinKBOCWWB" />*
                            </p>

                            
                            <FormControlLabel
                              className="col-2"
                              value={1}
                              control={<Radio />}
                              label={<Translate value="yes" />}
                            />
                            <FormControlLabel
                              className="col-2"
                              value={0}
                              control={<Radio />}
                              label={<Translate value="no" />}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Col>
                      <Col xs={6} md={4} className="member-p mt-2">
                        {data.is_regisrered_user === 1 ? (
                          <div className="regnum">
                            <p className="mt-2 mb-2">
                              KBOCWWB Registration Number
                            </p>
                            <FormControl>
                              <TextField
                                variant="outlined"
                                disabled
                                value={data.registration_code}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="start">
                                      {users.family_details.pancheck ? (
                                        <img alt="..." src={checkgreen} />
                                      ) : (
                                        <img alt="..." src={checkgrey} />
                                      )}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </FormControl>
                          </div>
                        ) : null}
                      </Col>
                    </Row> */}
                  </Col>
                </Row>
              </Row>
            );
          })}
        <Row className="mt-5 mb-0 final all-details-subtitle-row">
          <Col xs={12} md={12} className="profile-title">
            <img alt="..." src={bank} />
            {/* <h1>Bank Details</h1> */}
            <h1>
              <Translate value="bankDetails" />
            </h1>
            {(users && users.user.is_sent_approval === true) ||
            submitSuccess ? (
              <p
                style={{
                  backgroundColor: '#F3F6F8',
                  boxShadow: 'none',
                  color: 'black',
                }}
              >
                Submitted{' '}
                <CheckCircle
                  className={classes.backdropCheck}
                  style={{ height: 'auto', width: '20px' }}
                />
              </p>
            ) : (
              <p
                onClick={() =>
                  history.push(`${`/profile/bank/${labourUserID}/edit`}`)
                }
              >
                <Translate value="edityourdetails" />
                <img src={edit} />
              </p>
            )}
          </Col>
        </Row>
        <Row className="form-row-final mt-4">
          {bankDetails && bankDetails.length > 0 && (
            <>
              <Col xs={12} md={3} className="final-personal-col-1 px-3">
                {/* <Required className="mb-0" title="IFSC Code" /> */}
                <Required
                  className="mb-0"
                  title={<Translate value="iFSCCode" />}
                />
                <FormControl fullWidth>
                  <TextField value={bankDetails[0].ifsc_code} disabled />
                </FormControl>
              </Col>
              <Col xs={12} md={3} className="final-personal-col-1 px-3">
                {/* <Required className="mb-0" title="Account Number" /> */}
                <Required
                  className="mb-0"
                  title={<Translate value="accountNumber" />}
                />
                <FormControl fullWidth>
                  <TextField value={bankDetails[0].account_no} disabled />
                </FormControl>
              </Col>
              <Col xs={12} md={3} className="final-personal-col-1 px-3">
                {/* <Required className="mb-0" title="Bank Name" /> */}
                <Required
                  className="mb-0"
                  title={<Translate value="bankName" />}
                />
                <FormControl fullWidth>
                  <TextField value={bankDetails[0].bank_name} disabled />
                </FormControl>
              </Col>
              <Col xs={12} md={3} className="final-personal-col-1 px-3">
                {/* <Required className="mb-0" title="Bank Branch" /> */}
                <Required
                  className="mb-0"
                  title={<Translate value="bankBranch" />}
                />
                <FormControl fullWidth>
                  <TextField value={bankDetails[0].bank_branch} disabled />
                </FormControl>
              </Col>
              <Col xs={12} md={6} className="final-personal-col-1 px-3 mt-3">
                {/* <Required className="mb-0" title="Bank Branch" /> */}
                <Required
                  className="mb-0"
                  title={<Translate value="bankAddress" />}
                />

                <FormControl fullWidth>
                  <TextareaAutosize
                    variant="outlined"
                    multiline
                    rows={4}
                    value={bankDetails[0].bank_address}
                    disabled
                  />
                </FormControl>
              </Col>
              <Col xs={12} md={6} className="final-personal-col-1 px-3 mt-3">
                <Required
                  className="mb-0"
                  title={<Translate value="passbookDocument" />}
                />
                {bankPassbookDoc &&
                  bankPassbookDoc.length > 0 &&
                  bankPassbookDoc.map((file, index) => (
                    <FormControl
                      fullWidth
                      className="formcontrol3 w-100"
                      style={{ border: '1px solid black' }}
                    >
                      <TextField
                        className="w-100"
                        variant="outlined"
                        placeholder="Click Browse to Upload"
                        // name="mail_id"
                        disabled
                        value={file.file_name}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {!file.file_name.includes('.pdf') && (
                                <IconButton
                                  onClick={(ev) =>
                                    handleViewImage(ev, 'bankDoc')
                                  }
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              )}
                              {file.file_name.includes('.pdf') && (
                                <IconButton
                                  onClick={(ev) =>
                                    handlePDF(ev, 'bankDoc', file.file_id)
                                  }
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  ))}
              </Col>
            </>
          )}

          {bankDetails &&
          bankDetails.length &&
          bankDetails.filter((data) => data.is_nominee).length > 0 ? (
            <>
              {bankDetails &&
                bankDetails.length &&
                bankDetails
                  .filter((data) => data.is_nominee)
                  .map((data) => (
                    <>
                      <Col xs={10} className="profile-title">
                        {/* <h2>Nominee Bank Details</h2> */}
                        <h2>
                          <Translate value="nomineeBankDetails" />
                        </h2>
                      </Col>
                      <Col xs={3} className="final-personal-col-1 px-3">
                        {/* <Required className="mb-0" title="IFSC Code" /> */}
                        <Required
                          className="mb-0"
                          title={<Translate value="iFSCCode" />}
                        />
                        <FormControl fullWidth>
                          <TextField value={data.ifsc_code} disabled />
                        </FormControl>
                      </Col>
                      <Col xs={3} className="final-personal-col-1 px-3">
                        <Required
                          className="mb-0"
                          title={<Translate value="accountNumber" />}
                        />
                        <FormControl fullWidth>
                          <TextField value={data.account_no} disabled />
                        </FormControl>
                      </Col>
                      <Col xs={3} className="final-personal-col-1 px-3">
                        {/* <Required className="mb-0" title="Bank Name" /> */}
                        <Required
                          className="mb-0"
                          title={<Translate value="bankName" />}
                        />
                        <FormControl fullWidth>
                          <TextField value={data.bank_name} disabled />
                        </FormControl>
                      </Col>
                      <Col xs={3} className="final-personal-col-1 px-3">
                        {/* <Required className="mb-0" title="Bank Branch" /> */}
                        <Required
                          className="mb-0"
                          title={<Translate value="bankBranch" />}
                        />
                        <FormControl fullWidth>
                          <TextField value={data.bank_branch} disabled />
                        </FormControl>
                      </Col>
                      <Col xs={6} className="final-personal-col-1 px-3 mt-3">
                        {/* <Required className="mb-0" title="Bank Address" /> */}
                        <Required
                          className="mb-0"
                          title={<Translate value="bankAddress" />}
                        />
                        <FormControl fullWidth>
                          <TextareaAutosize
                            variant="outlined"
                            multiline
                            rows={4}
                            value={data.bank_address}
                            disabled
                          />
                        </FormControl>
                      </Col>
                    </>
                  ))}

              <Col xs={6} className="final-personal-col-1 px-3 mt-3">
                <Required
                  className="mb-0"
                  title={<Translate value="passbookDocument" />}
                />
                {nomineeBankPassbookDoc &&
                  nomineeBankPassbookDoc.length > 0 &&
                  nomineeBankPassbookDoc.map((file, index) => (
                    <FormControl
                      fullWidth
                      className="formcontrol3 w-100"
                      style={{ border: '1px solid black' }}
                    >
                      <TextField
                        className="w-100"
                        variant="outlined"
                        placeholder="Click Browse to Upload"
                        // name="mail_id"
                        disabled
                        value={file.file_name}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {!file.file_name.includes('.pdf') && (
                                <IconButton
                                  onClick={(ev) =>
                                    handleViewImage(ev, 'nomineeBankDoc')
                                  }
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              )}
                              {file.file_name.includes('.pdf') && (
                                <IconButton
                                  onClick={(ev) =>
                                    handlePDF(
                                      ev,
                                      'nomineeBankDoc',
                                      file.file_id,
                                    )
                                  }
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  ))}
              </Col>
            </>
          ) : null}
        </Row>
        {personalDetails && personalDetails[0].is_sevasindhu === 0 && (
          <>
            <Row className="mt-5 mb-0 final all-details-subtitle-row">
              <Col xs={12} md={12} className="profile-title">
                <img alt="..." src={nintydays} />
                {/* <h1>Details of 90 Days Working Certificate & Employer Details</h1> */}
                <h1 class="text-val">
                  <Translate value="detailsof90DaysWorkingCertificateEmployerDetails" />
                </h1>

                {(users && users.user.is_sent_approval === true) ||
                submitSuccess ? (
                  <p
                    style={{
                      backgroundColor: '#F3F6F8',
                      boxShadow: 'none',
                      color: 'black',
                    }}
                  >
                    Submitted{' '}
                    <CheckCircle
                      className={classes.backdropCheck}
                      style={{ height: 'auto', width: '20px' }}
                    />
                  </p>
                ) : (
                  <p
                    onClick={() =>
                      history.push(`/profile/nintyDays/${labourUserID}/edit`)
                    }
                  >
                    <Translate value="edityourdetails" />
                    <img src={edit} />
                  </p>
                )}
              </Col>
            </Row>
            {employerDetails &&
              employerDetails.length &&
              employerDetails.map((data) => {
                return (
                  <>
                    <Row className="form-row-final mt-3">
                      <Row className={'mt-0 pt-4 familymember-card final row'}>
                        <Col
                          xs={12}
                          md={3}
                          className="final-personal-col-1 px-3"
                        >
                          {/* <Required className="mb-0" title="Employer Details" /> */}
                          <Required
                            className="mb-0"
                            title={<Translate value="employerDetails" />}
                          />
                          <FormControl fullWidth>
                            <TextField
                              value={data.employement_status}
                              disabled
                            />
                          </FormControl>
                        </Col>
                        <Col
                          xs={12}
                          md={3}
                          className="final-personal-col-1 px-3"
                        >
                          {/* <Required className="mb-0" title="Constractor Name" /> */}
                          <Required
                            className="mb-0"
                            title={<Translate value="constractorName" />}
                          />
                          <FormControl fullWidth>
                            <TextField value={data.contractor_name} disabled />
                          </FormControl>
                        </Col>
                        <Col
                          xs={12}
                          md={3}
                          className="final-personal-col-1 px-3"
                        >
                          {/* <Required className="mb-0" title="Constractor Company Name" /> */}
                          <Required
                            className="mb-0"
                            title={<Translate value="constractorCompanyName" />}
                          />
                          <FormControl fullWidth>
                            <TextField
                              value={data.contractor_company_name}
                              disabled
                            />
                          </FormControl>
                        </Col>
                        <Col
                          xs={12}
                          md={3}
                          className="final-personal-col-1 px-3 mt-3"
                        >
                          {/* <Required className="mb-0" title="Mobile Number (Incharge Person)" /> */}
                          <Required
                            className="mb-0"
                            style="margin-top:10px;"
                            title={
                              <Translate value="mobileNumberInchargePerson" />
                            }
                          />
                          <FormControl fullWidth>
                            <TextField value={data.mobile_no} disabled />
                          </FormControl>
                        </Col>
                        <Col
                          xs={12}
                          md={6}
                          className="final-personal-col-1 px-3 mt-3"
                        >
                          {/* <Required className="mb-0" title="Workplace/Site Address" /> */}
                          <Required
                            className="mb-0"
                            title={<Translate value="workplaceSiteAddress" />}
                          />
                          <FormControl fullWidth>
                            <TextareaAutosize
                              variant="outlined"
                              multiline
                              rows={2}
                              value={data.site_address}
                              disabled
                            />
                          </FormControl>
                        </Col>
                        <Col
                          xs={12}
                          md={3}
                          className="final-personal-col-1 px-3 mt-3"
                        >
                          {/* <Required className="mb-0" title="State" /> */}
                          <Required
                            className="mb-0"
                            title={<Translate value="state" />}
                          />
                          <FormControl fullWidth>
                            <TextField value={data.state} disabled />
                          </FormControl>
                        </Col>
                        <Col
                          xs={12}
                          md={3}
                          className="final-personal-col-1 px-3 mt-3"
                        >
                          {/* <Required className="mb-0" title="Workplace/District" /> */}
                          <Required
                            className="mb-0"
                            title={<Translate value="workplaceDistrict" />}
                          />
                          <FormControl fullWidth>
                            <TextField value={data.district} disabled />
                          </FormControl>
                        </Col>
                        <Col
                          xs={12}
                          md={3}
                          className="final-personal-col-1 px-3 mt-3"
                        >
                          {/* <Required className="mb-0" title="Taluk" /> */}
                          <Required
                            className="mb-0"
                            title={<Translate value="taluk" />}
                          />
                          <FormControl fullWidth>
                            <TextField value={data.taluk} disabled />
                          </FormControl>
                        </Col>
                        <Col
                          xs={12}
                          md={3}
                          className="final-personal-col-1 px-3 mt-3"
                        >
                          {/* <Required className="mb-0" title="Town/City/Gram Panchayat" /> */}
                          <Required
                            className="mb-0"
                            title={<Translate value="townCityGramPanchayat" />}
                          />
                          <FormControl fullWidth>
                            <TextField
                              value={data.panhayat_city_town}
                              disabled
                            />
                          </FormControl>
                        </Col>
                        <Col
                          xs={12}
                          md={3}
                          className="final-personal-col-1 px-3 mt-3"
                        >
                          {/* <Required className="mb-0" title="Village/Ward Circle" /> */}
                          <Required
                            className="mb-0"
                            title={<Translate value="villageWardCircle" />}
                          />
                          <FormControl fullWidth>
                            <TextField
                              value={data.ward_area_village}
                              disabled
                            />
                          </FormControl>
                        </Col>
                        <Col
                          xs={12}
                          md={3}
                          className="final-personal-col-1 px-3 mt-3"
                        >
                          {/* <Required className="mb-0" title="pin_code" /> */}
                          <Required
                            className="mb-0"
                            title={<Translate value="pin_code" />}
                          />
                          <FormControl fullWidth>
                            <TextField value={data.pin_code} disabled />
                          </FormControl>
                        </Col>
                        <Col
                          xs={12}
                          md={3}
                          className="final-personal-col-1 px-3 mt-3"
                        >
                          {/* <Required className="mb-0" title="Nature of Work" /> */}
                          <Required
                            className="mb-0"
                            title={<Translate value="natureofWork" />}
                          />
                          <FormControl fullWidth>
                            <TextField value={data.nature_of_work} disabled />
                          </FormControl>
                        </Col>
                      </Row>
                    </Row>
                  </>
                );
              })}
            {certificateDetails && certificateDetails.length && (
              <Row className="form-row-final mt-4">
                <Col xs={12} md={12} className="profile-title">
                  <h2>
                    <Translate value="daysWorkCertificateDetails" />
                  </h2>
                  {/* <h2><Translate value="DaysWorkCertificateDetails"/></h2> */}
                </Col>
                <Col xs={12} md={6} className="final-personal-col-1">
                  {/* <Required className="mb-0" title="Type of Issuer" /> */}
                  <Required
                    className="mb-0"
                    title={<Translate value="typeofIssuer" />}
                  />
                  <FormControl fullWidth>
                    <TextField
                      value={certificateDetails[0].issuer_type}
                      disabled
                    />
                  </FormControl>
                </Col>
                <Col xs={12} md={6} className="final-personal-col-1 px-3">
                  {/* <Required className="mb-0" title="Issued Date" /> */}
                  <Required
                    className="mb-0 new-pad"
                    title={<Translate value="issuedDate" />}
                  />
                  <FormControl fullWidth>
                    <TextField
                      value={moment(certificateDetails[0].issue_date).format(
                        'DD.MM.YYYY',
                      )}
                      disabled
                    />
                  </FormControl>
                </Col>

                <Col xs={11} md={12} className="profile-title">
                  <Row>
                    {certificateDetails[0].catalog_value_type_of_issuer_id ===
                      55 && (
                      <>
                        <Col xs={12} md={4} className="issuerTypeForm">
                          {/* <p className="mt-0 mb-2">Labour Inspector Name</p> */}
                          <p className="mt-0 mb-2 new-pad">
                            <Translate value="labourInspectorName" />
                          </p>
                          <FormControl fullWidth>
                            <TextField
                              value={certificateDetails[0].inspector_first_name}
                              disabled
                            />
                          </FormControl>
                        </Col>
                        <Col xs={12} md={4} className="issuerTypeForm">
                          {/* <p className="mt-0 mb-2">Circle of the Labour Inspector </p> */}
                          <p className="mt-0 mb-2 new-pad">
                            <Translate value="circleoftheLabourInspector" />{' '}
                          </p>

                          <FormControl fullWidth>
                            <TextField
                              value={certificateDetails[0].inspector_circle}
                              disabled
                            />
                          </FormControl>
                        </Col>
                        <Col xs={12} md={4} className="issuerTypeForm">
                          {/* <p className="mt-0 mb-2 ">Mobile Number of the Labour Inspector </p> */}
                          <p className="mt-0 mb-2 new-space">
                            <Translate value="mobileNumberoftheLabourInspector" />{' '}
                          </p>
                          <FormControl fullWidth>
                            <TextField
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img
                                      src={mobilepic}
                                      alt="..."
                                      className="phone-icon"
                                    />
                                    <p className="countrycode">+91</p>
                                  </InputAdornment>
                                ),
                              }}
                              value={certificateDetails[0].inspector_mobile_no}
                              disabled
                            />
                          </FormControl>
                        </Col>
                      </>
                    )}
                    {certificateDetails[0].catalog_value_type_of_issuer_id ===
                      56 && (
                      <>
                        <Col xs={10} md={3} className="issuerTypeForm">
                          {/* <p className="mt-0 mb-2">Union Name</p> */}
                          <p className="mt-0 mb-2">
                            <Translate value="unionName" />
                          </p>
                          <FormControl fullWidth>
                            <TextField
                              value={certificateDetails[0].labour_union_name}
                              disabled
                            />
                          </FormControl>
                        </Col>
                        <Col xs={10} md={3} className="issuerTypeForm">
                          {/* <p className="mt-0 mb-2">Union Registration Number</p> */}
                          <p className="mt-0 mb-2">
                            <Translate value="unionRegistrationNumber" />
                          </p>
                          <FormControl fullWidth>
                            <TextField
                              value={
                                certificateDetails[0]
                                  .labour_union_registration_no
                              }
                              disabled
                            />
                          </FormControl>
                        </Col>
                        <Col xs={10} md={3} className="issuerTypeForm">
                          {/* <p className="mt-0 mb-2">Name of Issuer</p> */}
                          <p className="mt-0 mb-2">
                            <Translate value="nameofIssuer" />
                          </p>
                          <FormControl fullWidth>
                            <TextField
                              value={certificateDetails[0].issuer_name}
                              disabled
                            />
                          </FormControl>
                        </Col>
                        <Col xs={10} md={3} className="issuerTypeForm">
                          {/* <p className="mt-0 mb-2">Mobile Number (If Available)</p> */}
                          <p className="mt-0 mb-2">
                            <Translate value="mobileNumberIfAvailable" />
                          </p>
                          <FormControl fullWidth>
                            <TextField
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img
                                      src={mobilepic}
                                      alt="..."
                                      className="phone-icon"
                                    />
                                    <p className="countrycode">+91</p>
                                  </InputAdornment>
                                ),
                              }}
                              value={
                                certificateDetails[0].aslabour_union_phone_no
                              }
                              disabled
                            />
                          </FormControl>
                        </Col>
                      </>
                    )}
                    {certificateDetails[0].catalog_value_type_of_issuer_id ===
                      57 && (
                      <>
                        <Col xs={4} className="issuerTypeForm">
                          <p className="mt-0 mb-2">Name of Issuer</p>
                          <FormControl fullWidth>
                            <TextField
                              value={certificateDetails[0].issuer_name}
                              disabled
                            />
                          </FormControl>
                        </Col>
                        <Col xs={4} className="issuerTypeForm">
                          <p className="mt-0 mb-2">Place of Issuer</p>
                          <FormControl fullWidth>
                            <TextField
                              value={certificateDetails[0].issuer_place}
                              disabled
                            />
                          </FormControl>
                        </Col>
                        <Col xs={4} className="issuerTypeForm">
                          <p className="mt-0 mb-2">
                            Mobile Number (If Available)
                          </p>
                          <FormControl fullWidth>
                            <TextField
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img
                                      src={mobile}
                                      alt="..."
                                      className="phone-icon"
                                    />
                                    <p className="countrycode">+91</p>
                                  </InputAdornment>
                                ),
                              }}
                              value={certificateDetails[0].mobile_no}
                              disabled
                            />
                          </FormControl>
                        </Col>
                      </>
                    )}
                    {certificateDetails[0].catalog_value_type_of_issuer_id ===
                      58 && (
                      <>
                        <Col xs={4} className="issuerTypeForm">
                          <p className="mt-0 mb-2">Name of Issuer</p>
                          <FormControl fullWidth>
                            <TextField
                              value={certificateDetails[0].issuer_name}
                              disabled
                            />
                          </FormControl>
                        </Col>
                        <Col xs={4} className="issuerTypeForm">
                          <p className="mt-0 mb-2">Designation of Issuer</p>
                          <FormControl fullWidth>
                            <TextField
                              value={certificateDetails[0].issuer_designation}
                              disabled
                            />
                          </FormControl>
                        </Col>
                        <Col xs={4} className="issuerTypeForm">
                          <p className="mt-0 mb-2">
                            Mobile Number (If Available)
                          </p>
                          <FormControl fullWidth>
                            <TextField
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img
                                      src={mobile}
                                      alt="..."
                                      className="phone-icon"
                                    />
                                    <p className="countrycode">+91</p>
                                  </InputAdornment>
                                ),
                              }}
                              value={certificateDetails[0].mobile_no}
                              disabled
                            />
                          </FormControl>
                        </Col>
                      </>
                    )}

                    <Col
                      xs={12}
                      md={6}
                      className="final-personal-col-1 px-3 mt-3"
                    >
                      <Required
                        className="mb-0"
                        title={<Translate value="DaysWorkingCertificate" />}
                      />
                      {certificateDoc &&
                        certificateDoc.length > 0 &&
                        certificateDoc.map((file, index) => (
                          <FormControl
                            fullWidth
                            className="formcontrol3 w-100"
                            style={{ border: '1px solid black' }}
                          >
                            <TextField
                              className="w-100"
                              variant="outlined"
                              placeholder="Click Browse to Upload"
                              // name="mail_id"
                              disabled
                              value={file.file_name}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {!file.file_name.includes('.pdf') && (
                                      <IconButton
                                        onClick={(ev) =>
                                          handleViewImage(ev, 'certificateDoc')
                                        }
                                      >
                                        <VisibilityIcon />
                                      </IconButton>
                                    )}
                                    {file.file_name.includes('.pdf') && (
                                      <IconButton
                                        onClick={(ev) =>
                                          handlePDF(
                                            ev,
                                            'certificateDoc',
                                            file.file_id,
                                          )
                                        }
                                      >
                                        <VisibilityIcon />
                                      </IconButton>
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormControl>
                        ))}
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </>
        )}
        {(users && users.user.is_sent_approval === true) ||
        submitSuccess ? null : (
          <Row className="scheme-subtitle-row mt-4">
            <Col xs={12} className="searchform-subtitle">
              <p>
                <p>
                  <Translate value="declaration" />
                </p>
              </p>
            </Col>
          </Row>
        )}
        {(users && users.user.is_sent_approval === true) ||
        submitSuccess ? null : (
          <Row className="form-row mt-4 config-form searchform">
            <Col xs={12} className="note2 schemes mt-4 bank-col-5">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dec1}
                    onChange={(ev) => setDec1(ev.target.checked)}
                    name="declaration"
                    color="primary"
                  />
                }
                label={<Translate value="declarationmessage" />}
              />
            </Col>

            <Col xs={12} className="note2 schemes mt-4 bank-col-5">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dec2}
                    onChange={(ev) => setDec2(ev.target.checked)}
                    // name="declaration2"
                    color="primary"
                  />
                }
                label={<Translate value="declarationmessage2" />}
              />
            </Col>
            <Col xs={12} className="note2 schemes mt-4 bank-col-5">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dec3}
                    onChange={(ev) => setDec3(ev.target.checked)}
                    name="declaration3"
                    color="primary"
                  />
                }
                label={<Translate value="declarationmessage3" />}
              />
            </Col>
            <Col xs={12} className="note2 schemes mt-4 bank-col-5">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dec4}
                    onChange={(ev) => setDec4(ev.target.checked)}
                    name="declaration3"
                    color="primary"
                  />
                }
                label={<Translate value="declarationmessage4" />}
              />
            </Col>
            {users &&
              (!users.user.inspection_id || users.user.inspection_id === 0) && (
                <Row className="ack-table-row-last">
                  <Col xs={2}></Col>
                  <Col xs={12} className="note2 schemes mt-4 bank-col-5">
                    <>
                      <p className="mb-2 mt-3 text-left">
                        <Translate value="selfdeclaration" />
                      </p>
                      <div className="browsebutton-outerdiv d-flex justify-content-center align-items-center">
                        <div className="w-50">
                          {selfDeclarationDoc &&
                            selfDeclarationDoc.length > 0 &&
                            selfDeclarationDoc.map((file, index) => (
                              <FormControl
                                fullWidth
                                className="formcontrol3 w-100"
                              >
                                <TextField
                                  className="w-100"
                                  variant="outlined"
                                  placeholder="Click Browse to Upload"
                                  // name="mail_id"
                                  disabled
                                  value={file.name}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {
                                          <IconButton
                                            onClick={() =>
                                              handleRemoveSelfDeclarationFile(
                                                index,
                                              )
                                            }
                                          >
                                            <CancelIcon />
                                          </IconButton>
                                        }
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </FormControl>
                            ))}
                          {selfDeclarationDoc &&
                            selfDeclarationDoc.length === 0 && (
                              <FormControl
                                fullWidth
                                className="formcontrol3 w-100"
                              >
                                <TextField
                                  className="w-100"
                                  variant="outlined"
                                  placeholder="Click Browse to Upload"
                                  disabled
                                />
                              </FormControl>
                            )}
                        </div>
                        <div className="browsebutton2-div">
                          <div className="">
                            <input
                              type="file"
                              ref={selfDeclarationFileRef}
                              onChange={handleSelfDeclarationFile}
                              multiple
                              hidden
                            />
                            <Button
                              type="button"
                              variant="contained"
                              color="primary"
                              className="py-2 mx-2"
                              startIcon={<FolderIcon />}
                              onClick={() =>
                                selfDeclarationFileRef.current.click()
                              }
                            >
                              Browse
                            </Button>
                          </div>
                        </div>
                      </div>
                    </>
                  </Col>
                </Row>
              )}
          </Row>
        )}
        {personalDetails && personalDetails[0].is_sevasindhu === 0 && (
          <Row
            className="button-inside-form-row mt-5 mb-5"
            style={{
              borderTop: '2px solid #cccccc',
            }}
          >
            <Col xs={12} className="final-button-row mt-4 mb-5">
              {(users && users.user.is_sent_approval === true) ||
              submitSuccess ? (
                <Link
                  to={`/view/registrationack/${labourUserID}`}
                  style={{ flexGrow: '0.5' }}
                  target="_blank"
                >
                  <Button
                    className="w-50 py-3"
                    variant="contained"
                    color="info"
                  >
                    <Translate value="viewAcknowledgementLetter" />
                  </Button>
                </Link>
              ) : (
                <Link to="#" onClick={handleSubmit} style={{ flexGrow: '0.5' }}>
                  <Button
                    className="w-50 py-3"
                    variant="contained"
                    color="success"
                  >
                    <Translate value="finalSubmit" />
                  </Button>
                </Link>
              )}
            </Col>
          </Row>
        )}
      </Row>
    </>
  );
};

export default Review;
