import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { useLocation, useHistory, Link } from 'react-router-dom';
import VerifiedIcon from '@mui/icons-material/Verified';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import Select2 from 'react-select';
import personal from 'assets/images/f1-01.svg';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import {
  FormControl,
  TextField,
  Tooltip,
  LinearProgress,
} from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import { Backdrop, Paper, makeStyles } from '@material-ui/core';
import { Typography } from '@mui/material';
import { CheckCircle } from '@material-ui/icons';
import {
  EKYCWEBHOOK,
  getAadhaarDetails,
} from '../../components/SevaSindhu/SevaSindhuServices/Aadhaar.service';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png';
import { getTokenCookie, showToast } from 'utils/tools';
import { BASE_URL, SERVER } from 'store/actions/user.actions';
import axios from 'axios';
import PersonalDetailsFromSevasindhu from './LabourDetailsFromSevasindhu';
import moment from 'moment';
import uploadFileAPI from 'utils/fileupload';
import { toast } from 'react-toastify';

const SevasindhuLabourDetails = () => {
  const location = useLocation();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [aadhaarLoading, setAadhaarLoading] = useState(false);
  const [aadharVerified, setAadhaarVerified] = useState(false);
  const [labourDetails, setLabourDetails] = useState(null);
  const [vaultReferenceNumber, setVaultReferenceNumber] = useState(null);
  const [open, setOpen] = useState(true);

  const [aadhaarData, setAadhaarData] = useState(null);

  const [submitSuccess, setSubmitSuccess] = useState(false);

  const [applicantName, setApplicantName] = useState(null);
  const [DOB, setDOB] = useState(null);
  const [genderID, setGenderID] = useState(null);
  const [userPhotoID, setUserPhotoID] = useState(null);
  const [surveyResponse, setSurveyResponse] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [docDob, setDocDob] = useState(null);

  const [certSelectOption, setCertSelectOption] = useState(null);

  const queryParams = new URLSearchParams(location.search);

  const registrationNumber = queryParams.get('registrationNumber');
  const referenceNumber = queryParams.get('referenceNumber');
  const mobileNumber = queryParams.get('mobileNumber');

  const insertAndUpdateSurveyDetails = async () => {
    try {
      setIsSubmitting(true);
      const response = await axios.post(`${SERVER}/survey/insert-data`, {
        ss_user_id: labourDetails?.id,
        registrationNo: labourDetails?.regno,
        submissionLocation: labourDetails?.submissionlocation,
        labour_card_file_id: fileId,
        govt_doc_file_id: govtFileId,
        doc_uploaded_dob: docDob,
        doc_upload_type: certSelectOption,
        refNo: labourDetails?.spdv_appl_ref_no,
        phoneNo: mobileNumber,
        name_ss: labourDetails.applicantname,
        dob_ss: moment(labourDetails.dob).format('YYYY-MM-DD'),
        gender_ss:
          labourDetails?.gender == 1
            ? 'M'
            : labourDetails?.gender == 2
              ? 'F'
              : '',
        vault_ref_no: vaultReferenceNumber,
        name_aadhaar: aadhaarData?.name,
        dob_aadhaar: moment(aadhaarData?.dob).format('YYYY-MM-DD'),
        gender_aadhaar: aadhaarData.gender,
        dob_match_status:
          moment(aadhaarData?.dob).format('YYYY-MM-DD') ===
          moment(labourDetails.dob).format('YYYY-MM-DD')
            ? 1
            : 0,
      });
      setIsSubmitting(false);
      setSurveyResponse(response.data?.data?.[0]);
      showToast('SUCCESS', 'Aadhaar Validated Successfully');
      setSubmitSuccess(true);
    } catch (error) {
      const errorMsg =
        error?.response?.data?.errors?.msg || 'Something went wrong';
      showToast('ERROR', errorMsg);
      setSubmitSuccess(false);
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
      setLoading(false);
    }
  };

  const handleVerifyAadhaar = async () => {
    try {
      setAadhaarLoading(true);
      //Production Data

      const payLoad = {
        responseRedirectURL: `${BASE_URL}/seva-sindhu-survey?registrationNumber=${registrationNumber}&referenceNumber=${referenceNumber}&mobileNumber=${mobileNumber}&fileId=${fileId}&govtFileId=${govtFileId}&docDob=${docDob}&selectedDoc=${certSelectOption}`,
      };

      // Local Production Data

      // const payLoad = {
      //   responseRedirectURL: `http://localhost:3000/seva-sindhu-survey?registrationNumber=${registrationNumber}&referenceNumber=${referenceNumber}&mobileNumber=${mobileNumber}&fileId=${isFileUploadedId}`,
      // };

      // UAT
      // const payLoad = {
      //   responseRedirectURL: `https://kbocwwb.metahos.com/seva-sindhu-survey?registrationNumber=${registrationNumber}&referenceNumber=${referenceNumber}&mobileNumber=${mobileNumber}&fileId=${isFileUploadedId}`,
      // };

      const response = await EKYCWEBHOOK(payLoad);
      console.log(response);
      const { token } = response.data;
      // const {token} = response.data.token;

      // window.open(
      //   `https://dbt.karnataka.gov.in/HSMService/EKYCService.aspx?key=0dac67ae-00bb-47fb-8c10-f5fc1776e91d&token=${token}`,
      //   '_self',
      // );
      setAadhaarLoading(false);
      window.open(
        `https://dbt.karnataka.gov.in/HSMService/EKYCService.aspx?key=0dac67ae-00bb-47fb-8c10-f5fc1776e91d&token=${token}`,
        '_self',
      );
    } catch (error) {
      showToast(
        'ERROR',
        'Something went wrong! Please try again after some time.',
      );
    }
  };

  const callGetUserOnVaultReferenceNumber = async (vaultRefNo) => {
    setAadhaarLoading(true);
    try {
      const { data: aadhaarData } = await getAadhaarDetails(vaultRefNo);
      setAadhaarData(aadhaarData[0]);

      if (aadhaarData.length > 0) {
        setAadhaarLoading(false);
        setUserPhotoID(aadhaarData[0]?.image || null);
        setAadhaarVerified(true);
      } else {
        setAadhaarLoading(false);
        setAadhaarVerified(false);
        showToast('ERROR', 'Aadhaar Verification failed, please try again. ');
      }
    } catch (error) {
      setAadhaarLoading(false);
      setAadhaarVerified(false);
      showToast('ERROR', 'Something went wrong');
    }
  };

  useEffect(() => {
    const currentUrl = window.location.toString();
    const queryString = new URLSearchParams(location.search);
    if (currentUrl.includes('Success')) {
      const vaultRefNo = queryString.get('vaultrefno');
      const fileId = queryString.get('fileId');
      const govtFileId = queryString.get('govtFileId');
      const docDob = queryString.get('docDob');
      const selectedDoc = queryString.get('selectedDoc');
      if (docDob) {
        setDocDob(docDob);
      }
      if (selectedDoc) {
        setCertSelectOption(selectedDoc);
      }
      if (fileId) {
        setFileId(fileId);
      }
      if (govtFileId) {
        setGovtFileId(govtFileId);
      }
      if (vaultRefNo) {
        setVaultReferenceNumber(vaultRefNo);
        callGetUserOnVaultReferenceNumber(vaultRefNo);
      }
    } else if (currentUrl.includes('Failure')) {
      showToast('ERROR', 'Aadhaar Verification failed, please try again.');
    }
  }, [location.search]);

  const getLabourDetails = async () => {
    if (registrationNumber && referenceNumber) {
      setLoading(true);
      try {
        const response = await axios.post(
          `${SERVER}/seva_sindhu/check_user_in_seva_sindhu_for_survey`,
          {
            registration_number: registrationNumber,
            application_reference_number: referenceNumber,
          },
        );

        if (response.data.data.length > 0) {
          if (response.data.data[0]?.[0]?.is_dob_survey_done == 'Y') {
            setSubmitSuccess(true);
            setSurveyResponse(response.data.data[0]?.[0]);
            showToast('SUCCESS', 'Already submitted');
            return;
          }
          setLabourDetails(response.data.data[0]?.[0]);
        } else {
          showToast('ERROR', 'No records found');
        }
      } catch (error) {
        const errorMsg =
          error?.response?.data?.errors?.msg || 'Something went wrong';
        showToast('ERROR', errorMsg);
      } finally {
        setLoading(false);
      }
    } else {
      showToast('ERROR', 'Invalid details provided');
      history.push('/survey');
    }
  };

  const handleClose = () => {
    history.push('/');
    setOpen(false);
  };

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: '#ffffff',
      opacity: '0.5',

      display: 'block',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    backdropCheck: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#6CC17A',
    },
    backdropCheckp: {
      fontSize: '30px',
      fontWeight: 'bold',
    },
  }));
  const classes = useStyles();

  const [labourCardFile, setLabourCardFile] = useState(null);
  const [govtFile, setGovtFile] = useState(null);
  const [uploadError, setUploadError] = useState('');
  const [fileId, setFileId] = useState(null);
  const [govtFileId, setGovtFileId] = useState(null);
  const [fileUploading, setFileUploading] = useState(false);
  const [govtFileUploading, setGovtFileUploading] = useState(false);

  const handleDropdownSelect = (selected) => {
    setCertSelectOption(selected?.value || null);
  };

  const cert_options = [
    {
      label:
        'Birth Certificate issued by the Registrar of Births and Deaths or the Municipal Corporation or any other prescribed authority, whosoever has been empowered under the Registration of Birth and Deaths Act, 1969 to register the birth of a child born in India',
      value: 'birth_cert',
    },
    {
      label:
        'Transfer/School leaving/Matriculation Certificate issued by the school last attended/recognized educational board',
      value: 'education_cert',
    },
    {
      label:
        'Policy Bond issued by the Public Life Insurance Corporations/Companies having the DOB of the holder of the insurance policy',
      value: 'policy_cert',
    },
    {
      label: 'PAN Card issued by the Income Tax Department',
      value: 'pan_card',
    },
    {
      label:
        'Driving License issued by the Transport department of concerned state Government',
      value: 'driving_licence',
    },
  ];

  const handleFileChange = (event, fileType) => {
    const file = event.target.files[0];
    if (file) {
      // Validate file type (e.g., PDF, PNG, JPG)
      const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg'];
      if (!allowedTypes.includes(file.type)) {
        setUploadError('Only PDF, PNG, or JPG files are allowed.');
        return;
      }
      if (fileType == 'LABOUR') {
        setLabourCardFile(file);
      } else {
        setGovtFile(file);
      }
      setUploadError('');
    }
  };

  const handleFileUpload = async () => {
    if (!labourCardFile) {
      setUploadError('Please select a file to upload.');
      return;
    }
    try {
      setFileUploading(true);
      const labourCard = await uploadFileAPI(
        labourCardFile,
        labourCardFile.name,
        labourDetails.id,
        'labourCard',
      );
      setFileUploading(false);
      setFileId(labourCard?.image?.fileId);
      showToast('SUCCESS', 'Labour card uploaded successfully');
    } catch (error) {
      showToast('ERROR', 'An error occurred while uploading, Please try again');
    }
  };

  const handleGovtFileUpload = async () => {
    if (!govtFile) {
      setUploadError('Please select a file to upload.');
      return;
    }
    try {
      setGovtFileUploading(true);
      const govtFileData = await uploadFileAPI(
        govtFile,
        govtFile.name,
        labourDetails.id,
        'govtCert',
      );
      setGovtFileUploading(false);
      setGovtFileId(govtFileData?.image?.fileId);
      showToast(
        'SUCCESS',
        'Official Government-Issued Supporting Document uploaded successfully',
      );
    } catch (error) {
      console.log(error);
      showToast('ERROR', 'An error occurred while uploading, Please try again');
    }
  };

  const resetFileUpload = (fileType) => {
    if (fileType === 'LABOUR') {
      setLabourCardFile(null);
      setFileId(null);
      setUploadError('');
    } else {
      setGovtFile(null);
      setGovtFileId(null);
      setUploadError('');
    }
  };

  const showFileInNewTab = (file) => {
    window.open(
      `${SERVER}/upload/v2/file/${file}?token=${getTokenCookie()}`,
      '_blank',
    );
  };

  useEffect(() => {
    getLabourDetails();
  }, []);

  return (
    <>
      {submitSuccess ? (
        <Backdrop
          className={classes.backdrop}
          open={open}
          onClick={handleClose}
        >
          <div style={{ display: 'block' }}>
            <CheckCircle
              className={classes.backdropCheck}
              style={{ height: 'auto', width: '200px' }}
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p className="final-success-title">
                Aadhaar Verified Successfully
              </p>
              <p style={{ fontSize: 22, color: '#01a301' }}>
                Verification Token No:{' '}
                <span style={{ textDecoration: 'underline' }}>
                  {surveyResponse?.survey_token_no || ''}
                </span>
              </p>
              <Link to="/">
                <Typography variant="h5">Back to Home</Typography>
              </Link>
            </div>
          </div>
        </Backdrop>
      ) : null}

      <Row className="top-div">
        <Row className="topbar-row">
          <Col xs={12} md={4} lg={6} className="top-bar-col">
            <div className="logo-div-profile">
              <a href="/login">
                <img
                  id="logo"
                  src={logo}
                  alt="Karnataka Logo"
                  className="logo-img"
                />
              </a>
              <h1 className="logo-text">
                <Translate value="header.commonTitle" />
                <br />
                <Translate value="header.subcommonTitle" />
              </h1>
            </div>
          </Col>
          <div className="d-flex justify-content-end">
            <Col xs={12} md={4} lg={3} style={{ display: 'flex' }}>
              {/* Language selection component can be added here */}
            </Col>
          </div>
        </Row>
      </Row>
      {loading && (
        <div
          style={{ height: '80vh' }}
          className="d-flex align-items-center justify-content-center"
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <div>
              <Spinner animation="border" variant="primary" />
            </div>
            <div>Loading labour details...</div>
          </div>
        </div>
      )}
      {labourDetails && !loading && (
        <>
          <Row className="mt-3 px-4">
            <Col md={6}>
              <label>Select Supporting Document Type To Upload *</label>
              <Select2
                options={cert_options}
                className="select-marital"
                onChange={handleDropdownSelect}
                isSearchable={true}
                isClearable={true}
                isDisabled={aadharVerified}
                value={
                  cert_options.find(
                    (option) => option.value === certSelectOption,
                  ) || null
                }
                placeholder="Select Supporting Document Type"
              />
            </Col>
            <Col md={6}>
              <label>
                Enter Date Of Birth According to the Supporting Document *
              </label>
              <input
                type="date"
                disabled={aadharVerified}
                style={{
                  width: '100%',
                  padding: '5px',
                  border: '1px solid #ced4da',
                  borderRadius: '4px',
                  fontSize: '18px',
                }}
                value={docDob}
                onChange={(e) => {
                  setDocDob(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row className="mt-3 px-4">
            <Col md={6}>
              {govtFileId ? (
                <Col md={12} className="final-personal-col-1">
                  <p className="mb-2 text-success">
                    Official Government-Issued Supporting Document Uploaded
                    Successfully
                  </p>
                  <FormControl fullWidth>
                    <div className={`upload-box-success`}>
                      <label
                        htmlFor="govt-file-upload"
                        onClick={() => {
                          showFileInNewTab(govtFileId);
                        }}
                        className={`file-success`}
                      >
                        {'View File'}
                      </label>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => {
                          resetFileUpload('GOVT');
                        }}
                        disabled={aadharVerified}
                      >
                        {'Remove File'}
                      </Button>
                    </div>
                  </FormControl>
                </Col>
              ) : (
                <Col md={12} className="final-personal-col-1">
                  <p className="mb-2">
                    Upload Official Government-Issued Supporting Document *
                  </p>
                  <FormControl fullWidth>
                    <div
                      className={`upload-box ${
                        govtFileUploading ? 'loading' : ''
                      }`}
                    >
                      <input
                        id="govt-file-upload"
                        type="file"
                        accept=".pdf,.png,.jpg,.jpeg"
                        onChange={(e) => {
                          handleFileChange(e, 'GOVT');
                        }}
                        className="upload-input"
                      />
                      <label
                        htmlFor="govt-file-upload"
                        className={`file-label ${
                          govtFileUploading ? 'disabled' : ''
                        }`}
                      >
                        {govtFileUploading ? (
                          'Uploading document issued by the government Document...'
                        ) : govtFile && !fileUploading ? (
                          <div>
                            <div>{govtFile?.name}</div>
                          </div>
                        ) : (
                          <>
                            <CloudUploadOutlinedIcon className="mr-2" /> Upload
                            Document issued by the government that specify your
                            birth date
                          </>
                        )}
                      </label>
                      <Button
                        variant="outline-success"
                        size="sm"
                        onClick={handleGovtFileUpload}
                        disabled={govtFileUploading}
                      >
                        {govtFileUploading ? 'Uploading...' : 'Upload File'}
                      </Button>
                    </div>
                    {govtFileUploading ? <LinearProgress /> : null}

                    {uploadError && (
                      <div className="error-text">{uploadError}</div>
                    )}
                  </FormControl>
                </Col>
              )}
            </Col>

            <Col md={6}>
              {fileId ? (
                <Col md={12} className="final-personal-col-1">
                  <p className="mb-2 text-success">
                    Labour Card Uploaded Successfully
                  </p>
                  <FormControl fullWidth>
                    <div className={`upload-box-success`}>
                      <label
                        htmlFor="file-upload"
                        onClick={() => {
                          showFileInNewTab(fileId);
                        }}
                        className={`file-success`}
                      >
                        {'View File'}
                      </label>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => {
                          resetFileUpload('LABOUR');
                        }}
                        disabled={aadharVerified}
                      >
                        {'Remove File'}
                      </Button>
                    </div>
                  </FormControl>
                </Col>
              ) : (
                <Col md={12} className="final-personal-col-1">
                  <p className="mb-2">Upload Your Labour Card *</p>
                  <FormControl fullWidth>
                    <div
                      className={`upload-box ${fileUploading ? 'loading' : ''}`}
                    >
                      <input
                        id="file-upload"
                        type="file"
                        accept=".pdf,.png,.jpg,.jpeg"
                        onChange={(e) => {
                          handleFileChange(e, 'LABOUR');
                        }}
                        className="upload-input"
                      />
                      <label
                        htmlFor="file-upload"
                        className={`file-label ${
                          fileUploading ? 'disabled' : ''
                        }`}
                      >
                        {fileUploading ? (
                          'Uploading Labour card...'
                        ) : labourCardFile && !fileUploading ? (
                          <div>
                            <div>{labourCardFile?.name}</div>
                          </div>
                        ) : (
                          <>
                            <CloudUploadOutlinedIcon className="mr-2" /> Upload
                            Labour Card
                          </>
                        )}
                      </label>
                      <Button
                        variant="outline-success"
                        size="sm"
                        onClick={handleFileUpload}
                        disabled={fileUploading}
                      >
                        {fileUploading ? 'Uploading...' : 'Upload File'}
                      </Button>
                    </div>
                    {fileUploading ? <LinearProgress /> : null}

                    {uploadError && (
                      <div className="error-text">{uploadError}</div>
                    )}
                  </FormControl>
                </Col>
              )}
            </Col>
          </Row>
          <hr />
          <Row className="mx-2 mt-2">
            <Col md={6}>
              <PersonalDetailsFromSevasindhu labourData={labourDetails} />
            </Col>
            <Col md={6}>
              <Row className="final all-details-subtitle-row">
                <Col xs={12} md={12} className="profile-title">
                  <img alt="..." src={personal} />
                  <h1>Personal Details as per Aadhaar</h1>
                </Col>
              </Row>
              {aadharVerified && aadhaarData ? (
                <div>
                  <Row>
                    <div className="d-flex justify-content-center align-items-center">
                      {userPhotoID ? (
                        <img
                          style={{
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            padding: '5px',
                          }}
                          src={`${SERVER}/upload/v2/file/${userPhotoID}?token=${getTokenCookie()}`}
                          width={150}
                          height={150}
                          alt="userPhoto"
                        />
                      ) : (
                        <CircularProgress />
                      )}
                    </div>
                  </Row>
                  <Row className="px-5">
                    <Col md={12} className="final-personal-col-1 px-3">
                      <p className="mb-0">Name</p>
                      <FormControl fullWidth>
                        <TextField
                          name="firstname"
                          value={`${aadhaarData?.name || ''}`}
                          disabled
                        />
                      </FormControl>
                    </Col>

                    <Col md={12} className="final-personal-col-1 px-3 mt-1">
                      <p className="mb-0">Age </p>
                      <FormControl fullWidth className="formcontrol2">
                        <TextField disabled value={aadhaarData?.age || ''} />
                      </FormControl>
                    </Col>
                  </Row>

                  <Row className="px-5 mt-1">
                    <Col md={12} className="final-personal-col-1 px-3">
                      <p className="mb-0">Date Of Birth</p>
                      <FormControl fullWidth>
                        <TextField
                          name="dob"
                          value={`${
                            moment(aadhaarData?.dob).format('DD-MM-YYYY') || ''
                          }`}
                          disabled
                        />
                      </FormControl>
                    </Col>
                    <Col md={12} className="final-personal-col-1 px-3 mt-1">
                      <p className="mb-0">Gender</p>
                      <FormControl fullWidth>
                        <TextField
                          name="dob"
                          value={`${aadhaarData?.gender}`}
                          disabled
                        />
                      </FormControl>
                    </Col>
                  </Row>
                </div>
              ) : (
                <Col>
                  <div className="d-flex justify-content-center align-items-center py-2">
                    <Tooltip
                      title={
                        'Please Fill the details and upload the required documents before verifying the Aadhaar'
                      }
                      arrow
                      placement="top"
                      disableHoverListener={
                        fileId && govtFileId && docDob && certSelectOption
                      }
                    >
                      <span>
                        <Button
                          className={`${
                            aadharVerified
                              ? 'btn bg-success btn-lg'
                              : 'btn bg-primary btn-lg'
                          }`}
                          onClick={handleVerifyAadhaar}
                          disabled={
                            aadharVerified ||
                            !fileId ||
                            aadhaarLoading ||
                            !govtFileId ||
                            !docDob ||
                            !certSelectOption
                          }
                        >
                          {aadharVerified ? (
                            <VerifiedIcon />
                          ) : (
                            <DoubleArrowIcon />
                          )}
                          <span className="px-2 fs-5 font-weight-bolder">
                            {aadharVerified
                              ? 'Aadhaar Verifed'
                              : 'Click here to verify the Aadhaar'}
                          </span>
                        </Button>
                      </span>
                    </Tooltip>
                  </div>
                </Col>
              )}
            </Col>
          </Row>

          <Row className="mb-3">
            <Col className="d-flex justify-content-center mt-4">
              <Button
                onClick={insertAndUpdateSurveyDetails}
                size="lg"
                variant="success"
                style={{ width: '60vw' }}
                disabled={isSubmitting || !aadharVerified}
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default SevasindhuLabourDetails;
