import "date-fns";
import {
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  withStyles,
  Menu,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextareaAutosize,
  SwipeableDrawer,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import logo from "assets/images/Karnataka_logo_100_Pixels.png";

import CloseIcon from "@material-ui/icons/Close";
import ECard from "assets/images/ecard-01.svg";
import incomplete from "assets/images/2931158-200-01 copy.svg";
import pendingicon from "assets/images/2931158-200-01.svg";
import usericon from "assets/images/Icon awesome-user.svg";
import checkwhite from "assets/images/Subtraction 1.svg";
import Registration from "assets/images/Registration svg-01.svg";
import Renewal from "assets/images/Renew Subscription-595b40b75ba036ed117d9bb4.svg";
import Schemes from "assets/images/scheme-svgrepo-com.svg";
import SchemeStatus from "assets/images/schemss-01.svg";
// import SchemeStatus from 'assets/images/scheme-svgrepo-com (1).svg'
import rejected from "assets/images/close (2).svg";

import { useDispatch, useSelector } from "react-redux";
import {
  getUser,
  getFile,
  getUserRegistrationDetails,
  SERVER,
  setLocaleWithFallback,
} from "store/actions/user.actions";
import { Translate } from "react-redux-i18n";
import Modal from "@mui/material/Modal";

import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import { Link } from "react-router-dom";
import {
  deleteAllCookies,
  getTokenCookie,
  getTokenCookieAdmin,
  showToast,
} from "utils/tools";
import axios from "axios";
import moment from "moment";
import logout from "assets/images/Logout.svg";
import { Tooltip } from "@material-ui/core";
import back from "assets/images/Icon awesome-arrow-circle-left.svg";
import Badge from "@mui/material/Badge";
import NotificationIcon from "assets/images/NotificationIcon.svg";
import Box from "@mui/material/Box";
import ReactPaginate from "react-paginate";
import cookie from "react-cookies";
import { Pagination, Stack } from "@mui/material";

import language from "assets/images/translate (1).svg";
import { getData } from "./Acknowledgment";
import Ecard from "components/Documents/Ecard";
import RejectedReasonsDialog from "components/adminPortal/globals/RejectedReasonsDialog";
import { useState } from "react";

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);

  const [renewaldate, setrenewaldate] = React.useState("");
  const [allnotifications, setallNotifications] = React.useState([]);
  const [notification, setNotification] = React.useState([]);
  const [showAppStatus, setshowAppStatus] = React.useState(true);
  const [get_user_appeal, setget_user_appeal] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [allValues, setAllValues] = React.useState({
    language: users.profile_details.language,
    users: users.profile_details.users,
  });
  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage, setPostPerPage] = React.useState(4);
  const [pageNumber, setPageNumber] = React.useState(0);

  const handleChange = (event) => {
    setAllValues({
      ...allValues,

      [event.target.name]: event.target.value,
    });

    if (event.target.name === "language")
      dispatch(setLocaleWithFallback(event.target.value));
  };

  const StyledMenu = withStyles({
    paper: {
      border: "1px solid #d3d4d5",
      width: "350px",
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));

  //     useEffect(() => {
  //         console.log("allValues: " + JSON.stringify(allValues, undefined, 2))
  //     }, [allValues])
  //   ));

  const [rejectReason, setRejectReason] = React.useState(null);
  const [reasonLoading, setReasonLoading] = useState(false);
  const [reasonOpen, setReasonOpen] = useState(false);
  const [langValue, setLangValue] = useState("eng");

  const handleClickReasonOpen = (schemeAvailID, schemename, status) => {
    setReasonOpen(true);
    setReasonLoading(true);
    // setSchemeName(schemename);
    // callRejectReasons(schemeAvailID, status);
  };

  const handleReasonClose = () => {
    setReasonOpen(false);
  };

  const handleReasonChange = (event) => {
    setLangValue(event.target.value);
  };

  useEffect(() => {
    //   fetch(SERVER+'/user/get-user-registration-details', {
    //                     method: 'POST',
    //                     headers: {
    //                         'Content-Type': 'application/json',
    //                         // 'Authorization':`Bearer ${users.login_user.jwt}`,
    //                         'Authorization':`Bearer ${getTokenCookie()}`
    //                     },
    //                     body: JSON.stringify(
    //                         {
    //                             "key": "user_id",
    //                             "value": 1044937,
    //                             "board_id": 1,
    //                             "procedure_name": "all"
    //                         }
    //                     )
    //                 }).then(res=>res.json())
    //                 .then(data=>{
    //                     console.log(data.data.personal_details[0],"apiContent")
    //                     setRejectReason(data.data.personal_details[0].approval_rejection_description)
    //                 })
  }, [allValues]);

  useEffect(() => {
    if (
      get_user_appeal === "" &&
      get_user_appeal === undefined &&
      users.getUserRegistrationDetails !== undefined &&
      users.getUserRegistrationDetails !== null &&
      users.getUserRegistrationDetails.personal_details !== undefined &&
      users.getUserRegistrationDetails.personal_details.length > 0 &&
      users.getUserRegistrationDetails.personal_details[0]
        .is_renewal_rejected === 1
    ) {
      setStatus("renewalRejected");
    } else if (
      get_user_appeal === undefined &&
      users.getUserRegistrationDetails !== undefined &&
      users.getUserRegistrationDetails !== null &&
      users.getUserRegistrationDetails.personal_details !== undefined &&
      users.getUserRegistrationDetails.personal_details.length > 0 &&
      users.getUserRegistrationDetails.personal_details[0].is_approved
    ) {
      // if (
      //   users &&
      //   users.getUserRegistrationDetails &&
      //   users.getUserRegistrationDetails.address_details &&
      //   users.getUserRegistrationDetails.address_details[0] &&
      //   users.getUserRegistrationDetails.address_details[0].district ===
      //     "HAVERI"
      // ) {
      //   setStatus("unavailable");
      // } else {
      setStatus("approved");
      // }
    } else if (
      get_user_appeal === "" &&
      users.getUserRegistrationDetails !== undefined &&
      users.getUserRegistrationDetails !== null &&
      users.getUserRegistrationDetails.personal_details !== undefined &&
      users.getUserRegistrationDetails.personal_details.length > 0 &&
      users.getUserRegistrationDetails.personal_details[0].is_approved
    ) {
      // if (
      //   users &&
      //   users.getUserRegistrationDetails &&
      //   users.getUserRegistrationDetails.address_details &&
      //   users.getUserRegistrationDetails.address_details[0] &&
      //   users.getUserRegistrationDetails.address_details[0].district ===
      //     "HAVERI"
      // ) {
      //   setStatus("unavailable");
      // } else {
      setStatus("approved");
      // }
    }

    // else if (
    //     get_user_appeal === "" &&
    //     users.getUserRegistrationDetails !== undefined &&
    //     users.getUserRegistrationDetails !== null &&
    //     users.getUserRegistrationDetails.personal_details !== undefined &&
    //     users.getUserRegistrationDetails.personal_details.length > 0 &&
    //     users.getUserRegistrationDetails.personal_details[0].is_renewal_rejected) {
    //     setStatus("renewalRejected")
    // }
    else if (
      get_user_appeal === undefined &&
      users.getUserRegistrationDetails !== null &&
      users.getUserRegistrationDetails.personal_details !== undefined &&
      users.getUserRegistrationDetails.personal_details !== null &&
      users.getUserRegistrationDetails.personal_details.length > 0 &&
      users.getUserRegistrationDetails.personal_details[0].is_appealed !== null
    ) {
      setStatus("appealed");
    }
  }, [get_user_appeal]);

  useEffect(() => {
    if (users.user !== undefined && users.user !== null) {
      if (users.user.id !== undefined && users.user.id !== null) {
        let dataForRegDetails = {
          key: "user_id",
          value: users.user.id,
          board_id: 1,
          procedure_name: "all",
        };
        dispatch(getUserRegistrationDetails(dataForRegDetails));

        var config = {
          method: "get",
          url:
            SERVER +
            "/appeal/get_user_appeal?board_id=1&user_id=" +
            users.user.id,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getTokenCookie()}`,
          },
        };

        axios(config)
          .then(function (response) {
            // console.log("get_user_appeal.length response: "+response.data.data[0].length)

            setget_user_appeal(response.data.data[0]);
          })
          .catch(function (error) {});
      }
    }
  }, [users.user]);

  useEffect(() => {
    if (
      users.getUserRegistrationDetails !== undefined &&
      users.getUserRegistrationDetails !== null &&
      users.getUserRegistrationDetails.personal_details !== undefined &&
      users.getUserRegistrationDetails.personal_details.length > 0 &&
      users.getUserRegistrationDetails.personal_details[0]
        .is_edit_profile_availed &&
      users.getUserRegistrationDetails.personal_details[0]
        .is_edit_profile_availed === 1
    ) {
      setStatus("editProfile");
    } else if (
      users.getUserRegistrationDetails !== undefined &&
      users.getUserRegistrationDetails !== null &&
      users.getUserRegistrationDetails.personal_details !== undefined &&
      users.getUserRegistrationDetails.personal_details.length > 0 &&
      users.getUserRegistrationDetails.personal_details[0].is_renewal_claimed &&
      users.getUserRegistrationDetails.personal_details[0]
        .is_renewal_claimed === 1
    ) {
      setStatus("renewed");
    } else if (
      users.getUserRegistrationDetails !== undefined &&
      users.getUserRegistrationDetails !== null &&
      users.getUserRegistrationDetails.personal_details !== undefined &&
      users.getUserRegistrationDetails.personal_details.length > 0
      // &&
      // get_user_appeal !== undefined
    ) {
      setRejectReason(
        users.getUserRegistrationDetails.personal_details[0]
          .approval_rejection_description
      );

      if (
        users.getUserRegistrationDetails.personal_details[0].is_sent_approval
      ) {
        if (
          users.getUserRegistrationDetails.personal_details[0]
            .is_renewal_rejected === 1 &&
          users.getUserRegistrationDetails.certificate_details[0]
            .registration_status === "Rejected"
        ) {
          // setStatus("Your Registration is Approved Successfully")
          setStatus("renewalRejected");
        }
        if (
          users.getUserRegistrationDetails.personal_details[0]
            .is_renewal_rejected === 0 &&
          users.getUserRegistrationDetails.certificate_details[0]
            .registration_status === "Approved"
        ) {
          setStatus("approved");
          // if (
          //   users &&
          //   users.getUserRegistrationDetails &&
          //   users.getUserRegistrationDetails.address_details &&
          //   users.getUserRegistrationDetails.address_details[0] &&
          //   users.getUserRegistrationDetails.address_details[0].district ===
          //     'HAVERI'
          // ) {
          // setStatus('unavailable');
          // } else {

          // }
          // setStatus("Your Registration is Approved Successfully")
        } else if (
          users.getUserRegistrationDetails.personal_details[0].is_approved ||
          (users.getUserRegistrationDetails.certificate_details &&
            users.getUserRegistrationDetails.certificate_details.length > 0 &&
            users.getUserRegistrationDetails.certificate_details[0]
              .registration_status === "Approved" &&
            users.getUserRegistrationDetails.personal_details[0]
              .is_renewal_rejected === 1)
        ) {
          // setStatus("Your Registration is Approved Successfully")
          //    if(renewaldate){
          //     let dateString2 = renewaldate
          // let dateObj2 = new Date(dateString2);
          // let momentObj2 = moment(dateObj2);
          // let days_diff = momentObj2.diff(moment(Date.now()).startOf('day'), 'days', false)
          // console.log(days_diff,"days_diff");
          // if(days_diff <= 0)
          // {
          //     setStatus("expired")
          // }
          // }
          // else{
          //     setStatus("approved")
          // }
        } else if (
          users.getUserRegistrationDetails.personal_details &&
          users.getUserRegistrationDetails.personal_details.length &&
          users.getUserRegistrationDetails.personal_details[0].is_rejected
        ) {
          // setStatus("Your Registration is Pending for Approval")
          setStatus("rejected");
        } else if (
          (users.getUserRegistrationDetails &&
            users.getUserRegistrationDetails.certificate_details.length &&
            users.getUserRegistrationDetails.certificate_details[0]
              .registration_status === "Pending") ||
          users.getUserRegistrationDetails.certificate_details[0]
            .registration_status === "Draft Approved" ||
          users.getUserRegistrationDetails.certificate_details[0]
            .registration_status === "Draft Rejected"
        ) {
          // setStatus("Your Registration is Pending for Approval")
          setStatus("pending");
        }
      } else {
        // setStatus("Please Complete Your Registration")
        setStatus("incomplete");
      }

      if (
        get_user_appeal === undefined &&
        get_user_appeal === "" &&
        users.getUserRegistrationDetails !== undefined &&
        users.getUserRegistrationDetails !== null &&
        users.getUserRegistrationDetails.personal_details !== undefined &&
        users.getUserRegistrationDetails.personal_details.length > 0 &&
        users.getUserRegistrationDetails.personal_details[0]
          .is_renewal_rejected === 1
      ) {
        setStatus("renewalRejected");
      } else if (
        get_user_appeal === undefined &&
        users.getUserRegistrationDetails !== undefined &&
        users.getUserRegistrationDetails !== null &&
        users.getUserRegistrationDetails.personal_details !== undefined &&
        users.getUserRegistrationDetails.personal_details.length > 0 &&
        users.getUserRegistrationDetails.personal_details[0].is_approved
      ) {
        //       if(renewaldate){
        //     let dateString2 = renewaldate
        // let dateObj2 = new Date(dateString2);
        // let momentObj2 = moment(dateObj2);
        // let days_diff = momentObj2.diff(moment(Date.now()).startOf('day'), 'days', false)
        // console.log(days_diff,"days_diff");
        // if(days_diff <= 0)
        // {
        //     setStatus("expired")
        // }
        // }else{
        // setStatus("approved")
        // }
      } else if (
        get_user_appeal === "" &&
        users.getUserRegistrationDetails !== undefined &&
        users.getUserRegistrationDetails !== null &&
        users.getUserRegistrationDetails.personal_details !== undefined &&
        users.getUserRegistrationDetails.personal_details.length > 0 &&
        users.getUserRegistrationDetails.personal_details[0].is_approved
      ) {
        //       if(renewaldate){
        //     let dateString2 = renewaldate
        // let dateObj2 = new Date(dateString2);
        // let momentObj2 = moment(dateObj2);
        // let days_diff = momentObj2.diff(moment(Date.now()).startOf('day'), 'days', false)
        // console.log(days_diff,"days_diff");
        // if(days_diff <= 0)
        // {
        //     setStatus("expired")
        // }
        // }else{
        //     setStatus("approved")
        // }
      } else if (
        get_user_appeal === undefined &&
        users.getUserRegistrationDetails !== null &&
        users.getUserRegistrationDetails.personal_details !== undefined &&
        users.getUserRegistrationDetails.personal_details !== null &&
        users.getUserRegistrationDetails.personal_details.length > 0 &&
        users.getUserRegistrationDetails.personal_details[0].is_appealed !==
          null
      ) {
        setStatus("appealed");
      }
    }
  }, [users.getUserRegistrationDetails]);

  const SchemesClicked = () => {
    if (
      users &&
      users.getUserRegistrationDetails &&
      users.getUserRegistrationDetails.personal_details &&
      users.getUserRegistrationDetails.personal_details[0].is_rejected === true
    ) {
      showToast("ERROR", "Application is rejected");
      return;
    }

    if (users && users.user.is_expired) {
      showToast(
        "ERROR",
        "This service is unavailable as you have claimed for Funeral Assistance Scheme"
      );
      return;
    }
    if (
      users.getUserRegistrationDetails !== undefined &&
      users.getUserRegistrationDetails !== null &&
      users.getUserRegistrationDetails.personal_details !== undefined &&
      users.getUserRegistrationDetails.personal_details.length > 0 &&
      users.getUserRegistrationDetails.personal_details[0]
        .is_edit_profile_availed &&
      users.getUserRegistrationDetails.personal_details[0]
        .is_edit_profile_availed === 1
    ) {
      showToast(
        "ERROR",
        "Please wait after your Edit Profile request is processed, you will be able to apply schemes."
      );
    } else {
      if (renewaldate !== undefined && renewaldate !== "") {
        var dateString2 = renewaldate;
        var dateObj2 = new Date(dateString2);
        var momentObj2 = moment(dateObj2);
        var days_diff = momentObj2.diff(
          moment(Date.now()).startOf("day"),
          "days",
          false
        );

        if (users.user !== null && users.user.is_approved) {
          props.history.push("/kbocwwb-schemes-home");
        } else {
          showToast("ERROR", "Your Application is Not Yet Approved!");
        }
      } else {
        showToast("ERROR", "Your Application is Not Yet Approved!");
      }
    }
  };

  // const SchemesClicked = () => {
  //   showToast('ERROR', 'This service is unavailable');
  // };

  const EcardClicked = () => {
    if (!users?.user?.is_approved) {
      showToast("ERROR", "Your Application is not yet approved or submitted!");
      return;
    }
    if (
      users?.getUserRegistrationDetails?.personal_details[0]
        ?.is_edit_profile_availed === 1
    ) {
      showToast("ERROR", "Edit Profile is pending for approval");
      return;
    }
    props.history.push(
      `/e-card/uid=${users.user.id}_bid=${users.user.board_id}`
    );
  };

  //   if (users.user !== null && users.user.is_approved) {
  //     if (renewaldate !== undefined && renewaldate !== '') {
  //       var dateString2 = renewaldate;
  //       var dateObj2 = new Date(dateString2);
  //       var momentObj2 = moment(dateObj2);
  //       var days_diff = momentObj2.diff(
  //         moment(Date.now()).startOf('day'),
  //         'days',
  //         false,
  //       );

  //       // if (days_diff <= 0) {
  //       //     showToast("ERROR", "Please Renew!")
  //       // }
  //       // else{
  //       //  }
  //       if (
  //         users.getUserRegistrationDetails.personal_details[0]
  //           .is_edit_profile_availed === 1
  //       ) {
  //         showToast('WARN', 'Edit Profile Pending for Approval');
  //       } else {
  //         props.history.push(
  //           `/e-card/uid=${users.user.id}_bid=${users.user.board_id}`,
  //         );
  //       }
  //     }
  //   } else {
  //     showToast('ERROR', 'Your Application is Not Yet Approved!');
  //   }
  // };
  // const RenewalClicked = () => {
  //   const dob =
  //     users &&
  //     users.getUserRegistrationDetails &&
  //     users.getUserRegistrationDetails.personal_details &&
  //     users.getUserRegistrationDetails.personal_details[0].date_of_birth;
  //   const diffAge = moment().diff(dob, 'years');
  //   if (users && users.user.is_expired) {
  //     showToast(
  //       'ERROR',
  //       'This service is unavailable as you have claimed for Funeral Assistance Scheme',
  //     );
  //   } else {
  //     if (diffAge < 60) {
  //       if (
  //         users.getUserRegistrationDetails.personal_details[0] !== null &&
  //         users.getUserRegistrationDetails.personal_details[0] !== undefined &&
  //         (users.getUserRegistrationDetails.personal_details[0]
  //           .is_renewal_scheme_claimed === 0 ||
  //           users.getUserRegistrationDetails.personal_details[0]
  //             .is_renewal_scheme_claimed === 2)
  //       ) {
  //         if (renewaldate !== undefined && renewaldate !== '') {
  //           //     props.history.push("/renewals");
  //           // }
  //           var dateString2 = renewaldate;
  //           var dateObj2 = new Date(dateString2);
  //           var momentObj2 = moment(dateObj2);
  //           var days_diff = momentObj2.diff(
  //             moment().startOf('D'),
  //             'days',
  //             false,
  //           );

  //           if (days_diff <= 0) {
  //             props.history.push('/renewals');
  //           } else {
  //             showToast(
  //               'ERROR',
  //               'You Cannot Renew Before 1 Year of Registration!',
  //             );
  //           }
  //         } else {
  //           showToast('ERROR', 'Your Application is Not Yet Approved!');
  //         }
  //       } else {
  //         showToast(
  //           'ERROR',
  //           'Your Application has been already applied for Renewal!',
  //         );
  //       }
  //     } else {
  //       showToast(
  //         'ERROR',
  //         'This service is unavailable for the age above 60 years',
  //       );
  //     }
  //   }
  // };

  const RenewalClicked = () => {
    // if (users?.user?.age >= 60) {
    //   showToast(
    //     'ERROR',
    //     'This service is unavailable for the age 60 and above',
    //   );
    //   return;
    // }
    console.log(status);
    if (status === "rejected") {
      showToast("ERROR", "Not Allowed");
      return;
    }
    if (users?.user?.is_renewal_extended === 1) {
      if (
        users?.getUserRegistrationDetails?.personal_details[0]
          ?.is_renewal_claimed === 1
      ) {
        showToast("ERROR", "Renewal already applied");
        return;
      }
      if (users?.user?.ISACTIVEUSER === 2) {
        props.history.push(`/renewal/${users.user?.id}`);
      } else {
        showToast("ERROR", "Card validity is not expired");
      }
    } else {
      if (users?.user?.age >= 60) {
        showToast(
          "ERROR",
          "This service is unavailable for the age 60 and above"
        );
      } else {
        if (
          users?.getUserRegistrationDetails?.personal_details[0]
            ?.is_renewal_claimed === 1
        ) {
          showToast("ERROR", "Renewal already applied");
          return;
        }
        if (users?.user?.ISACTIVEUSER === 2) {
          props.history.push(`/renewal/${users.user?.id}`);
        } else {
          showToast("ERROR", "Card validity is not expired");
        }
      }
    }
  };

  console.log({ users });

  const RegistrationClicked = () => {
    if (users?.user?.is_expired) {
      showToast(
        "ERROR",
        "This service is unavailable as you have claimed for Funeral Assistance Scheme"
      );
      return;
    }
    if (
      users?.getUserRegistrationDetails?.personal_details[0]
        ?.is_edit_profile_availed === 1
    ) {
      showToast("ERROR", "Already applied for edit profile.");
      return;
    }
    if (users?.user?.ISACTIVEUSER === 2) {
      showToast("ERROR", "Please Renew!");
      return;
    }

    if (
      users?.user?.tab_name === "90_days_certificate_details" &&
      status === "rejected" &&
      // users?.getUserRegistrationDetails?.rejection_reasons.length > 0 &&
      users?.getUserRegistrationDetails?.application_details &&
      moment().diff(
        moment(
          users?.getUserRegistrationDetails?.personal_details[0].approved_date
        ),
        "days"
      ) > 90
    ) {
      props.history.push(
        `/profile/personal/${users.getUserRegistrationDetails.personal_details[0].id}`
      );
      return;
    }
    users?.user?.tab_name === null &&
      props.history.push(
        `/profile/personal/${users.getUserRegistrationDetails.personal_details[0].id}`
      );
    users?.user?.tab_name === "personal_details" &&
      props.history.push(
        `/profile/address/${users.getUserRegistrationDetails.personal_details[0].id}`
      );
    users?.user?.tab_name === "address_details" &&
      props.history.push(
        `/profile/family/${users.getUserRegistrationDetails.personal_details[0].id}`
      );
    users?.user?.tab_name === "family_details" &&
      props.history.push(
        `/profile/bank/${users.getUserRegistrationDetails.personal_details[0].id}`
      );
    users?.user?.tab_name === "bank_details" &&
      props.history.push(
        `/profile/nintyDays/${users.getUserRegistrationDetails.personal_details[0].id}`
      );
    users?.user?.tab_name === "90_days_certificate_details" &&
      props.history.push(
        `/profile/review/${users.getUserRegistrationDetails.personal_details[0].id}`
      );
  };

  // const RegistrationClicked = () => {
  //   if (users && users.user.is_expired) {
  //     showToast(
  //       'ERROR',
  //       'This service is unavailable as you have claimed for Funeral Assistance Scheme',
  //     );
  //   } else {
  //     if (
  //       users.getUserRegistrationDetails.personal_details[0]
  //         .is_edit_profile_availed === 1
  //     ) {
  //       showToast('WARN', 'Edit Profile Pending for Approval');
  //     } else {
  //       if (renewaldate !== undefined && renewaldate !== '') {
  //         var dateString2 = renewaldate;
  //         var dateObj2 = new Date(dateString2);
  //         var momentObj2 = moment(dateObj2);
  //         var days_diff = momentObj2.diff(
  //           moment(Date.now()).startOf('day'),
  //           'days',
  //           false,
  //         );

  //         if (days_diff <= 0) {
  //           showToast('ERROR', 'Please Renew!');
  //         } else {
  //           users.user.tab_name === null &&
  //             props.history.push(
  //               `/profile/personal/${users.getUserRegistrationDetails.personal_details[0].id}`,
  //             );
  //           users.user.tab_name === 'personal_details' &&
  //             props.history.push(
  //               `/profile/address/${users.getUserRegistrationDetails.personal_details[0].id}`,
  //             );
  //           users.user.tab_name === 'address_details' &&
  //             props.history.push(
  //               `/profile/family/${users.getUserRegistrationDetails.personal_details[0].id}`,
  //             );
  //           users.user.tab_name === 'family_details' &&
  //             props.history.push(
  //               `/profile/bank/${users.getUserRegistrationDetails.personal_details[0].id}`,
  //             );
  //           users.user.tab_name === 'bank_details' &&
  //             props.history.push(
  //               `/profile/nintyDays/${users.getUserRegistrationDetails.personal_details[0].id}`,
  //             );
  //           users.user.tab_name === '90_days_certificate_details' &&
  //             props.history.push(
  //               `/profile/review/${users.getUserRegistrationDetails.personal_details[0].id}`,
  //             );
  //         }
  //       } else if (
  //         users.getUserRegistrationDetails !== undefined &&
  //         users.getUserRegistrationDetails !== null &&
  //         users.getUserRegistrationDetails.personal_details !== undefined &&
  //         users.getUserRegistrationDetails.personal_details[0] !== undefined &&
  //         users.getUserRegistrationDetails.personal_details[0]
  //           .is_sent_approval !== undefined &&
  //         users.getUserRegistrationDetails.personal_details[0]
  //           .is_sent_approval !== true
  //       ) {
  //         if (
  //           getTokenCookie() !== null ||
  //           getTokenCookie() !== undefined ||
  //           getTokenCookie() !== ''
  //         ) {
  //           users.user.tab_name === null &&
  //             props.history.push(
  //               `/profile/personal/${users.getUserRegistrationDetails.personal_details[0].id}`,
  //             );
  //           users.user.tab_name === 'personal_details' &&
  //             props.history.push(
  //               `/profile/address/${users.getUserRegistrationDetails.personal_details[0].id}`,
  //             );
  //           users.user.tab_name === 'address_details' &&
  //             props.history.push(
  //               `/profile/family/${users.getUserRegistrationDetails.personal_details[0].id}`,
  //             );
  //           users.user.tab_name === 'family_details' &&
  //             props.history.push(
  //               `/profile/bank/${users.getUserRegistrationDetails.personal_details[0].id}`,
  //             );
  //           users.user.tab_name === 'bank_details' &&
  //             props.history.push(
  //               `/profile/nintyDays/${users.getUserRegistrationDetails.personal_details[0].id}`,
  //             );
  //           users.user.tab_name === '90_days_certificate_details' &&
  //             props.history.push(
  //               `/profile/review/${users.getUserRegistrationDetails.personal_details[0].id}`,
  //             );
  //           console.log('IN2');
  //         } else {
  //           showToast(
  //             'ERROR',
  //             'Registration is Incomplete.\nPlease contact your Labour Inspector.',
  //           );
  //         }
  //       } else {
  //         users.user.tab_name === null &&
  //           props.history.push(
  //             `/profile/personal/${users.getUserRegistrationDetails.personal_details[0].id}`,
  //           );
  //         users.user.tab_name === 'personal_details' &&
  //           props.history.push(
  //             `/profile/address/${users.getUserRegistrationDetails.personal_details[0].id}`,
  //           );
  //         users.user.tab_name === 'address_details' &&
  //           props.history.push(
  //             `/profile/family/${users.getUserRegistrationDetails.personal_details[0].id}`,
  //           );
  //         users.user.tab_name === 'family_details' &&
  //           props.history.push(
  //             `/profile/bank/${users.getUserRegistrationDetails.personal_details[0].id}`,
  //           );
  //         users.user.tab_name === 'bank_details' &&
  //           props.history.push(
  //             `/profile/nintyDays/${users.getUserRegistrationDetails.personal_details[0].id}`,
  //           );
  //         users.user.tab_name === '90_days_certificate_details' &&
  //           props.history.push(
  //             `/profile/review/${users.getUserRegistrationDetails.personal_details[0].id}`,
  //           );
  //       }
  //     }
  //   }
  // };

  const AppealButtonPressed = () => {
    // setshowAppStatus(false)

    // console.log("appealreason: " + allValues.appealreason)

    if (get_user_appeal !== undefined && get_user_appeal !== "") {
      if (
        allValues.appealreason !== undefined &&
        allValues.appealreason !== null &&
        allValues.appealreason !== ""
      ) {
        var data = JSON.stringify({
          board_id: get_user_appeal.board_id,
          user_id: users.user.id,
          approval_id: get_user_appeal.approval_id,
          labour_work_certificate_id:
            get_user_appeal.labour_work_certificate_id,
          appeal_to_user_id: get_user_appeal.appeal_to_user_id,
          appeal_description: allValues.appealreason,
        });

        var config = {
          method: "post",
          url: SERVER + "/appeal/make_an_appeal",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: data,
        };

        axios(config)
          .then(function (response) {
            if (
              response.data.success !== undefined &&
              response.data.success === true
            ) {
              handleClose();
              showToast("SUCCESS", "Successfully Appealed!");
              // props.history.push("/dashboard-user");
              // window.location.reload();

              let dataForRegDetails = {
                key: "user_id",
                value: users.user.id,
                board_id: 1,
                procedure_name: "all",
              };
              dispatch(getUserRegistrationDetails(dataForRegDetails));

              var config = {
                method: "get",
                url:
                  SERVER +
                  "/appeal/get_user_appeal?board_id=1&user_id=" +
                  users.user.id,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${getTokenCookie()}`,
                },
              };

              axios(config)
                .then(function (response) {
                  // console.log("get_user_appeal.length response: "+response.data.data[0].length)

                  setget_user_appeal(response.data.data[0]);
                })
                .catch(function (error) {
                  console.error("get_user_appeal error: " + error);
                });

              setshowAppStatus(true);
            }
          })
          .catch(function (error) {
            handleClose();
            setshowAppStatus(true);
          });
      } else {
        showToast("ERROR", "Please write reason to appeal.");
      }
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    sessionStorage.clear();
    dispatch(getFile("delete"));

    if (
      getTokenCookie() === null ||
      getTokenCookie() === undefined ||
      getTokenCookie() === ""
    ) {
      props.history.push("/login");
    } else if (
      users.user !== undefined &&
      users.user !== null &&
      users.user !== ""
    ) {
      if (users.generate_otp !== null) {
        if (getTokenCookie() !== undefined) {
          dispatch(getUser(users.generate_otp.data.phone_number));
        }
      } else if (users.generate_otp_registration !== null) {
        if (getTokenCookie() !== undefined) {
          dispatch(getUser(users.generate_otp_registration.data.phone_number));
        }
      }
      setStatus("");

      var data = {
        reader_id: users.user.id,
        receiver_id: users.user.id,
        is_global: false,
        is_labour: true,
      };
      getNotifications(data, 1);

      var datagetrenewaldate = JSON.stringify({
        user_id: users.user.id,
      });

      var configgetrenewaldate = {
        method: "post",
        url: SERVER + "/user/get-renewal-date",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: datagetrenewaldate,
      };

      axios(configgetrenewaldate)
        .then(function (response) {
          setrenewaldate(response.data.data.recordsets[0][0].next_renewal_date);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      props.history.push("/login");
    }
  }, []);

  const [open, setOpen] = React.useState(false);
  const [opennotification, setopennotification] = React.useState(false);

  const handleClickOpen = () => {
    if (get_user_appeal !== undefined && get_user_appeal !== "") {
      setOpen(true);
      setshowAppStatus(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setshowAppStatus(true);
  };
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = notification.slice(indexOfFirstPost, indexOfLastPost);
  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);
  const paginate = (pageNum) => setCurrentPage(pageNum);
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(notification.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const updateNotification = async (_id) => {
    var config = {
      method: "patch",
      url: SERVER + "/notifications/update_notification_read",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: {
        id: _id,
        reader_id: users.user.id,
      },
    };
    await axios(config)
      .then(function (response) {})
      .catch(function (error) {
        console.error("notification error: " + error);
      });

    var data = {
      receiver_id: users.user.id,
      is_global: false,
      is_labour: true,
    };
    getNotifications(data, 1);
  };

  const clearAllNotification = () => {
    notification.map((x) => {
      updateNotification(x._id);
    });
  };
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [openNotifications, setOpenNotifications] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenNotifications(open);
  };

  const handlePageChange = (event, value) => {
    var data = {
      reader_id: users.user.id,
      receiver_id: users.user.id,
      is_global: false,
      is_labour: true,
    };
    getNotifications(data, value);
  };

  const getNotifications = (data, page) => {
    var config = {
      method: "post",
      url: `${SERVER}/notifications/get_user_notifications?limit=5&page=${page}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        setallNotifications(response.data);

        setNotification(response.data.docs);
      })
      .catch(function (error) {
        console.error("notification error: " + error);
      });
  };

  return (
    <>
      <SwipeableDrawer
        anchor={"right"}
        open={openNotifications}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Box
          // sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
          // sx={{ width: 412 }}
          sx={{ width: "auto", maxWidth: 412 }}
          role="presentation"
          // onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
          className="list_notifications_box"
        >
          <div className="list_notifications_header">
            <div>Notifications</div>
            <CloseIcon
              className="closeNoti mr-2"
              onClick={toggleDrawer(false)}
            />
          </div>
          {/* <li key="index" className="list_notifications_clearall">
                        <Tooltip title="Clear All Notifications" placement="bottom-start" arrow interactive> 
                            <Link to="#">Clear All</Link>
                        </Tooltip>
                    </li> */}

          {allnotifications !== undefined &&
            allnotifications.docs !== undefined &&
            allnotifications.docs.map((x, index) => (
              <li key={index} className="list_notifications">
                <div>
                  <Tooltip
                    title="Clear Notification"
                    placement="left"
                    arrow
                    interactive
                  >
                    <CloseIcon
                      className="dismissNoti"
                      onClick={() => updateNotification(x._id)}
                    />
                  </Tooltip>
                  <p className="notification_time m-0 mb-2 p-0">
                    {moment(x.updatedAt).format("DD/MM/YYYY  hh:mm  a")}
                  </p>
                  <p className="m-0 p-0">{x.message}</p>
                </div>
              </li>
            ))}
          <nav>
            <Stack
              // spacing={2}
              className="p-2 pagination_noti"
            >
              <Pagination
                // showFirstButton showLastButton
                color="primary"
                count={allnotifications.totalPages}
                page={allnotifications.page}
                variant="outlined"
                onChange={handlePageChange}
              />
            </Stack>
          </nav>
        </Box>
      </SwipeableDrawer>
      <div className="root">
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xl"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="form-dialog-title">
            Are you sure you want to Appeal?
          </DialogTitle>
          <DialogContent>
            <TextareaAutosize
              variant="outlined"
              multiline
              rows={3}
              style={{ width: "500px", padding: "10px" }}
              placeholder="Appeal Reason!"
              name="appealreason"
              value={allValues.appealreason}
              onChange={handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outline-secondary">
              Cancel
            </Button>
            <Button onClick={AppealButtonPressed} variant="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        <Row className="top-div">
          <Row className="topbar-row">
            <Col xs={12} md={6} lg={6} className="">
              <div className="logo-div-profile">
                <img id="logo" src={logo} alt="..." className="logo-img" />
                <h1 className="logo-text">
                  <Translate value="header.title" />
                  <br />
                  <Translate value="header.sub" />
                </h1>
              </div>
            </Col>
            <Col className="">
              <div className="d-flex justify-content-evenly usericon-header-logout">
                <div className="mt-1">
                  <Badge
                    badgeContent={
                      allnotifications !== undefined &&
                      allnotifications.totalDocs
                    }
                    color="primary"
                  >
                    <img
                      onClick={toggleDrawer(true)}
                      src={NotificationIcon}
                      alt="..."
                    />
                  </Badge>
                </div>
                <img alt="..." src={usericon} className="usericon-img" />

                <p>
                  {users &&
                    users.user &&
                    `${users.user.first_name ?? ""} 
                ${users.user.middle_name ?? ""} 
                ${users.user.last_name ?? ""}`}
                </p>
                <Tooltip title="Logout" placement="top-start" arrow interactive>
                  <img
                    alt="..."
                    src={logout}
                    className="logout-img"
                    onClick={() => {
                      props.history.push("/login");
                    }}
                  />
                </Tooltip>
                <div className="d-flex justify-content-end ms-5">
                  <Col
                    xs={12}
                    md={4}
                    lg={3}
                    className="d-flex justify-content-end mt-2 me-2 ms-2 w-100"
                  >
                    <Select
                      className="select-language"
                      style={{ width: "100%" }}
                      variant="outlined"
                      labelId="demo-simple-select-required-label"
                      value={allValues.language}
                      name="language"
                      displayEmpty
                      onChange={handleChange}
                    >
                      <MenuItem value="">
                        <ListItemIcon>
                          <img
                            alt="..."
                            src={language}
                            className="language-img"
                          />
                        </ListItemIcon>
                        <ListItemText primary="Select Language" />
                      </MenuItem>
                      {/* <MenuItem value="en">English</MenuItem>
                                        <MenuItem value="ka">Kannada</MenuItem> */}
                      <MenuItem value="en">
                        <ListItemText primary="English" />
                      </MenuItem>
                      <MenuItem value="ka">
                        <ListItemText primary="ಕನ್ನಡ" />
                      </MenuItem>
                    </Select>
                  </Col>
                </div>
              </div>
            </Col>
          </Row>
        </Row>

        <Row className="dashboarduser-row2 container-fluid m-0 p-0">
          <Col xs={12} className="dashboarduser-col2 status p-0">
            {/* <Link to="#" className="profile-title-status-scheme" onClick={() => props.history.goBack()}>
                                <img alt="..." className="cal-icon hover-icn" src={back} />
                            </Link> */}
            <div className="ApplicationStatus">
              {/* <Link to="#" className="profile-title-status-scheme" onClick={() => props.history.goBack()}>
                                <img alt="..." className="cal-icon hover-icn" src={back} />
                            </Link> */}
              <Translate value="applicationStatus" />
            </div>
            {/* <p>{status}</p> */}

            {showAppStatus ? (
              {
                // unavailable: (
                //   <>
                //     <div className="statusbar rejected">
                //       <img alt="..." src={rejected} />
                //       <p>This service is unavailable for Haveri District</p>
                //     </div>
                //   </>
                // ),

                renewalRejected: (
                  <>
                    <div className="statusbar outer">
                      <div className="statusbar rejected">
                        <img alt="..." src={rejected} />
                        <p>
                          <Translate value="renewalregStatus" />
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClickReasonOpen()}
                          >
                            {" "}
                            .Click here to view rejected reasons
                          </span>
                          {get_user_appeal === undefined ||
                          (users.getUserRegistrationDetails !== undefined &&
                            users.getUserRegistrationDetails !== null &&
                            users.getUserRegistrationDetails
                              .personal_details !== undefined &&
                            users.getUserRegistrationDetails
                              .personal_details[0] !== undefined &&
                            users.getUserRegistrationDetails.personal_details[0]
                              .is_appealed &&
                            users.getUserRegistrationDetails.personal_details[0]
                              .is_rejected) ? null : (
                            <>
                              {/* <Link
                                to="#"
                                onClick={handleClickOpen}
                                className="ml-3 appealLink"
                              >
                                <Translate value="clickAppeal" />
                              </Link> */}
                            </>
                          )}
                        </p>
                      </div>
                      {/* <div className="statusbar reason">
                        <p className="mb-2 reason-title">
                          <Translate value="reason" />
                        </p>
                        <p
                          className="pl-0"
                          style={{
                            fontFamily: 'inherit',
                            color: 'red',
                            fontWeight: 'thicker',
                          }}
                        >
                          {users.getUserRegistrationDetails !== undefined &&
                            users.getUserRegistrationDetails !== null &&
                            users.getUserRegistrationDetails
                              .certificate_details !== undefined &&
                            users.getUserRegistrationDetails
                              .certificate_details[0] !== undefined &&
                            users.getUserRegistrationDetails
                              .certificate_details[0].remarks &&
                            users.getUserRegistrationDetails
                              .certificate_details[0].order_remarks}
                        </p>
                        
                      </div> */}
                      {
                        <RejectedReasonsDialog
                          open={reasonOpen}
                          handleClose={handleReasonClose}
                          reasons={
                            users?.getUserRegistrationDetails?.rejection_reasons
                          }
                          oldReasons={
                            users?.getUserRegistrationDetails
                              ?.certificate_details[0]?.remarks
                          }
                          serviceName={"Renewal Rejected Reasons"}
                          // loading={reasonLoading}
                          value={langValue}
                          handleChange={handleReasonChange}
                        />
                      }
                    </div>
                  </>
                ),
                approved: (
                  <>
                    <div className="statusbar approved">
                      <img alt="..." src={checkwhite} />
                      <p>
                        <Translate value="approvedStatus" />
                      </p>
                    </div>
                  </>
                ),
                pending: (
                  <>
                    <div className="statusbar pending">
                      <img alt="..." src={pendingicon} />
                      <p>
                        <Translate value="pendingStatus" />
                      </p>
                    </div>
                  </>
                ),
                renewed: (
                  <>
                    <div className="statusbar pending">
                      <img alt="..." src={pendingicon} />
                      <p>
                        <Translate value="renewalStatus" />
                      </p>
                    </div>
                  </>
                ),
                incomplete: (
                  <>
                    <Link to="#" onClick={() => RegistrationClicked()}>
                      <div className="statusbar incomplete">
                        <img alt="..." src={incomplete} />
                        <p>
                          <Translate value="incompleteStatus" />
                        </p>
                      </div>
                    </Link>
                  </>
                ),
                editProfile: (
                  <>
                    <div className="statusbar pending">
                      <img alt="..." src={incomplete} />
                      <p>
                        <Translate value="editProfileStatus" />
                      </p>
                    </div>
                  </>
                ),
                expired: (
                  <>
                    <div className="statusbar rejected">
                      <img alt="..." src={pendingicon} />
                      <p>
                        <Translate value="expiredStatus" />
                      </p>
                    </div>
                  </>
                ),
                rejected: (
                  <>
                    <div className="statusbar outer">
                      <div className="statusbar rejected d-flex">
                        <img alt="..." src={rejected} />
                        <p>
                          <Translate value="rejectedStatus" />
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClickReasonOpen()}
                          >
                            {" "}
                            Click here to view rejected reasons
                          </span>
                          {get_user_appeal === undefined ||
                          (users.getUserRegistrationDetails !== undefined &&
                            users.getUserRegistrationDetails !== null &&
                            users.getUserRegistrationDetails
                              .personal_details !== undefined &&
                            users.getUserRegistrationDetails
                              .personal_details[0] !== undefined &&
                            users.getUserRegistrationDetails.personal_details[0]
                              .is_appealed &&
                            users.getUserRegistrationDetails.personal_details[0]
                              .is_rejected) ? null : (
                            <>
                              {/* <Link
                                to="#"
                                onClick={handleClickOpen}
                                className="ml-3 appealLink"
                              >
                                <Translate value="clickAppeal" />
                              </Link> */}
                            </>
                          )}
                        </p>
                      </div>
                      {/* <div className="statusbar reason">
                        <p className="mb-2 reason-title">
                          <Translate value="reason" />
                        </p>
                        <p
                          className="pl-0"
                          style={{
                            fontFamily: 'inherit',
                            color: 'red',
                            fontWeight: 'thicker',
                          }}
                        >
                          {' '}
                          {users.getUserRegistrationDetails !== undefined &&
                            users.getUserRegistrationDetails !== null &&
                            users.getUserRegistrationDetails
                              .personal_details !== undefined &&
                            users.getUserRegistrationDetails
                              .personal_details[0] !== undefined &&
                            users.getUserRegistrationDetails.personal_details[0]
                              .approval_reject_description}
                        </p>
                      </div> */}
                    </div>
                    <div className="d-flex justify-content-start">
                      {/* <Button
                        className="w-100 text-start"
                        variant="text"
                        style={{ cursor: 'pointer' }}
                        // onClick={() =>
                        //   handleClickOpen(
                        //     row.scheme_avail_id,
                        //     row.scheme_name,
                        //     row.status,
                        //   )
                        // }
                      >
                        Click here to view rejected reasons
                      </Button> */}
                      {
                        <RejectedReasonsDialog
                          open={reasonOpen}
                          handleClose={handleReasonClose}
                          reasons={
                            users?.getUserRegistrationDetails?.rejection_reasons
                          }
                          oldReasons={
                            users?.getUserRegistrationDetails
                              ?.certificate_details[0]?.remarks
                          }
                          serviceName={"Registration Rejected Reasons"}
                          // loading={reasonLoading}
                          value={langValue}
                          handleChange={handleReasonChange}
                        />
                      }
                    </div>
                  </>
                ),
                appealed: (
                  <>
                    <div className="statusbar pending">
                      <img alt="..." src={pendingicon} />
                      <p>
                        <Translate value="appealStatus" />
                      </p>
                    </div>
                  </>
                ),
              }[status]
            ) : (
              <></>
            )}
          </Col>

          {/* <Col xs={12} sm={12} md={12} className="dashboarduser-col-noti">
                        <p> - No New Notifications - </p>
                    </Col> */}
        </Row>

        <Row className="dashboarduser-row">
          <>
            <Col xs={3} sm={1} md={3} className="dashboarduser-col mt-3">
              {/* <Link to="/profile"> */}
              <Link to="#" onClick={RegistrationClicked}
              style = {{
                pointerEvents:  users?.user && users.user?.continuation_of_pension_eligible === 1 ? "none" : "default", 
                opacity: users?.user && users.user?.continuation_of_pension_eligible === 1 ? 0.6 : 1
            }}
              >
                <div className="icondiv">
                  <img alt="..." src={Registration} />
                  <p>
                    <Translate value="dashRegistration" />
                  </p>
                </div>
              </Link>
            </Col>
            <Col xs={3} sm={1} md={3} className="dashboarduser-col mt-3">
              {/* <Link to="/renewals"> */}
              <Link to="#" onClick={RenewalClicked} style = {{
                  pointerEvents:  users?.user && users.user?.continuation_of_pension_eligible === 1 ? "none" : "default", 
                  opacity: users?.user && users.user?.continuation_of_pension_eligible === 1 ? 0.6 : 1
              }}>
                <div className="icondiv">
                  <img alt="..." src={Renewal} />
                  <p>
                    <Translate value="dashRenewal" />
                  </p>
                </div>
              </Link>
            </Col>
            <Col xs={3} sm={1} md={3} className="dashboarduser-col mt-3">
              <Link to="#" onClick={SchemesClicked}>
                <div className="icondiv">
                  <img alt="..." src={Schemes} />
                  <p>
                    <Translate value="dashSchemes" />
                  </p>
                </div>
              </Link>
            </Col>
            <Col xs={3} sm={1} md={3} className="dashboarduser-col mt-3">
              <Link
                to={`/scheme-status/${
                  users &&
                  users.getUserRegistrationDetails &&
                  users.getUserRegistrationDetails.personal_details &&
                  users.getUserRegistrationDetails.personal_details[0].id
                }`}
              >
                <div className="icondiv">
                  <img alt="..." src={SchemeStatus} />
                  <p>
                    <Translate value="dashSchemesStatus" />
                  </p>
                </div>
              </Link>
            </Col>
          </>
        </Row>

        <Row className="dashboarduser-row-id">
          <>
            <Col xs={12} md={6} className="dashboarduser-col id-col">
              {/* <Link to="/e-card" className="id-link"> */}
              <Link to="#" onClick={EcardClicked} className="id-link">
                {/* <div className="icondiv"> */}
                <img alt="..." src={ECard} />
                <p>
                  <Translate value="eCard" />
                </p>
                {/* </div> */}
              </Link>
            </Col>
          </>
        </Row>

        <Modal
          open={opennotification}
          onClose={() => setopennotification(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ overflow: "scroll" }}
        >
          <Box className="modal_box">
            <Typography> Notification</Typography>
            <Row>
              {" "}
              {currentPosts.map((x, index) => (
                <li key="index" className="list_noti">
                  {/* <div onClick={() => updateNotification(x._id)}>  {x.message}</div> */}
                  <div className="d-flex flex-column">
                    <div
                      className="d-inline-flex justify-content-end "
                      onClick={() => updateNotification(x._id)}
                    >
                      {" "}
                      <button className="btn btn-danger" color="primary">
                        X
                      </button>
                    </div>
                    <div> {x.message}</div>
                  </div>
                </li>
              ))}
            </Row>
            <nav>
              <ul className="pagination justify-content-center">
                <li className="page-item">
                  <a className="page-link" href="#" onClick={() => prevPage()}>
                    Previous
                  </a>
                </li>
                {pageNumbers.map((num) => (
                  <li className="page-item" key={num}>
                    <a
                      onClick={() => paginate(num)}
                      href="#"
                      className="page-link"
                    >
                      {num}
                    </a>
                  </li>
                ))}
                <li className="page-item">
                  <a className="page-link" href="#" onClick={() => nextPage()}>
                    Next
                  </a>
                </li>
                <button
                  className="btn btn-primary"
                  onClick={() => clearAllNotification()}
                >
                  Clear
                </button>
              </ul>
            </nav>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default Dashboard;
