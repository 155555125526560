import { DialogTitle } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, Slide } from '@mui/material';
import React from 'react';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const StatusModal = ({ children, styles = {} }) => {
  return (
    <Dialog
      sx={{ ...styles }}
      maxWidth={'sm'}
      fullWidth
      open={true}
      TransitionComponent={Transition}
    >
      {children}
    </Dialog>
  );
};
StatusModal.Title = ({ render }) => {
  return <DialogTitle>{render()}</DialogTitle>;
};

StatusModal.Content = ({ render }) => {
  return <DialogContent>{render()}</DialogContent>;
};
StatusModal.Actions = ({ render }) => {
  return <DialogActions>{render()}</DialogActions>;
};

export default StatusModal;
