import React from 'react';
import { FormControl, TextField, InputAdornment } from '@material-ui/core';
import { PhoneAndroid } from '@material-ui/icons';
import { Col, Row } from 'react-bootstrap';
import personal from 'assets/images/f1-01.svg';
import moment from 'moment';

const PersonalDetailsFromSevasindhu = ({ labourData }) => {
  return (
    <>
      <div>
        <Row>
          <Col>
            <Row className="final all-details-subtitle-row">
              <Col xs={12} md={12} className="profile-title">
                <img alt="..." src={personal} />
                <h1>Personal Details as per Labour Card</h1>
              </Col>
            </Row>

            <Row className="px-2">
              <Col md={12} className="final-personal-col-1 px-3 mt-2">
                <p className="mb-0">Name</p>
                <FormControl fullWidth>
                  <TextField
                    name="firstname"
                    value={`${labourData?.applicantname || ''}`}
                    disabled
                  />
                </FormControl>
              </Col>

              <Col md={12} className="final-personal-col-1 px-3 mt-4">
                <p className="mb-0">Mobile Number </p>
                <FormControl fullWidth className="formcontrol2">
                  <TextField
                    disabled
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" className="mb-1 mt-1">
                          <PhoneAndroid className="phone-icon" />
                          +91
                        </InputAdornment>
                      ),
                    }}
                    value={labourData?.mobileno || ''}
                  />
                </FormControl>
              </Col>
            </Row>

            <Row className="px-2 mt-3">
              <Col md={12} className="final-personal-col-1 px-3 mt-1">
                <p className="mb-0">Date Of Birth</p>
                <FormControl fullWidth>
                  <TextField
                    name="dob"
                    value={`${
                      moment(labourData?.dob).format('DD-MM-YYYY') || ''
                    }`}
                    disabled
                  />
                </FormControl>
              </Col>
              <Col md={12} className="final-personal-col-1 px-3 mt-4">
                <p className="mb-0">Age</p>
                <FormControl fullWidth>
                  <TextField
                    name="age"
                    value={
                      labourData?.dob
                        ? `${moment().diff(
                            moment(labourData?.dob),
                            'years',
                          )} years, ${moment().diff(
                            moment(labourData?.dob).add(
                              moment().diff(moment(labourData?.dob), 'years'),
                              'years',
                            ),
                            'months',
                          )} months, ${moment().diff(
                            moment(labourData?.dob)
                              .add(
                                moment().diff(moment(labourData?.dob), 'years'),
                                'years',
                              )
                              .add(
                                moment().diff(
                                  moment(labourData?.dob).add(
                                    moment().diff(
                                      moment(labourData?.dob),
                                      'years',
                                    ),
                                    'years',
                                  ),
                                  'months',
                                ),
                                'months',
                              ),
                            'days',
                          )} days`
                        : ''
                    }
                    disabled
                  />
                </FormControl>
              </Col>
            </Row>
            <Row className="px-2 mt-3">
              <Col md={12} className="final-personal-col-1 px-3 mt-1">
                <p className="mb-0">Gender</p>
                <FormControl fullWidth>
                  <TextField
                    name="dob"
                    value={`${
                      labourData?.gender == 1
                        ? 'MALE'
                        : labourData?.gender == 2
                          ? 'FEMALE'
                          : ''
                    }`}
                    disabled
                  />
                </FormControl>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PersonalDetailsFromSevasindhu;
