// import React, { useEffect, useRef, useState } from "react";
// import { Button, Col, Row } from "react-bootstrap";
// import logo from "assets/images/Karnataka_logo_100_Pixels.png";
// import { Translate } from "react-redux-i18n";
// import Box from "@mui/material/Box";
// import TextField from "@mui/material/TextField";
// import {
//   CircularProgress,
//   FormControl,
//   IconButton,
//   InputAdornment,
//   ListItemText,
//   MenuItem,
//   Paper,
//   Select,
//   TextareaAutosize,
// } from "@material-ui/core";


// // import Select from '@mui/material/Select';

// // import MenuItem from '@mui/material/MenuItem';
// // import FormControl from '@mui/material/FormControl';
// import CloudUploadIcon from "@mui/icons-material/CloudUpload";

// import closeIcon from "assets/images/close.png";
// import {
//   KeyboardDatePicker,
//   MuiPickersUtilsProvider,
// } from "@material-ui/pickers";
// import MomentUtils from "@date-io/moment";
// import CancelIcon from "@mui/icons-material/Cancel";
// import FolderIcon from "@mui/icons-material/Folder";

// import axios from "axios";
// import { SERVER } from "store/actions/user.actions";
// import { getTokenCookie, Required, showToast } from "utils/tools";
// import moment from "moment";
// import { Typography } from "@mui/material";
// import { Link } from "react-router-dom/cjs/react-router-dom.min";
// import uploadFileAPI from "utils/fileupload";
// import { useSelector } from "react-redux";
// import { backlogFuneralAccidentDeathFileUpload } from "./FIleUpload";

// import successIcon from "assets/images/check (3).svg";
// import SimpleBackdrop from "components/loadingScreen/Backdrop";
// import backIcon from "assets/images/Icon awesome-arrow-circle-left.svg";
// import {
//   CheckUserAPI,
//   InsertPensionAPI,
//   UpdatePensionAPI,
//   CheckUserByMobileNoAPI,
// } from "services/CheckUserPension.service";
// import {
//   cityData,
//   districtData,
//   talukData,
//   villageData,
// } from "components/SevaSindhu/LabourDetails/AddressLogic";
// import { getSubmissionLocationAPI } from "components/SevaSindhu/SevaSindhuServices/SevaSindhu.service";
// // import { ContinuationPensionFileUpload } from "./FIleUpload";
// const BacklogFormAccidentDeath = () => {
//   const users = useSelector((state) => state.users);
//   const deptUserID = users?.getDeptUserRole?.data[0]?.department_user_id;
//   const [mobileNumber, setMobileNumber] = useState(null);
//   const [benificiaryName, setBeneficiaryName] = useState(null);
//   const [registrationNumber, setRegistrationNumber] = useState(null);
//   const [referenceNumber, setReferenceNumber] = useState(null);
//   const [permanentAddress, setPermanentAddress] = useState(null);

//   const [stateID, setStateID] = useState(null);
//   const [districtID, setDistrictID] = useState(null);
//   const [talukID, setTalukID] = useState(null);
//   const [cityID, setCityID] = useState(null);
//   const [villageID, setVillageID] = useState(null);
//   const [circleID, setCircleID] = useState(null);

//   const [stateName, setStateName] = useState(null);
//   const [districtName, setDistrictName] = useState(null);
//   const [talukName, setTalukName] = useState(null);
//   const [cityName, setCityName] = useState(null);
//   const [villageName, setVillageName] = useState(null);
//   const [circleName, setCircleName] = useState(null);

//   const [district, setDistrict] = useState(null);
//   const [tauluk, setTaluk] = useState(null);
//   const [city, setCity] = useState(null);
//   const [village, setVillage] = useState(null);
//   const [circle, setCircle] = useState(null);

//   const [dob, setDob] = useState(null);
//   const [pensionAvailDate, setPensionAvaliDate] = useState(null);
//   const [pensionRecievedDate, setPensionRecievedDate] = useState(null);

//   const [registrationDate, setRegistrationDate] = useState(null);
//   const [validityFromDate, setValidityFromDate] = useState(null);
//   const [validityToDate, setValidityToDate] = useState(null);

//   const [isVerified, setIsVerified] = useState(false);
//   const [isMobileVerified, setIsMobileVerified] = useState(false);
//   const [isUserVerified, setIsUserVerified] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [numberLoading, setNumberLoading] = useState(false);

//   const [submitLoader, setSubmitLoader] = useState(false);

//   const labourCardFileRef = useRef(null);
//   const aadhaarCardFileRef = useRef(null);
//   const labourPhotoFileRef = useRef(null);
//   const pensionOrderRef = useRef(null);

//   const [labourCardDoc, setLabourCardDoc] = useState([]);
//   const [aadhaarCardDoc, setAadhaarCardDoc] = useState([]);
//   const [labourPhotoDoc, setLabourPhotoDoc] = useState([]);
//   const [pensionOrderDoc, setPensionOrderDoc] = useState([]);

//   const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

//   const [isTransferred, setIsTransferred] = useState(false);
//   const [isDataFound, setIsDataFound] = useState(false);
//   const [circleData, setCircleData] = useState(null);

//   const [sevaSindhuID, setSevaSindhuID] = useState(null);

//   const [gender, setGender] = useState(null);

//   // const [dataUri, setDataUri] = useState('');

//   // Address Drop Down API's CALL

//   useEffect(() => {
//     if (deptUserID && !isDataFound) {
//       axios
//         .get(`${SERVER}/admin/alc-district?departmentUserID=${deptUserID}`, {
//           headers: {
//             Authorization: `Bearer ${getTokenCookie()}`,
//           },
//         })
//         .then((res) => {
//           setDistrict(res.data.data);
//         })
//         .catch((err) => {
//           console.log(err);
//         });
//     }
//   }, [deptUserID, isDataFound]);

//   useEffect(() => {
//     if (districtID) {
//       axios
//         .get(`${SERVER}/global/get_taluk_details?district_id=${districtID}`)
//         .then((res) => {
//           setTaluk(res.data.data);
//         })
//         .catch((err) => {
//           console.log(err);
//         });
//     }
//   }, [districtID]);

//   useEffect(() => {
//     if (talukID) {
//       axios
//         .get(`${SERVER}/global/get_city_details?taluk_id=${talukID}`)
//         .then((res) => {
//           setCity(res.data.data);
//         })
//         .catch((err) => {
//           console.log(err);
//         });
//     }
//   }, [talukID]);

//   useEffect(() => {
//     if (cityID) {
//       axios
//         .get(`${SERVER}/global/get_village_details?city_id=${cityID}`)
//         .then((res) => {
//           setVillage(res.data.data);
//         })
//         .catch((err) => {
//           console.log(err);
//         });
//     }
//   }, [cityID]);

//   useEffect(() => {
//     if (districtID && talukID && cityID) {
//       const circlePayload = {
//         district_id: districtID,
//         taluk_id: null,
//         gram_panchayat_id: null,
//         village_area_ward: null,
//       };
//       axios
//         .post(`${SERVER}/config/circle/get_circles_on_area`, circlePayload, {
//           headers: {
//             Authorization: `Bearer ${getTokenCookie()}`,
//           },
//         })
//         .then((res) => {
//           // setCircleID(res.data.data);

//           const filteredArr = res.data.data.reduce((acc, current) => {
//             const x = acc.find((item) => item.circle_id === current.circle_id);
//             if (!x) {
//               return acc.concat([current]);
//             } else {
//               return acc;
//             }
//           }, []);

//           setCircle(filteredArr);
//         });
//     }
//   }, [districtID, talukID, cityID]);

//   const verifySevaSindhu = async () => {
//     if (registrationNumber && referenceNumber) {
//       setLoading(true);
//       const payload = {
//         regNo: registrationNumber,
//         refNo: referenceNumber,
//       };
//       try {
//         const { data } = await CheckUserAPI(payload);

//         setLoading(false);
//         setIsUserVerified(true);
//         if (data?.length > 0) {
//           // if (data[0]?.department_user_id !== deptUserID) {
//           //   showToast(
//           //     "ERROR",
//           //     "Application cannot be submitted under this jurisdiction"
//           //   );
//           //   setIsUserVerified(false);
//           //   setIsVerified(false);
//           //   return;
//           // }
//           if (data[0]?.is_verified_continuation_of_pension) {
//             showToast("ERROR", "Application has already been submited.");
//             setIsUserVerified(false);
//             setIsVerified(false);
//             return;
//           }
//           if (data[0]?.is_transferred === true) {
//             setIsVerified(true);
//             setIsTransferred(data[0]?.is_transferred);
//             setMobileNumber(data[0]?.mobileno);
//             setDob(data[0]?.DOB);
//             setStateName(data[0]?.state_name);
//             setDistrictName(data[0]?.district_name);
//             setTalukName(data[0]?.taluk_name);
//             setCityName(data[0]?.city_town_gram_panchayat_name);
//             setVillageName(data[0]?.village_area_name);

//             setPermanentAddress(data[0]?.permanent_address);
//           }
//           setIsDataFound(true);
//           setCircleName(data[0]?.circle_name);
//           setBeneficiaryName(data[0]?.applicantname);
//           callGetLocationDetails(data[0]?.submissionlocation);
//           setRegistrationDate(data[0]?.registrationdate);
//           setValidityFromDate(data[0]?.validity_fromdate);
//           setValidityToDate(data[0]?.validity_todate);
//           setSevaSindhuID(data[0]?.id);
//           setGender(data[0]?.gender);
//           setCircleID(data[0]?.circle_id);
//         }
//       } catch (error) {
//         setLoading(false);
//         if (error?.response?.data?.errors?.msg) {
//           showToast("ERROR", `${error?.response?.data?.errors?.msg}`);
//         }
//         showToast("ERROR", "Something went wrong");
//       }
//     } else {
//       showToast("ERROR", "Please enter registration and reference number");
//     }
//   };

//   const verifyMobileNumber = async () => {
//     if (mobileNumber) {
//       setNumberLoading(true);

//       const pensionDetails = await CheckUserByMobileNoAPI({
//         mobile: mobileNumber,
//       });

//       if (pensionDetails?.data && pensionDetails?.data?.length > 0) {
//         setNumberLoading(false);
//         setIsMobileVerified(false);
//         setIsVerified(false);
//         showToast("ERROR", "The Mobile Number is Already Manually Registered");
//         return;
//       }

//       axios
//         .get(`${SERVER}/user/${mobileNumber}`)
//         .then((res) => {
//           setNumberLoading(false);
//           setIsMobileVerified(false);
//           setIsVerified(false);
//           showToast("ERROR", "Mobile Number Already Registered, Please Login!");
//         })
//         .catch((err) => {
//           if (err.response.data.error) {
//             setNumberLoading(false);
//             setIsMobileVerified(true);
//             setIsVerified(true);
//           }
//         });
//     } else {
//       showToast("ERROR", "Please enter mobile number");
//     }
//   };

//   const handleReset = () => {
//     setMobileNumber("");
//     setBeneficiaryName("");
//     setRegistrationNumber("");
//     setReferenceNumber("");
//     setDob(null);
//     setPermanentAddress("");
//     setPensionAvaliDate(null);
//     setPensionRecievedDate(null);

//     setAadhaarCardDoc([]);
//     setLabourCardDoc([]);
//     setLabourPhotoDoc([]);
//     setPensionOrderDoc([]);
//     setIsVerified(false);
//     setIsMobileVerified(false);
//     setIsUserVerified(false);
//     setIsSubmitSuccess(false);
//   };
//   /* FILE UPLOAD CHANGE */
//   const handleFileChange = (ev) => {
//     if (ev.target.name === "aadhaarCard") {
//       if (
//         ev.target.files[0].type === "image/png" ||
//         ev.target.files[0].type === "image/jpeg" ||
//         ev.target.files[0].type === "image/jpg" ||
//         ev.target.files[0].type === "application/pdf"
//       ) {
//         if (ev.target.files[0].size < 2000001) {
//           const newFiles = [...aadhaarCardDoc, ...ev.target.files];
//           setAadhaarCardDoc(newFiles);
//         } else {
//           showToast("ERROR", "Please upload file size less than 2MB.");
//         }
//       } else {
//         showToast("ERROR", "Please upload file in PNG/JPEG or PDF");
//       }
//     } else if (ev.target.name === "labourPhoto") {
//       if (
//         ev.target.files[0].type === "image/png" ||
//         ev.target.files[0].type === "image/jpeg" ||
//         ev.target.files[0].type === "image/jpg" ||
//         ev.target.files[0].type === "application/pdf"
//       ) {
//         if (ev.target.files[0].size < 2000001) {
//           const newFiles = [...labourPhotoDoc, ...ev.target.files];
//           setLabourPhotoDoc(newFiles);
//         } else {
//           showToast("ERROR", "Please upload file size less than 2MB.");
//         }
//       } else {
//         showToast("ERROR", "Please upload file in PNG/JPEG or PDF");
//       }
//     } else if (ev.target.name === "labourCard") {
//       if (
//         ev.target.files[0].type === "image/png" ||
//         ev.target.files[0].type === "image/jpeg" ||
//         ev.target.files[0].type === "image/jpg" ||
//         ev.target.files[0].type === "application/pdf"
//       ) {
//         if (ev.target.files[0].size < 2000001) {
//           const newFiles = [...labourCardDoc, ...ev.target.files];
//           setLabourCardDoc(newFiles);
//         } else {
//           showToast("ERROR", "Please upload file size less than 2MB.");
//         }
//       } else {
//         showToast("ERROR", "Please upload file in PNG/JPEG or PDF");
//       }
//     } else if (ev.target.name === "pensionOrder") {
//       if (
//         ev.target.files[0].type === "image/png" ||
//         ev.target.files[0].type === "image/jpeg" ||
//         ev.target.files[0].type === "image/jpg" ||
//         ev.target.files[0].type === "application/pdf"
//       ) {
//         if (ev.target.files[0].size < 2000001) {
//           const newFiles = [...pensionOrderDoc, ...ev.target.files];
//           setPensionOrderDoc(newFiles);
//         } else {
//           showToast("ERROR", "Please upload file size less than 2MB.");
//         }
//       } else {
//         showToast("ERROR", "Please upload file in PNG/JPEG or PDF");
//       }
//     }
//   };

//   const handleRemoveFile = (index, documentType) => {
//     // if (documentType === "labourCard") {
//     //   const newFiles = [...labourCardDoc];
//     //   newFiles.splice(index, 1);
//     //   setLabourCardDoc(newFiles);
//     //   labourCardFileRef.current.value = "";
//     // } else if (documentType === "aadhaarCard") {
//     //   const newFiles = [...aadhaarCardDoc];
//     //   newFiles.splice(index, 1);
//     //   setAadhaarCardDoc(newFiles);
//     //   aadhaarCardFileRef.current.value = "";
//     // } else if (documentType === "labourPhoto") {
//     //   const newFiles = [...labourPhotoDoc];
//     //   newFiles.splice(index, 1);
//     //   setLabourPhotoDoc(newFiles);
//     //   labourPhotoFileRef.current.value = "";
//     // } else if (documentType === "pensionOrder") {
//     //   const newFiles = [...pensionOrderDoc];
//     //   newFiles.splice(index, 1);
//     //   setPensionOrderDoc(newFiles);
//     //   pensionOrderRef.current.value = "";
//     // }
//   };

//   const insertPensionScheme = async (
//     labourCardDoc,
//     labourPhotoDoc,

//     pensionOrderDoc
//   ) => {
//     const payload = {
//       boardID: 1,
//       departmentUserID: deptUserID,
//       sevasindhuID: +sevaSindhuID,
//       mobile: +mobileNumber,
//       regNo: registrationNumber,
//       refNo: referenceNumber,
//       registrationDate: moment(registrationDate).format("YYYY-MM-DD"),
//       validityFromDate: moment(validityFromDate).format("YYYY-MM-DD"),
//       validityToDate: moment(validityToDate).format("YYYY-MM-DD"),
//       applicantName: benificiaryName,
//       dob: moment(dob).format("YYYY-MM-DD"),
//       gender: 0,
//       state: +stateID,
//       district: +districtID,
//       taluk: +talukID,
//       gramPanchayathCityTown: +cityID,
//       villageAreaWard: +villageID,
//       circle: +circleID,
//       permanentAddress: permanentAddress,
//       availedFromDate: moment(pensionAvailDate).format("YYYY-MM-DD"),
//       availedLastDate: moment(pensionRecievedDate).format("YYYY-MM-DD"),
//       labourCard: labourCardDoc,
//       labourPhoto: labourPhotoDoc,
//       aadharCard: [],
//       pensionOrder: pensionOrderDoc,
//     };

//     try {
//       if (isDataFound) {
//         const response = await UpdatePensionAPI(payload);
//         if (response.success) {
//           setSubmitLoader(false);
//           setIsSubmitSuccess(true);
//           showToast(
//             "SUCCESS",
//             "Application Submitted successfully.For further process,kindly generate the E-card in the citizen login to allow for continuation of pension"
//           );
//         }
//       } else {
//         const response = await InsertPensionAPI(payload);
//         if (response.success) {
//           setSubmitLoader(false);
//           setIsSubmitSuccess(true);
//           showToast(
//             "SUCCESS",
//             "Application submitted successfully.For further process,kindly generate the E-card in the citizen login to allow for continuation of pension"
//           );
//         }
//       }
//     } catch (err) {
//       setSubmitLoader(false);
//       if (err?.response?.data?.errors?.msg) {
//         showToast("ERROR", `${err?.response?.data?.errors?.msg}`);
//       } else {
//         showToast("ERROR", "Something went wrong");
//       }
//     }
//   };

//   const handleSubmitData = async (ev) => {
//     if (
//       mobileNumber &&
//       benificiaryName &&
//       dob &&
//       permanentAddress &&
//       pensionAvailDate &&
//       pensionRecievedDate &&
//       registrationDate &&
//       validityFromDate &&
//       validityToDate &&
//       (+stateID !== 0 || stateName) &&
//       (+districtID !== 0 || districtName) &&
//       (+talukID !== 0 || talukName) &&
//       (+cityID !== 0 || cityName) &&
//       (+villageID !== 0 || villageName) &&
//       (+circleID !== 0 || circleName)
//     ) {
//       if (
//         labourCardDoc &&
//         labourCardDoc.length > 0 &&
//         labourPhotoDoc &&
//         labourPhotoDoc.length > 0 &&
//         pensionOrderDoc &&
//         pensionOrderDoc.length > 0
//       ) {
//         setSubmitLoader(true);

//         const response = await backlogFuneralAccidentDeathFileUpload(
//           labourCardDoc,
//           labourPhotoDoc,

//           pensionOrderDoc
//         );

//         if (response.success) {
//           insertPensionScheme(
//             response.labourCardDoc,
//             response.labourPhotoDoc,

//             response.pensionOrderDoc
//           );
//         }
//       } else {
//         showToast("ERROR", "Please Upload documents");
//       }
//     } else {
//       showToast("ERROR", "Please fill all the mandatory details");
//     }
//   };

//   const callGetLocationDetails = async (submissionLocation) => {
//     try {
//       const payLoad = {
//         ref_seva_sindhu_circle_id: +submissionLocation,
//       };
//       const response = await getSubmissionLocationAPI(payLoad);
//       if (response.success) {
//         setCircleData(response.data);
//       }
//     } catch (error) {
//       showToast("ERROR", "Something went wrong");
//     }
//   };

//   // useEffect(() => {
//   //   if ((isTransferred === null || isTransferred === false) && circleData) {
//   //     console.log('called');
//   //     const _districtData = districtData(circleData && circleData);

//   //     setDistrict(_districtData);
//   //     const _talukData = talukData(circleData && circleData);
//   //     setTaluk(_talukData);
//   //     const _villageData = villageData(circleData && circleData);
//   //     setVillage(_villageData);

//   //     const _cityData = cityData(circleData && circleData);

//   //     if (talukID) {
//   //       const result = _cityData.filter((_data) => _data.taluk_id === talukID);
//   //       setCity(result);
//   //     }
//   //     if (cityID) {
//   //       const result = _villageData.filter(
//   //         (_data) => _data.gram_panchayat_city_town_id === cityID,
//   //       );

//   //       setVillage(result);
//   //     }
//   //   }
//   // }, [isTransferred, circleData, talukID, cityID]);

//   return (
//     <>
//       {submitLoader && <SimpleBackdrop />}

//       <div>
//         <div className='root'>
//           <Row className='top-div '>
//             <Row className='topbar-row'>
//               <Col xs={12} md={4} lg={6} className='top-bar-col'>
//                 <div className='logo-div-profile'>
//                   <a href='/admin/dashboard/12'>
//                     <img id='logo' src={logo} alt='...' className='logo-img' />
//                   </a>
//                   <h1 className='logo-text'>
//                     {/* Karnataka Building & Other Construction<br />Workers Welfare Board (KBOCWWB) */}
//                     <Translate value='header.title' /> <br />
//                     <Translate value='header.sub' />
//                   </h1>
//                 </div>
//               </Col>
//             </Row>
//           </Row>
//         </div>
//         <div className='d-flex justify-content-start align-items-start ps-3 mt-3'>
//           <Link to={"/admin/dashboard/12"}>
//             <img src={backIcon} alt='' />
//           </Link>
//         </div>
//         <div className='d-flex justify-content-start align-items-center ms-5 gap-5'>
//           {!isSubmitSuccess && (
//             <div className='d-flex justify-content-start align-items-center gap-5'>
//               <Col md={6}>
//                 <TextField
//                   className='w-100'
//                   id='standard-basic'
//                   label='Registration number'
//                   variant='standard'
//                   value={registrationNumber}
//                   disabled={isUserVerified}
//                   // inputProps={{ minLength: 10, maxLength: 10 }}
//                   onChange={(ev) => setRegistrationNumber(ev.target.value)}
//                 />
//               </Col>
//               <Col md={6}>
//                 <TextField
//                   className='w-100'
//                   id='standard-basic'
//                   label='Reference number'
//                   variant='standard'
//                   value={referenceNumber}
//                   disabled={isUserVerified}
//                   // inputProps={{ minLength: 10, maxLength: 10 }}
//                   onChange={(ev) => setReferenceNumber(ev.target.value)}
//                 />
//               </Col>
//               <Col className='mt-3 d-flex'>
//                 {loading ? (
//                   <Box sx={{ display: "flex" }}>
//                     <CircularProgress />
//                   </Box>
//                 ) : (
//                   <Button
//                     disabled={
//                       !registrationNumber || !referenceNumber || isUserVerified
//                     }
//                     onClick={verifySevaSindhu}
//                     variant={isUserVerified ? "success" : "primary"}
//                     className='mx-3'
//                   >
//                     {isUserVerified ? "Verified" : "Verify"}
//                   </Button>
//                 )}

//                 <Button
//                   disabled={!registrationNumber || !referenceNumber}
//                   onClick={handleReset}
//                   variant='primary'
//                 >
//                   {"Reset"}
//                 </Button>
//               </Col>
//             </div>
//           )}
//         </div>

//         {isUserVerified && (
//           <div className='d-flex justify-content-start align-items-center ms-5 gap-5'>
//             {!isSubmitSuccess && (
//               <div className='d-flex justify-content-start align-items-center gap-5 w-100'>
//                 <Col md={3}>
//                   <TextField
//                     className='w-100'
//                     id='standard-basic'
//                     label='Mobile number'
//                     variant='standard'
//                     value={mobileNumber}
//                     disabled={isMobileVerified || isTransferred}
//                     inputProps={{ minLength: 10, maxLength: 10 }}
//                     onChange={(ev) =>
//                       setMobileNumber(ev.target.value.replace(/[^0-9]/g, ""))
//                     }
//                   />
//                 </Col>
//                 {!isTransferred && (
//                   <Col md={3} className='mt-3 d-flex'>
//                     {numberLoading ? (
//                       <Box sx={{ display: "flex" }}>
//                         <CircularProgress />
//                       </Box>
//                     ) : (
//                       <Button
//                         disabled={
//                           (mobileNumber && mobileNumber.length < 10) ||
//                           isVerified
//                         }
//                         onClick={verifyMobileNumber}
//                         variant={isVerified ? "success" : "primary"}
//                         className=''
//                       >
//                         {isVerified ? "Verified" : "Verify"}
//                       </Button>
//                     )}

//                     {/* <Button
//                       disabled={!mobileNumber}
//                       onClick={handleReset}
//                       variant="primary"
//                     >
//                       {'Reset'}
//                     </Button> */}
//                   </Col>
//                 )}
//               </div>
//             )}
//           </div>
//         )}

//         <div className='d-flex justify-content-center align-items-center px-3 py-2'>
//           <div>
//             {isSubmitSuccess ? (
//               <>
//                 <div className='d-flex justify-content-center align-items-center flex-column'>
//                   <img width={80} height={80} src={successIcon} alt='...' />
//                   <h2 className='my-3'>Successfully Submitted</h2>
//                   <Button variant='primary' color='info' onClick={handleReset}>
//                     Click here to submit another application
//                   </Button>
//                 </div>
//               </>
//             ) : null}

//             <Row className='px-5 py-2'>
//               {/* <Col xs={12} md={4} className="mt-3"></Col> */}

//               <Row className='mt-3'>
//                 {!isSubmitSuccess && isVerified ? (
//                   <>
//                     <Col xs={12} md={4}>
//                       <Required
//                         title={"Name of the Beneficiary as per aadhar"}
//                       />
//                       <TextField
//                         className='w-100'
//                         id='standard-basic'
//                         // label="Name of the Beneficiary"
//                         variant='standard'
//                         disabled={isDataFound}
//                         value={benificiaryName}
//                         onChange={(ev) => setBeneficiaryName(ev.target.value)}
//                       />
//                     </Col>

//                     <Col xs={12} md={4}>
//                       <Required title={"Date of Birth as per aadhar"} />
//                       <MuiPickersUtilsProvider utils={MomentUtils}>
//                         <KeyboardDatePicker
//                           className='w-100 '
//                           // className="datepicker"
//                           // margin="normal"

//                           format='DD/MM/YYYY'
//                           maxDate={
//                             !isTransferred &&
//                             moment(Date()).subtract(61, "year")
//                           }
//                           value={dob}
//                           onChange={(date) => {
//                             setDob(date);
//                           }}
//                           placeholder='DOB'
//                           disabled={isTransferred}
//                         />
//                       </MuiPickersUtilsProvider>
//                     </Col>
//                     <Col xs={12} md={4}>
//                       <Required title={"Registration date"} />
//                       <MuiPickersUtilsProvider utils={MomentUtils}>
//                         <KeyboardDatePicker
//                           className='w-100'
//                           // className="datepicker"
//                           // margin="normal"
//                           format='DD/MM/YYYY'
//                           value={registrationDate}
//                           disabled={isDataFound}
//                           maxDate={new Date()}
//                           onChange={(date) => {
//                             setRegistrationDate(date);
//                           }}
//                           placeholder='Registration Date'
//                         />
//                       </MuiPickersUtilsProvider>
//                     </Col>
//                     <Col xs={12} md={4}>
//                       <Required title={"Validity from date"} />
//                       <MuiPickersUtilsProvider utils={MomentUtils}>
//                         <KeyboardDatePicker
//                           className='w-100'
//                           // className="datepicker"
//                           // margin="normal"
//                           minDate={moment(registrationDate)}
//                           format='DD/MM/YYYY'
//                           value={validityFromDate}
//                           disabled={isDataFound}
//                           maxDate={!isDataFound && new Date()}
//                           onChange={(date) => {
//                             setValidityFromDate(date);
//                           }}
//                           placeholder='Validity From Date'
//                         />
//                       </MuiPickersUtilsProvider>
//                     </Col>
//                     <Col xs={12} md={4}>
//                       <Required title={"Validity to date"} />
//                       <MuiPickersUtilsProvider utils={MomentUtils}>
//                         <KeyboardDatePicker
//                           className='w-100'
//                           // className="datepicker"
//                           // margin="normal"
//                           format='DD/MM/YYYY'
//                           minDate={moment(validityFromDate)}
//                           value={validityToDate}
//                           disabled={isDataFound}
//                           maxDate={
//                             !isDataFound &&
//                             moment(validityFromDate).add(3, "years")
//                           }
//                           onChange={(date) => {
//                             setValidityToDate(date);
//                           }}
//                           placeholder='Validity To Date'
//                         />
//                       </MuiPickersUtilsProvider>
//                     </Col>
//                     <Col xs={12} md={4}>
//                       <Required title={"Permanent address"} />

//                       <TextareaAutosize
//                         id='standard-basic'
//                         className='w-100'
//                         variant='standard'
//                         value={permanentAddress}
//                         disabled={isTransferred}
//                         onChange={(ev) => setPermanentAddress(ev.target.value)}
//                       />
//                     </Col>
//                     <Col xs={12} md={4}>
//                       <Required title={"Pension availing from date"} />
//                       <MuiPickersUtilsProvider utils={MomentUtils}>
//                         <KeyboardDatePicker
//                           className='w-100'
//                           // className="datepicker"
//                           // margin="normal"

//                           format='DD/MM/YYYY'
//                           value={pensionAvailDate}
//                           minDate={moment(registrationDate)}
//                           maxDate={new Date()}
//                           onChange={(date) => {
//                             setPensionAvaliDate(date);
//                           }}
//                           placeholder='Pension Availing From Date'
//                         />
//                       </MuiPickersUtilsProvider>
//                     </Col>
//                     <Col xs={12} md={4}>
//                       <Required title={"Last pension received date"} />
//                       <MuiPickersUtilsProvider utils={MomentUtils}>
//                         <KeyboardDatePicker
//                           className='w-100'
//                           // className="datepicker"
//                           // margin="normal"
//                           minDate={moment(pensionAvailDate)}
//                           format='DD/MM/YYYY'
//                           value={pensionRecievedDate}
//                           maxDate={new Date()}
//                           onChange={(date) => {
//                             setPensionRecievedDate(date);
//                           }}
//                           placeholder='Last Pension Received Date'
//                         />
//                       </MuiPickersUtilsProvider>
//                     </Col>
//                     <Col xs={12} md={4}>
//                       <Required title={"State"} />
//                       {isTransferred && (
//                         <TextField
//                           id='standard-basic'
//                           className='w-100'
//                           variant='standard'
//                           value={stateName}
//                           disabled
//                         // onChange={(ev) => setPermanentAddress(ev.target.value)}
//                         />
//                       )}
//                       {!isTransferred && (
//                         <FormControl className='' variant='standard' fullWidth>
//                           <Select
//                             className=''
//                             labelId='demo-simple-select-required-label'
//                             id='demo-simple-select-required'
//                             value={stateID || "0"}
//                             // name="state"
//                             displayEmpty
//                             disabled={isTransferred}
//                             onChange={(ev) => setStateID(ev.target.value)}
//                           >
//                             <MenuItem value='0'>
//                               <ListItemText primary='Select State' />
//                             </MenuItem>

//                             <MenuItem value={12}>
//                               <ListItemText primary={"Karnataka"} />
//                             </MenuItem>
//                           </Select>
//                         </FormControl>
//                       )}
//                     </Col>
//                     <Col xs={12} md={4}>
//                       <Required title={"District"} />
//                       {isTransferred && (
//                         <TextField
//                           id='standard-basic'
//                           className='w-100'
//                           variant='standard'
//                           value={districtName}
//                           disabled
//                         // onChange={(ev) => setPermanentAddress(ev.target.value)}
//                         />
//                       )}
//                       {!isTransferred && (
//                         <FormControl className='' variant='standard' fullWidth>
//                           <Select
//                             className=''
//                             labelId='demo-simple-select-required-label'
//                             id='demo-simple-select-required'
//                             value={districtID || "0"}
//                             // name="state"
//                             displayEmpty
//                             disabled={isTransferred}
//                             onChange={(ev) => setDistrictID(ev.target.value)}
//                           >
//                             <MenuItem value='0'>
//                               <ListItemText primary='Select District' />
//                             </MenuItem>
//                             {district &&
//                               district.length &&
//                               district.map((data) => (
//                                 <MenuItem value={data.id}>
//                                   <ListItemText primary={data.district} />
//                                 </MenuItem>
//                               ))}
//                           </Select>
//                         </FormControl>
//                       )}
//                     </Col>

//                     <Col xs={12} md={4}>
//                       <Required title={"Taulk"} />
//                       {isTransferred && (
//                         <TextField
//                           id='standard-basic'
//                           className='w-100'
//                           variant='standard'
//                           value={talukName}
//                           disabled
//                         // onChange={(ev) => setPermanentAddress(ev.target.value)}
//                         />
//                       )}
//                       {!isTransferred && (
//                         <FormControl className='' variant='standard' fullWidth>
//                           <Select
//                             className=''
//                             labelId='demo-simple-select-required-label'
//                             id='demo-simple-select-required'
//                             value={talukID || "0"}
//                             // name="state"
//                             displayEmpty
//                             disabled={isTransferred}
//                             onChange={(ev) => setTalukID(ev.target.value)}
//                           >
//                             <MenuItem value='0'>
//                               <ListItemText primary='Select Taluk' />
//                             </MenuItem>
//                             {tauluk &&
//                               tauluk.length &&
//                               tauluk.map((data) => (
//                                 <MenuItem value={data.id || data.taluk_id}>
//                                   <ListItemText
//                                     primary={data.name || data.taluk_name}
//                                   />
//                                 </MenuItem>
//                               ))}
//                           </Select>
//                         </FormControl>
//                       )}
//                     </Col>
//                     <Col xs={12} md={4}>
//                       <Required title={"City"} />
//                       {isTransferred && (
//                         <TextField
//                           id='standard-basic'
//                           className='w-100'
//                           variant='standard'
//                           value={cityName}
//                           disabled
//                         // onChange={(ev) => setPermanentAddress(ev.target.value)}
//                         />
//                       )}
//                       {!isTransferred && (
//                         <FormControl className='' variant='standard' fullWidth>
//                           <Select
//                             className='mt-0'
//                             labelId='demo-simple-select-required-label'
//                             id='demo-simple-select-required'
//                             value={cityID || "0"}
//                             // name="state"
//                             displayEmpty
//                             disabled={isTransferred}
//                             onChange={(ev) => setCityID(ev.target.value)}
//                           >
//                             <MenuItem value='0'>
//                               <ListItemText primary='Select City' />
//                             </MenuItem>
//                             {city &&
//                               city.length &&
//                               city.map((data) => (
//                                 <MenuItem
//                                   value={
//                                     data.id || data.gram_panchayat_city_town_id
//                                   }
//                                 >
//                                   <ListItemText
//                                     primary={
//                                       data.name ||
//                                       data.gram_panchayatcity_town_name
//                                     }
//                                   />
//                                 </MenuItem>
//                               ))}
//                           </Select>
//                         </FormControl>
//                       )}
//                     </Col>

//                     <Col xs={12} md={4}>
//                       <Required title={"Village"} />
//                       {isTransferred && (
//                         <TextField
//                           id='standard-basic'
//                           className='w-100'
//                           variant='standard'
//                           value={villageName}
//                           disabled
//                         // onChange={(ev) => setPermanentAddress(ev.target.value)}
//                         />
//                       )}
//                       {!isTransferred && (
//                         <FormControl className='' variant='standard' fullWidth>
//                           <Select
//                             className='mt-0'
//                             labelId='demo-simple-select-required-label'
//                             id='demo-simple-select-required'
//                             value={villageID || "0"}
//                             // name="state"
//                             displayEmpty
//                             disabled={isTransferred}
//                             onChange={(ev) => setVillageID(ev.target.value)}
//                           >
//                             <MenuItem value='0'>
//                               <ListItemText primary='Select Village' />
//                             </MenuItem>
//                             {village &&
//                               village.length &&
//                               village.map((data) => (
//                                 <MenuItem
//                                   value={data.id || data.village_area_ward_id}
//                                 >
//                                   <ListItemText
//                                     primary={
//                                       data.name || data.village_area_ward
//                                     }
//                                   />
//                                 </MenuItem>
//                               ))}
//                           </Select>
//                         </FormControl>
//                       )}
//                     </Col>

//                     <Col xs={12} md={4}>
//                       <Required title={"Circle"} />
//                       {isDataFound && (
//                         <TextField
//                           id='standard-basic'
//                           className='w-100'
//                           variant='standard'
//                           value={circleName}
//                           disabled
//                         // onChange={(ev) => setPermanentAddress(ev.target.value)}
//                         />
//                       )}
//                       {!isDataFound && (
//                         <FormControl className='' variant='standard' fullWidth>
//                           <Select
//                             className='mt-0'
//                             labelId='demo-simple-select-required-label'
//                             id='demo-simple-select-required'
//                             value={circleID || "0"}
//                             // name="state"
//                             displayEmpty
//                             onChange={(ev) => setCircleID(ev.target.value)}
//                           >
//                             <MenuItem value='0'>
//                               <ListItemText primary='Select Circle' />
//                             </MenuItem>
//                             {circle &&
//                               circle.length &&
//                               circle.map((data) => (
//                                 <MenuItem value={data.circle_id}>
//                                   <ListItemText primary={data.circle_name} />
//                                 </MenuItem>
//                               ))}
//                           </Select>
//                         </FormControl>
//                       )}
//                     </Col>

//                     <div className=''>
//                       <Row>
//                         <Col md={6}>
//                           <div className=''>
//                             <Required
//                               className='mt-3 mb-2 fs-5'
//                               title={<Translate value='Labour Card' />}
//                             />
//                             {labourCardDoc && labourCardDoc.length > 0 ? (
//                               labourCardDoc.map((file, index) => (
//                                 <FormControl
//                                   variant='outlined'
//                                   fullWidth
//                                   className='w-75'
//                                 >
//                                   <TextField
//                                     style={{ border: "2px solid grey" }}
//                                     variant='outlined'
//                                     placeholder='Click Browse to Upload'
//                                     // name="mail_id"
//                                     disabled
//                                     value={file.name}
//                                     InputProps={{
//                                       endAdornment: (
//                                         <InputAdornment position='end'>
//                                           {
//                                             <IconButton
//                                               onClick={() =>
//                                                 handleRemoveFile(
//                                                   index,
//                                                   "labourCard"
//                                                 )
//                                               }
//                                             >
//                                               <CancelIcon />
//                                             </IconButton>
//                                           }
//                                         </InputAdornment>
//                                       ),
//                                     }}
//                                   />
//                                 </FormControl>
//                               ))
//                             ) : (
//                               <FormControl fullWidth className='w-75'>
//                                 <TextField
//                                   variant='outlined'
//                                   style={{ border: "2px solid grey" }}
//                                   placeholder='Click Browse to Upload'
//                                   disabled
//                                 />
//                               </FormControl>
//                             )}

//                             <input
//                               type='file'
//                               ref={labourCardFileRef}
//                               onChange={handleFileChange}
//                               multiple
//                               hidden
//                               name='labourCard'
//                             />
//                             <Button
//                               className='mx-3 py-2'
//                               onClick={() => labourCardFileRef.current.click()}
//                             >
//                               <FolderIcon />
//                               Browse
//                             </Button>
//                           </div>
//                         </Col>

//                         <Col md={6}>
//                           <div className=''>
//                             <Required
//                               className='mt-3 mb-2 fs-5'
//                               title={<Translate value='Labour Photo' />}
//                             />
//                             {labourPhotoDoc && labourPhotoDoc.length > 0 ? (
//                               labourPhotoDoc.map((file, index) => (
//                                 <FormControl
//                                   variant='outlined'
//                                   fullWidth
//                                   className='w-75'
//                                 >
//                                   <TextField
//                                     style={{ border: "2px solid grey" }}
//                                     variant='outlined'
//                                     placeholder='Click Browse to Upload'
//                                     // name="mail_id"
//                                     disabled
//                                     value={file.name}
//                                     InputProps={{
//                                       endAdornment: (
//                                         <InputAdornment position='end'>
//                                           {
//                                             <IconButton
//                                               onClick={() =>
//                                                 handleRemoveFile(
//                                                   index,
//                                                   "labourPhoto"
//                                                 )
//                                               }
//                                             >
//                                               <CancelIcon />
//                                             </IconButton>
//                                           }
//                                         </InputAdornment>
//                                       ),
//                                     }}
//                                   />
//                                 </FormControl>
//                               ))
//                             ) : (
//                               <FormControl fullWidth className='w-75'>
//                                 <TextField
//                                   variant='outlined'
//                                   style={{ border: "2px solid grey" }}
//                                   placeholder='Click Browse to Upload'
//                                   disabled
//                                 />
//                               </FormControl>
//                             )}

//                             <input
//                               type='file'
//                               ref={labourPhotoFileRef}
//                               onChange={handleFileChange}
//                               multiple
//                               hidden
//                               name='labourPhoto'
//                             />
//                             <Button
//                               className='mx-3 py-2'
//                               onClick={() => labourPhotoFileRef.current.click()}
//                             >
//                               <FolderIcon />
//                               Browse
//                             </Button>
//                           </div>
//                         </Col>
//                         <Col md={6}>
//                           <div className=''>
//                             <Required
//                               className='mt-3 mb-2 fs-5'
//                               title={
//                                 <Translate value='Latest Pension Order Document' />
//                               }
//                             />
//                             {pensionOrderDoc && pensionOrderDoc.length > 0 ? (
//                               pensionOrderDoc.map((file, index) => (
//                                 <FormControl
//                                   variant='outlined'
//                                   fullWidth
//                                   className='w-75'
//                                 >
//                                   <TextField
//                                     style={{ border: "2px solid grey" }}
//                                     variant='outlined'
//                                     placeholder='Click Browse to Upload'
//                                     // name="mail_id"
//                                     disabled
//                                     value={file.name}
//                                     InputProps={{
//                                       endAdornment: (
//                                         <InputAdornment position='end'>
//                                           {
//                                             <IconButton
//                                               onClick={() =>
//                                                 handleRemoveFile(
//                                                   index,
//                                                   "pensionOrder"
//                                                 )
//                                               }
//                                             >
//                                               <CancelIcon />
//                                             </IconButton>
//                                           }
//                                         </InputAdornment>
//                                       ),
//                                     }}
//                                   />
//                                 </FormControl>
//                               ))
//                             ) : (
//                               <FormControl fullWidth className='w-75'>
//                                 <TextField
//                                   variant='outlined'
//                                   style={{ border: "2px solid grey" }}
//                                   placeholder='Click Browse to Upload'
//                                   disabled
//                                 />
//                               </FormControl>
//                             )}

//                             <input
//                               type='file'
//                               ref={pensionOrderRef}
//                               onChange={handleFileChange}
//                               multiple
//                               hidden
//                               name='pensionOrder'
//                             />
//                             <Button
//                               className='mx-3 py-2'
//                               onClick={() => pensionOrderRef.current.click()}
//                             >
//                               <FolderIcon />
//                               Browse
//                             </Button>
//                           </div>
//                         </Col>
//                       </Row>
//                     </div>
//                     <Col xs={12} md={6} className='w-100 text-center'>
//                       <p className='text-danger font-weight-bolder fs-5 my-4'>
//                         Note: Supported file format is JPG/JPEG/PNG, PDF and
//                         maximum file size is 2 MB.
//                       </p>
//                     </Col>
//                   </>
//                 ) : null}
//               </Row>

//               {!isSubmitSuccess && isVerified ? (
//                 <Row className='mt-5 d-flex justify-content-end w-100'>
//                   <Col
//                     xs={12}
//                     md={4}
//                     className='d-flex justify-content-end align-items-center gap-2'
//                   >
//                     <Button className='' variant='danger' size='lg'>
//                       <Link
//                         style={{ color: "inherit" }}
//                         to={"/admin/dashboard/12"}
//                       >
//                         Back
//                       </Link>
//                     </Button>

//                     <Button
//                       onClick={handleSubmitData}
//                       className=''
//                       variant='success'
//                       size='lg'
//                       disabled={submitLoader}
//                     >
//                       Submit
//                     </Button>
//                   </Col>
//                 </Row>
//               ) : null}
//             </Row>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default BacklogFormAccidentDeath;


import React from "react"
import { useEffect, useRef, useState } from "react"
import { Button, Col, Row } from "react-bootstrap"
import { Translate } from "react-redux-i18n"
import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import MomentUtils from "@date-io/moment"
import { useHistory, useLocation, useParams, Link } from "react-router-dom"
import { EKYCWEBHOOK, getAadhaarDetails, getUserOnVaultReferenceNumber } from "./Aadhaar/Aadhaar.service"
import CancelIcon from "@mui/icons-material/Cancel"
import FolderIcon from "@mui/icons-material/Folder"
import moment from "moment"
import { backlogFuneralAccidentDeathFileUpload } from "./FIleUpload"
import SimpleBackdrop from "components/loadingScreen/Backdrop"
import {
  CheckUserAPIforBackLogFuneralAccidentDeath,
  UpdatebacklogFuneralAccidentDeath,
  CheckUserByMobileNoAPI,
} from "services/CheckUserPension.service"
import { getSubmissionLocationAPI } from "components/SevaSindhu/SevaSindhuServices/SevaSindhu.service"
import { SERVER, BASE_URL } from "store/actions/user.actions"
import { showToast } from "utils/tools"
import axios from "axios"

import logo from "assets/images/Karnataka_logo_100_Pixels.png"
import successIcon from "assets/images/check (3).svg"
import backIcon from "assets/images/Icon awesome-arrow-circle-left.svg"

const BacklogFormAccidentDeath = () => {
  const location = useLocation()
  const { labourUserID, isEdited } = useParams()
  const history = useHistory()

  const [mobileNumber, setMobileNumber] = useState(null)
  const [applicantName, setApplicantName] = useState(null)
  const [deathAge, setDeathAge] = useState(null)
  const [applicantAge, setApplicantAge] = useState(null)
  const [deathPlace, setDeathPlace] = useState(null)
  const [causeofDeath, setCauseofDeath] = useState(null)
  const [relationship, setRelationship] = useState(null)
  const [scheme_avail_id, setScheme_avail_id] = useState(null)
  const [labour_user_id, setLabour_user_id] = useState(null)
  const [registrationNumber, setRegistrationNumber] = useState(null)
  const [referenceNumber, setReferenceNumber] = useState(null)
  const [permanentAddress, setPermanentAddress] = useState(null)
  const [dod, setDod] = useState(null)
  const [registrationDate, setRegistrationDate] = useState(null)

  const [isVerified, setIsVerified] = useState(false)
  const [isMobileVerified, setIsMobileVerified] = useState(false)
  const [isUserVerified, setIsUserVerified] = useState(false)
  const [isAadhaarVerified, setIsAadhaarVerified] = useState(false)
  const [loading, setLoading] = useState(false)
  const [numberLoading, setNumberLoading] = useState(false)
  const [submitLoader, setSubmitLoader] = useState(false)
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false)
  const [isTransferred, setIsTransferred] = useState(false)
  const [isDataFound, setIsDataFound] = useState(false)

  const [circleData, setCircleData] = useState(null)
  const [sevaSindhuID, setSevaSindhuID] = useState(null)
  const [showProfileImage, setShowProfileImage] = useState(null)
  const [vaultRefNumber, setVaultRefNumber] = useState(null)

  const [accidentResultedIn, setAccidentResultedIn] = useState("")
  const [employerName, setEmployerName] = useState("")
  const [employerAddress, setEmployerAddress] = useState("")
  const [accidentDate, setAccidentDate] = useState(null)
  const [ageAtAccident, setAgeAtAccident] = useState("")
  const [applicantType, setApplicantType] = useState("")
  const [bankName, setBankName] = useState("")
  const [accountNumber, setAccountNumber] = useState("")
  const [bankBranch, setBankBranch] = useState("")
  const [ifscCode, setIfscCode] = useState("")

  const [typeOfIssuer, setTypeOfIssuer] = useState("")
  const [issuedDate, setIssuedDate] = useState(null)
  const [unionName, setUnionName] = useState("")
  const [unionRegistrationNumber, setUnionRegistrationNumber] = useState("")
  const [placeOfIssuer, setPlaceOfIssuer] = useState("")
  const [workCertificate, setWorkCertificate] = useState("")

  const labourCardFileRef = useRef < HTMLInputElement > (null)
  const nomineeaadhaarFileRef = useRef < HTMLInputElement > (null)
  const deathcertificateRef = useRef < HTMLInputElement > (null)
  const benificiaryaadharRef = useRef < HTMLInputElement > (null)
  const firCopyRef = useRef < HTMLInputElement > (null)
  const postmortemReportRef = useRef < HTMLInputElement > (null)
  const previousLabourCardRef = useRef < HTMLInputElement > (null)

  const [labourCardDoc, setLabourCardDoc] = useState([])
  const [nomineeaadhaarCardDoc, setNomineeaadhaarCardDoc] = useState([])
  const [deathcertificateDoc, setDeathcertificateDoc] = useState([])
  const [benificiaryaadharDoc, setBenificiaryaadharDoc] = useState([])
  const [firCopyDoc, setFirCopyDoc] = useState([])
  const [postmortemReportDoc, setPostmortemReportDoc] = useState([])
  const [previousLabourCardDoc, setPreviousLabourCardDoc] = useState([])

  const aadhaar = async () => {
    const vaultRefNo = "4736068402104452"
    setVaultRefNumber(vaultRefNo)
    callGetUserOnVaultReferenceNumber(vaultRefNo)
  }

  const callGetUserOnVaultReferenceNumber = async (vaultRefNo) => {
    try {
      const { data: aadhaarData } = await getAadhaarDetails(vaultRefNo)

      if (aadhaarData.length > 0) {
        const aadhaarDOB = moment().diff(aadhaarData[0].dob, "years")
        if (aadhaarDOB >= 18 && aadhaarDOB < 60) {
          showToast("SUCCESS", "Aadhaar Verified Successfully")
          setShowProfileImage(`data:image/png;base64,${aadhaarData[0].document_link}`)
          setIsAadhaarVerified(true)
        } else {
          showToast(
            "ERROR",
            `Your age is ${aadhaarDOB}. As per the KBOCWWB ACT you are not eligible for the registration`,
          )
        }
      } else {
        setIsAadhaarVerified(false)
        showToast("ERROR", "Aadhaar Verification failed, please try again. ")
      }
    } catch (error) {
      showToast("ERROR", "Something went wrong")
    }
  }

  const handleVerifyAadhaar = async () => {
    try {
      const payLoad = {
        responseRedirectURL: `${BASE_URL}/profile/personal/${labourUserID}`,
      }
      const response = await EKYCWEBHOOK(payLoad)
      const { token } = response.data
      aadhaar()
    } catch (error) {
      showToast("ERROR", "Something went wrong! Please try again after some time.")
    }
  }

  const verifySevaSindhu = async () => {
    if (registrationNumber && referenceNumber) {
      setLoading(true)
      const payload = {
        regNo: registrationNumber,
        refNo: referenceNumber,
      }
      try {
        const { data } = await CheckUserAPIforBackLogFuneralAccidentDeath(payload)
        setLoading(false)
        setIsUserVerified(true)
        if (data?.length > 0) {
          if (data[0]?.is_verified_continuation_of_pension) {
            showToast("ERROR", "Application has already been submitted.")
            setIsUserVerified(false)
            setIsVerified(false)
            return
          }
          setIsVerified(true)
          setIsDataFound(false)

          data[0]?.data.forEach((item) => {
            switch (item.field) {
              case "Cause of death":
                setCauseofDeath(item.field_value)
                break
              case "Place of death":
                setDeathPlace(item.field_value)
                break
              case "Date of death":
                setDod(new Date(item.field_value))
                break
              case "Age at the time of death":
                setDeathAge(item.field_value)
                break
              case "Age of Applicant":
                setApplicantAge(item.field_value)
                break
              case "Name of the Applicant":
                setApplicantName(item.field_value)
                break
              case "Permanent Address":
                setPermanentAddress(item.field_value)
                break
              case "Relationship with Beneficiary":
                setRelationship(item.field_value)
                break
            }
          })

          setScheme_avail_id(data[0]?.scheme_availed_id)
          setLabour_user_id(data[0]?.labour_user_id)
          callGetLocationDetails(data[0]?.submissionlocation)
          setRegistrationDate(new Date(data[0]?.registrationdate))
          setSevaSindhuID(data[0]?.id)
        }
      } catch (error) {
        setLoading(false)
        if (error?.response?.data?.errors?.msg) {
          showToast("ERROR", `${error?.response?.data?.errors?.msg}`)
        }
        showToast("ERROR", "Something went wrong")
      }
    } else {
      showToast("ERROR", "Please enter registration and reference number")
    }
  }

  const verifyMobileNumber = async () => {
    if (mobileNumber) {
      setNumberLoading(true)

      try {
        const pensionDetails = await CheckUserByMobileNoAPI({
          mobile: mobileNumber,
        })

        if (pensionDetails?.data && pensionDetails?.data?.length > 0) {
          setNumberLoading(false)
          setIsMobileVerified(false)
          setIsVerified(false)
          showToast("ERROR", "The Mobile Number is Already Manually Registered")
          return
        }

        const response = await axios.get(`${SERVER}/user/${mobileNumber}`)
        setNumberLoading(false)
        setIsMobileVerified(false)
        setIsVerified(false)
        showToast("ERROR", "Mobile Number Already Registered, Please Login!")
      } catch (err) {
        if (err.response && err.response.data.error) {
          setNumberLoading(false)
          setIsMobileVerified(true)
          setIsVerified(true)
        }
      }
    } else {
      showToast("ERROR", "Please enter mobile number")
    }
  }

  const handleReset = () => {
    setMobileNumber("")
    setApplicantName("")
    setDeathPlace("")
    setCauseofDeath("")
    setDeathAge("")
    setApplicantAge("")
    setRegistrationNumber("")
    setReferenceNumber("")
    setDod(null)
    setPermanentAddress("")
    setNomineeaadhaarCardDoc([])
    setLabourCardDoc([])
    setDeathcertificateDoc([])
    setBenificiaryaadharDoc([])
    setFirCopyDoc([])
    setPostmortemReportDoc([])
    setPreviousLabourCardDoc([])
    setIsVerified(false)
    setIsMobileVerified(false)
    setIsUserVerified(false)
    setIsAadhaarVerified(false)
    setIsSubmitSuccess(false)
    setAccidentResultedIn("")
    setEmployerName("")
    setEmployerAddress("")
    setAccidentDate(null)
    setAgeAtAccident("")
    setApplicantType("")
    setBankName("")
    setAccountNumber("")
    setBankBranch("")
    setIfscCode("")
    setTypeOfIssuer("")
    setIssuedDate(null)
    setUnionName("")
    setUnionRegistrationNumber("")
    setPlaceOfIssuer("")
    setWorkCertificate("")
  }

  const handleFileChange = (ev) => {
    if (ev.target.files && ev.target.files.length > 0) {
      const file = ev.target.files[0]
      if (
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "application/pdf"
      ) {
        if (file.size < 2000001) {
          switch (ev.target.name) {
            case "labourCard":
              setLabourCardDoc([...labourCardDoc, file])
              break
            case "nomineeaadhaar":
              setNomineeaadhaarCardDoc([...nomineeaadhaarCardDoc, file])
              break
            case "deathcertificate":
              setDeathcertificateDoc([...deathcertificateDoc, file])
              break
            case "benificiaryaadhar":
              setBenificiaryaadharDoc([...benificiaryaadharDoc, file])
              break
            case "firCopy":
              setFirCopyDoc([...firCopyDoc, file])
              break
            case "postmortemReport":
              setPostmortemReportDoc([...postmortemReportDoc, file])
              break
            case "previousLabourCard":
              setPreviousLabourCardDoc([...previousLabourCardDoc, file])
              break
          }
        } else {
          showToast("ERROR", "Please upload file size less than 2MB.")
        }
      } else {
        showToast("ERROR", "Please upload file in PNG/JPEG or PDF")
      }
    }
  }

  const handleRemoveFile = (index, documentType) => {
    switch (documentType) {
      case "labourCard":
        setLabourCardDoc(labourCardDoc.filter((_, i) => i !== index))
        break
      case "nomineeaadhaar":
        setNomineeaadhaarCardDoc(nomineeaadhaarCardDoc.filter((_, i) => i !== index))
        break
      case "deathcertificate":
        setDeathcertificateDoc(deathcertificateDoc.filter((_, i) => i !== index))
        break
      case "benificiaryaadhar":
        setBenificiaryaadharDoc(benificiaryaadharDoc.filter((_, i) => i !== index))
        break
      case "firCopy":
        setFirCopyDoc(firCopyDoc.filter((_, i) => i !== index))
        break
      case "postmortemReport":
        setPostmortemReportDoc(postmortemReportDoc.filter((_, i) => i !== index))
        break
      case "previousLabourCard":
        setPreviousLabourCardDoc(previousLabourCardDoc.filter((_, i) => i !== index))
        break
    }
  }

  const updateAccidentDeathData = async (
    labourCardDoc,
    nomineeaadhaarCardDoc,
    benificiaryaadharDoc,
    deathcertificateDoc,
    firCopyDoc,
    postmortemReportDoc,
    previousLabourCardDoc,
  ) => {
    const payload = {
      boardID: 1,
      departmentUserID: sevaSindhuID,
      sevasindhuID: sevaSindhuID,
      mobile: mobileNumber,
      regNo: registrationNumber,
      refNo: referenceNumber,
      registrationDate: moment(registrationDate).format("YYYY-MM-DD"),
      applicantName: applicantName,
      dod: moment(dod).format("YYYY-MM-DD"),
      causeofDeath: causeofDeath,
      deathAge: deathAge,
      deathPlace: deathPlace,
      relationship: relationship,
      applicantAge: applicantAge,
      scheme_avail_id: scheme_avail_id,
      labour_user_id: labour_user_id,
      permanentAddress: permanentAddress,
      accidentResultedIn: accidentResultedIn,
      employerName: employerName,
      employerAddress: employerAddress,
      accidentDate: moment(accidentDate).format("YYYY-MM-DD"),
      ageAtAccident: ageAtAccident,
      applicantType: applicantType,
      bankName: bankName,
      accountNumber: accountNumber,
      bankBranch: bankBranch,
      ifscCode: ifscCode,
      typeOfIssuer: typeOfIssuer,
      issuedDate: moment(issuedDate).format("YYYY-MM-DD"),
      unionName: unionName,
      unionRegistrationNumber: unionRegistrationNumber,
      placeOfIssuer: placeOfIssuer,
      workCertificate: workCertificate,
      labourCard: labourCardDoc,
      nomineeaadhaarCardDoc: nomineeaadhaarCardDoc,
      benificiaryaadhar: benificiaryaadharDoc,
      deathCertificate: deathcertificateDoc,
      firCopy: firCopyDoc,
      postmortemReport: postmortemReportDoc,
      previousLabourCard: previousLabourCardDoc,
    }

    try {
      const response = await UpdatebacklogFuneralAccidentDeath(payload)
      setSubmitLoader(false)
      setIsSubmitSuccess(true)
      showToast("SUCCESS", "Application Submitted successfully.")
    } catch (err) {
      setSubmitLoader(false)
      if (err?.response?.data?.errors?.msg) {
        showToast("ERROR", `${err?.response?.data?.errors?.msg}`)
      } else {
        showToast("ERROR", "Something went wrong")
      }
    }
  }

  const handleSubmitData = async (ev) => {
    ev.preventDefault()
    if (
      mobileNumber &&
      applicantName &&
      dod &&
      permanentAddress &&
      causeofDeath &&
      applicantAge &&
      deathPlace &&
      deathAge &&
      relationship &&
      labourCardDoc.length > 0 &&
      nomineeaadhaarCardDoc.length > 0 &&
      deathcertificateDoc.length > 0 &&
      benificiaryaadharDoc.length > 0 &&
      accidentResultedIn &&
      employerName &&
      employerAddress &&
      accidentDate &&
      ageAtAccident &&
      applicantType &&
      bankName &&
      accountNumber &&
      bankBranch &&
      ifscCode &&
      firCopyDoc.length > 0 &&
      postmortemReportDoc.length > 0 &&
      previousLabourCardDoc.length > 0 &&
      typeOfIssuer &&
      issuedDate &&
      unionName &&
      unionRegistrationNumber &&
      placeOfIssuer &&
      workCertificate
    ) {
      setSubmitLoader(true)
      const uploadFiles = await backlogFuneralAccidentDeathFileUpload(
        labourCardDoc,
        nomineeaadhaarCardDoc,
        deathcertificateDoc,
        benificiaryaadharDoc,
        firCopyDoc,
        postmortemReportDoc,
        previousLabourCardDoc,
      )

      if (uploadFiles.success) {
        updateAccidentDeathData(
          uploadFiles.labourCardDoc,
          uploadFiles.nomineeaadhaarCardDoc,
          uploadFiles.benificiaryaadharDoc,
          uploadFiles.deathcertificateDoc,
          uploadFiles.firCopyDoc,
          uploadFiles.postmortemReportDoc,
          uploadFiles.previousLabourCardDoc,
        )
      } else {
        showToast("ERROR", "Please Upload documents")
      }
    } else {
      showToast("ERROR", "Please fill all the mandatory details")
    }
  }

  const callGetLocationDetails = async (submissionLocation) => {
    try {
      const payLoad = {
        ref_seva_sindhu_circle_id: submissionLocation,
      }
      const response = await getSubmissionLocationAPI(payLoad)
      if (response.success) {
        setCircleData(response.data)
      }
    } catch (error) {
      showToast("ERROR", "Something went wrong")
    }
  }

  return (
    <>
      {submitLoader && <SimpleBackdrop />}

      <div>
        <div className="root">
          <Row className="top-div ">
            <Row className="topbar-row">
              <Col xs={12} md={4} lg={6} className="top-bar-col">
                <div className="logo-div-profile">
                  <a href="/admin/dashboard/20">
                    <img id="logo" src={logo || "/placeholder.svg"} alt="..." className="logo-img" />
                  </a>
                  <h1 className="logo-text">
                    <Translate value="header.title" /> <br />
                    <Translate value="header.sub" />
                  </h1>
                </div>
              </Col>
            </Row>
          </Row>
        </div>
        <div className="d-flex justify-content-start align-items-start ps-3 mt-3">
          <Link to={"/admin/dashboard/20"}>
            <img src={backIcon || "/placeholder.svg"} alt="" />
          </Link>
        </div>
        <form onSubmit={handleSubmitData}>
          <div className="d-flex justify-content-start align-items-center ms-5 gap-5">
            {!isSubmitSuccess && (
              <div className="d-flex justify-content-start align-items-center gap-5">
                <Col md={6}>
                  <TextField
                    className="w-100"
                    id="standard-basic"
                    label="Registration number"
                    variant="standard"
                    value={registrationNumber}
                    disabled={isUserVerified}
                    onChange={(ev) => setRegistrationNumber(ev.target.value)}
                  />
                </Col>
                <Col md={6}>
                  <TextField
                    className="w-100"
                    id="standard-basic"
                    label="Reference number"
                    variant="standard"
                    value={referenceNumber}
                    disabled={isUserVerified}
                    onChange={(ev) => setReferenceNumber(ev.target.value)}
                  />
                </Col>
                <Col className="mt-3 d-flex">
                  {loading ? (
                    <Box sx={{ display: "flex" }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Button
                      disabled={!registrationNumber || !referenceNumber || isUserVerified}
                      onClick={verifySevaSindhu}
                      variant={isUserVerified ? "success" : "primary"}
                      className="mx-3"
                    >
                      {isUserVerified ? "Verified" : "Verify"}
                    </Button>
                  )}

                  <Button disabled={!registrationNumber || !referenceNumber} onClick={handleReset} variant="primary">
                    {"Reset"}
                  </Button>
                </Col>
              </div>
            )}
          </div>

          {isUserVerified && (
            <div className="d-flex justify-content-start align-items-center ms-5 gap-5">
              {!isSubmitSuccess && (
                <div className="d-flex justify-content-start align-items-center gap-5 w-100">
                  <Col md={3}>
                    <TextField
                      className="w-100"
                      id="standard-basic"
                      label="Nominee Mobile number"
                      variant="standard"
                      value={mobileNumber}
                      disabled={isMobileVerified || isTransferred}
                      inputProps={{ minLength: 10, maxLength: 10 }}
                      onChange={(ev) => setMobileNumber(ev.target.value.replace(/[^0-9]/g, ""))}
                    />
                  </Col>
                  {!isTransferred && (
                    <Col md={3} className="mt-3 d-flex">
                      {numberLoading ? (
                        <Box sx={{ display: "flex" }}>
                          <CircularProgress />
                        </Box>
                      ) : (
                        <Button
                          disabled={(mobileNumber && mobileNumber.length < 10) || isMobileVerified}
                          onClick={verifyMobileNumber}
                          variant={isMobileVerified ? "success" : "primary"}
                          className=""
                        >
                          {isMobileVerified ? "Verified" : "Verify"}
                        </Button>
                      )}
                    </Col>
                  )}
                </div>
              )}
            </div>
          )}

          {isUserVerified && (
            <div className="d-flex justify-content-start align-items-center ms-5 gap-5">
              {!isSubmitSuccess && (
                <div className="d-flex justify-content-start align-items-center gap-5 w-100">
                  <Col md={3}>
                    <TextField
                      className="w-100"
                      id="standard-basic"
                      label="Verify Nominee Aadhar No"
                      variant="standard"
                      value={vaultRefNumber}
                      disabled={isAadhaarVerified}
                      inputProps={{ minLength: 12, maxLength: 12 }}
                      onChange={(ev) => setVaultRefNumber(ev.target.value.replace(/[^0-9]/g, ""))}
                    />
                  </Col>
                  {!isTransferred && (
                    <Col md={3} className="mt-3 d-flex">
                      {numberLoading ? (
                        <Box sx={{ display: "flex" }}>
                          <CircularProgress />
                        </Box>
                      ) : (
                        <Button
                          disabled={(vaultRefNumber && vaultRefNumber.length < 12) || isAadhaarVerified}
                          onClick={handleVerifyAadhaar}
                          variant={isAadhaarVerified ? "success" : "primary"}
                          className=""
                        >
                          {isAadhaarVerified ? "Verified" : "Verify"}
                        </Button>
                      )}
                    </Col>
                  )}
                </div>
              )}
            </div>
          )}

          <div className="d-flex justify-content-center align-items-center px-3 py-2">
            <div>
              {isSubmitSuccess ? (
                <>
                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <img width={80} height={80} src={successIcon || "/placeholder.svg"} alt="..." />
                    <h2 className="my-3">Successfully Submitted</h2>
                    <Button variant="primary" color="info" onClick={handleReset}>
                      Click here to submit another application
                    </Button>
                  </div>
                </>
              ) : null}

              <Row className="px-5 py-2">
                <Row className="mt-3">
                  {!isSubmitSuccess && isVerified ? (
                    <>
                      <Col xs={12} md={4}>
                        <TextField
                          className="w-100"
                          id="standard-basic"
                          label="Name of the Applicant"
                          variant="standard"
                          disabled={isDataFound}
                          value={applicantName}
                          onChange={(ev) => setApplicantName(ev.target.value)}
                        />
                      </Col>

                      <Col xs={12} md={4}>
                        <TextField
                          className="w-100"
                          id="standard-basic"
                          label="Place of Death"
                          variant="standard"
                          disabled={isDataFound}
                          value={deathPlace}
                          onChange={(ev) => setDeathPlace(ev.target.value)}
                        />
                      </Col>

                      <Col xs={12} md={4}>
                        <TextField
                          className="w-100"
                          id="standard-basic"
                          label="Cause of Death"
                          variant="standard"
                          disabled={isDataFound}
                          value={causeofDeath}
                          onChange={(ev) => setCauseofDeath(ev.target.value)}
                        />
                      </Col>

                      <Col xs={12} md={4}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDatePicker
                            className="w-100"
                            format="DD/MM/YYYY"
                            maxDate={moment(Date())}
                            value={dod}
                            onChange={(date) => {
                              setDod(date)
                            }}
                            placeholder="Date of Death"
                            disabled={isTransferred}
                          />
                        </MuiPickersUtilsProvider>
                      </Col>

                      <Col xs={12} md={4}>
                        <TextField
                          className="w-100"
                          id="standard-basic"
                          label="Age at the time of Death"
                          variant="standard"
                          disabled={isDataFound}
                          value={deathAge}
                          onChange={(ev) => setDeathAge(ev.target.value)}
                        />
                      </Col>

                      <Col xs={12} md={4}>
                        <TextField
                          className="w-100"
                          id="standard-basic"
                          label="Age of Nominee"
                          variant="standard"
                          disabled={isDataFound}
                          value={applicantAge}
                          onChange={(ev) => setApplicantAge(ev.target.value)}
                        />
                      </Col>

                      <Col xs={12} md={4}>
                        <TextareaAutosize
                          id="standard-basic"
                          className="w-100"
                          placeholder="Permanent address"
                          value={permanentAddress}
                          disabled={isTransferred}
                          onChange={(ev) => setPermanentAddress(ev.target.value)}
                        />
                      </Col>

                      <Col xs={12} md={4}>
                        <FormControl className="w-100">
                          <Select
                            value={relationship}
                            onChange={(ev) => setRelationship(ev.target.value)}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value="">
                              <em>Relationship with Beneficiary</em>
                            </MenuItem>
                            <MenuItem value={"Father"}>Father</MenuItem>
                            <MenuItem value={"Mother"}>Mother</MenuItem>
                            <MenuItem value={"Husband"}>Husband</MenuItem>
                            <MenuItem value={"Wife"}>Wife</MenuItem>
                            <MenuItem value={"Son"}>Son</MenuItem>
                            <MenuItem value={"Daughter"}>Daughter</MenuItem>
                            <MenuItem value={"Brother"}>Brother</MenuItem>
                            <MenuItem value={"Sister"}>Sister</MenuItem>
                            <MenuItem value={"Other"}>Other</MenuItem>
                          </Select>
                        </FormControl>
                      </Col>

                      <Col xs={12} md={4}>
                        <TextField
                          className="w-100"
                          id="standard-basic"
                          label="Accident Resulted In"
                          variant="standard"
                          value={accidentResultedIn}
                          onChange={(ev) => setAccidentResultedIn(ev.target.value)}
                        />
                      </Col>

                      <Col xs={12} md={4}>
                        <TextField
                          className="w-100"
                          id="standard-basic"
                          label="Employer Name at the time of accident"
                          variant="standard"
                          value={employerName}
                          onChange={(ev) => setEmployerName(ev.target.value)}
                        />
                      </Col>

                      <Col xs={12} md={4}>
                        <TextareaAutosize
                          id="standard-basic"
                          className="w-100"
                          placeholder="Employer address at the time of accident"
                          value={employerAddress}
                          onChange={(ev) => setEmployerAddress(ev.target.value)}
                        />
                      </Col>

                      <Col xs={12} md={4}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDatePicker
                            className="w-100"
                            format="DD/MM/YYYY"
                            maxDate={moment(Date())}
                            value={accidentDate}
                            onChange={(date) => setAccidentDate(date)}
                            placeholder="Date of Accident"
                          />
                        </MuiPickersUtilsProvider>
                      </Col>

                      <Col xs={12} md={4}>
                        <TextField
                          className="w-100"
                          id="standard-basic"
                          label="Age at the time of accident"
                          variant="standard"
                          value={ageAtAccident}
                          onChange={(ev) => setAgeAtAccident(ev.target.value)}
                        />
                      </Col>

                      <Col xs={12} md={4}>
                        <FormControl className="w-100">
                          <Select
                            value={applicantType}
                            onChange={(ev) => setApplicantType(ev.target.value)}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value="">
                              <em>Applicant Type</em>
                            </MenuItem>
                            <MenuItem value={"Self"}>Self</MenuItem>
                            <MenuItem value={"Nominee"}>Nominee</MenuItem>
                          </Select>
                        </FormControl>
                      </Col>

                      <Col xs={12} md={4}>
                        <TextField
                          className="w-100"
                          id="standard-basic"
                          label="Applicant Bank Name"
                          variant="standard"
                          value={bankName}
                          onChange={(ev) => setBankName(ev.target.value)}
                        />
                      </Col>

                      <Col xs={12} md={4}>
                        <TextField
                          className="w-100"
                          id="standard-basic"
                          label="Applicant Account Number"
                          variant="standard"
                          value={accountNumber}
                          onChange={(ev) => setAccountNumber(ev.target.value)}
                        />
                      </Col>

                      <Col xs={12} md={4}>
                        <TextField
                          className="w-100"
                          id="standard-basic"
                          label="Applicant Bank Branch"
                          variant="standard"
                          value={bankBranch}
                          onChange={(ev) => setBankBranch(ev.target.value)}
                        />
                      </Col>

                      <Col xs={12} md={4}>
                        <TextField
                          className="w-100"
                          id="standard-basic"
                          label="Applicant Bank IFSC Code"
                          variant="standard"
                          value={ifscCode}
                          onChange={(ev) => setIfscCode(ev.target.value)}
                        />
                      </Col>

                      <Col xs={12} md={4}>
                        <TextField
                          className="w-100"
                          id="standard-basic"
                          label="Type of Issuer"
                          variant="standard"
                          value={typeOfIssuer}
                          onChange={(ev) => setTypeOfIssuer(ev.target.value)}
                        />
                      </Col>

                      <Col xs={12} md={4}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDatePicker
                            className="w-100"
                            format="DD/MM/YYYY"
                            maxDate={moment(Date())}
                            value={issuedDate}
                            onChange={(date) => setIssuedDate(date)}
                            placeholder="Issued Date"
                          />
                        </MuiPickersUtilsProvider>
                      </Col>

                      <Col xs={12} md={4}>
                        <TextField
                          className="w-100"
                          id="standard-basic"
                          label="Union Name"
                          variant="standard"
                          value={unionName}
                          onChange={(ev) => setUnionName(ev.target.value)}
                        />
                      </Col>

                      <Col xs={12} md={4}>
                        <TextField
                          className="w-100"
                          id="standard-basic"
                          label="Union Registration Number"
                          variant="standard"
                          value={unionRegistrationNumber}
                          onChange={(ev) => setUnionRegistrationNumber(ev.target.value)}
                        />
                      </Col>

                      <Col xs={12} md={4}>
                        <TextField
                          className="w-100"
                          id="standard-basic"
                          label="Place of Issuer"
                          variant="standard"
                          value={placeOfIssuer}
                          onChange={(ev) => setPlaceOfIssuer(ev.target.value)}
                        />
                      </Col>

                      <Col xs={12} md={4}>
                        <TextField
                          className="w-100"
                          id="standard-basic"
                          label="90 Days Work Certificate"
                          variant="standard"
                          value={workCertificate}
                          onChange={(ev) => setWorkCertificate(ev.target.value)}
                        />
                      </Col>

                      <div className="">
                        <Row>
                          <Col md={6}>
                            <div className="">
                              <Typography className="mt-3 mb-2 fs-5">Labour Card</Typography>
                              {labourCardDoc && labourCardDoc.length > 0 ? (
                                labourCardDoc.map((file, index) => (
                                  <FormControl key={index} variant="outlined" fullWidth className="w-75">
                                    <TextField
                                      style={{ border: "2px solid grey" }}
                                      variant="outlined"
                                      placeholder="Click Browse to Upload"
                                      disabled
                                      value={file.name}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton onClick={() => handleRemoveFile(index, "labourCard")}>
                                              <CancelIcon />
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </FormControl>
                                ))
                              ) : (
                                <FormControl fullWidth className="w-75">
                                  <TextField
                                    variant="outlined"
                                    style={{ border: "2px solid grey" }}
                                    placeholder="Click Browse to Upload"
                                    disabled
                                  />
                                </FormControl>
                              )}

                              <input
                                type="file"
                                ref={labourCardFileRef}
                                onChange={handleFileChange}
                                hidden
                                name="labourCard"
                              />
                              <Button className="mx-3 py-2" onClick={() => labourCardFileRef.current?.click()}>
                                <FolderIcon />
                                Browse
                              </Button>
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="">
                              <Typography className="mt-3 mb-2 fs-5">Nominee Aadhar</Typography>
                              {nomineeaadhaarCardDoc && nomineeaadhaarCardDoc.length > 0 ? (
                                nomineeaadhaarCardDoc.map((file, index) => (
                                  <FormControl key={index} variant="outlined" fullWidth className="w-75">
                                    <TextField
                                      style={{ border: "2px solid grey" }}
                                      variant="outlined"
                                      placeholder="Click Browse to Upload"
                                      disabled
                                      value={file.name}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton onClick={() => handleRemoveFile(index, "nomineeaadhaar")}>
                                              <CancelIcon />
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </FormControl>
                                ))
                              ) : (
                                <FormControl fullWidth className="w-75">
                                  <TextField
                                    variant="outlined"
                                    style={{ border: "2px solid grey" }}
                                    placeholder="Click Browse to Upload"
                                    disabled
                                  />
                                </FormControl>
                              )}

                              <input
                                type="file"
                                ref={nomineeaadhaarFileRef}
                                onChange={handleFileChange}
                                hidden
                                name="nomineeaadhaar"
                              />
                              <Button className="mx-3 py-2" onClick={() => nomineeaadhaarFileRef.current?.click()}>
                                <FolderIcon />
                                Browse
                              </Button>
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="">
                              <Typography className="mt-3 mb-2 fs-5">Death Certificate</Typography>
                              {deathcertificateDoc && deathcertificateDoc.length > 0 ? (
                                deathcertificateDoc.map((file, index) => (
                                  <FormControl key={index} variant="outlined" fullWidth className="w-75">
                                    <TextField
                                      style={{ border: "2px solid grey" }}
                                      variant="outlined"
                                      placeholder="Click Browse to Upload"
                                      disabled
                                      value={file.name}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton onClick={() => handleRemoveFile(index, "deathcertificate")}>
                                              <CancelIcon />
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </FormControl>
                                ))
                              ) : (
                                <FormControl fullWidth className="w-75">
                                  <TextField
                                    variant="outlined"
                                    style={{ border: "2px solid grey" }}
                                    placeholder="Click Browse to Upload"
                                    disabled
                                  />
                                </FormControl>
                              )}

                              <input
                                type="file"
                                ref={deathcertificateRef}
                                onChange={handleFileChange}
                                hidden
                                name="deathcertificate"
                              />
                              <Button className="mx-3 py-2" onClick={() => deathcertificateRef.current?.click()}>
                                <FolderIcon />
                                Browse
                              </Button>
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="">
                              <Typography className="mt-3 mb-2 fs-5">Beneficiary Aadhar Number</Typography>
                              {benificiaryaadharDoc && benificiaryaadharDoc.length > 0 ? (
                                benificiaryaadharDoc.map((file, index) => (
                                  <FormControl key={index} variant="outlined" fullWidth className="w-75">
                                    <TextField
                                      style={{ border: "2px solid grey" }}
                                      variant="outlined"
                                      placeholder="Click Browse to Upload"
                                      disabled
                                      value={file.name}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton onClick={() => handleRemoveFile(index, "benificiaryaadhar")}>
                                              <CancelIcon />
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </FormControl>
                                ))
                              ) : (
                                <FormControl fullWidth className="w-75">
                                  <TextField
                                    variant="outlined"
                                    style={{ border: "2px solid grey" }}
                                    placeholder="Click Browse to Upload"
                                    disabled
                                  />
                                </FormControl>
                              )}

                              <input
                                type="file"
                                ref={benificiaryaadharRef}
                                onChange={handleFileChange}
                                hidden
                                name="benificiaryaadhar"
                              />
                              <Button className="mx-3 py-2" onClick={() => benificiaryaadharRef.current?.click()}>
                                <FolderIcon />
                                Browse
                              </Button>
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="">
                              <Typography className="mt-3 mb-2 fs-5">FIR Copy</Typography>
                              {firCopyDoc && firCopyDoc.length > 0 ? (
                                firCopyDoc.map((file, index) => (
                                  <FormControl key={index} variant="outlined" fullWidth className="w-75">
                                    <TextField
                                      style={{ border: "2px solid grey" }}
                                      variant="outlined"
                                      placeholder="Click Browse to Upload"
                                      disabled
                                      value={file.name}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton onClick={() => handleRemoveFile(index, "firCopy")}>
                                              <CancelIcon />
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </FormControl>
                                ))
                              ) : (
                                <FormControl fullWidth className="w-75">
                                  <TextField
                                    variant="outlined"
                                    style={{ border: "2px solid grey" }}
                                    placeholder="Click Browse to Upload"
                                    disabled
                                  />
                                </FormControl>
                              )}

                              <input type="file" ref={firCopyRef} onChange={handleFileChange} hidden name="firCopy" />
                              <Button className="mx-3 py-2" onClick={() => firCopyRef.current?.click()}>
                                <FolderIcon />
                                Browse
                              </Button>
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="">
                              <Typography className="mt-3 mb-2 fs-5">Postmortem Report</Typography>
                              {postmortemReportDoc && postmortemReportDoc.length > 0 ? (
                                postmortemReportDoc.map((file, index) => (
                                  <FormControl key={index} variant="outlined" fullWidth className="w-75">
                                    <TextField
                                      style={{ border: "2px solid grey" }}
                                      variant="outlined"
                                      placeholder="Click Browse to Upload"
                                      disabled
                                      value={file.name}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton onClick={() => handleRemoveFile(index, "postmortemReport")}>
                                              <CancelIcon />
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </FormControl>
                                ))
                              ) : (
                                <FormControl fullWidth className="w-75">
                                  <TextField
                                    variant="outlined"
                                    style={{ border: "2px solid grey" }}
                                    placeholder="Click Browse to Upload"
                                    disabled
                                  />
                                </FormControl>
                              )}

                              <input
                                type="file"
                                ref={postmortemReportRef}
                                onChange={handleFileChange}
                                hidden
                                name="postmortemReport"
                              />
                              <Button className="mx-3 py-2" onClick={() => postmortemReportRef.current?.click()}>
                                <FolderIcon />
                                Browse
                              </Button>
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="">
                              <Typography className="mt-3 mb-2 fs-5">Previous Labour Card</Typography>
                              {previousLabourCardDoc && previousLabourCardDoc.length > 0 ? (
                                previousLabourCardDoc.map((file, index) => (
                                  <FormControl key={index} variant="outlined" fullWidth className="w-75">
                                    <TextField
                                      style={{ border: "2px solid grey" }}
                                      variant="outlined"
                                      placeholder="Click Browse to Upload"
                                      disabled
                                      value={file.name}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton onClick={() => handleRemoveFile(index, "previousLabourCard")}>
                                              <CancelIcon />
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </FormControl>
                                ))
                              ) : (
                                <FormControl fullWidth className="w-75">
                                  <TextField
                                    variant="outlined"
                                    style={{ border: "2px solid grey" }}
                                    placeholder="Click Browse to Upload"
                                    disabled
                                  />
                                </FormControl>
                              )}

                              <input
                                type="file"
                                ref={previousLabourCardRef}
                                onChange={handleFileChange}
                                hidden
                                name="previousLabourCard"
                              />
                              <Button className="mx-3 py-2" onClick={() => previousLabourCardRef.current?.click()}>
                                <FolderIcon />
                                Browse
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>

                      <Col xs={12} className="mt-4">
                        <Button
                          variant="primary"
                          type="submit"
                          disabled={!isVerified || !isMobileVerified || !isAadhaarVerified}
                        >
                          Submit
                        </Button>
                      </Col>
                    </>
                  ) : null}
                </Row>
              </Row>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default BacklogFormAccidentDeath

