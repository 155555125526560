import 'date-fns';
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import { CameraAlt } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { getTokenCookie } from 'utils/tools';
import downloadicn from 'assets/images/Icon material-file-download.svg';

import { useDispatch, useSelector } from 'react-redux';
import { SERVER, SERVER_NAME } from 'store/actions/user.actions';

import Moment from 'react-moment';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png';
import back from 'assets/images/Icon awesome-arrow-circle-left.svg';

import NoImage from 'assets/images/No signature available-01-01.svg';

import QRCode from 'react-qr-code';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import SimpleBackdrop from 'components/loadingScreen/Backdrop';
import { ECardServiceAPI } from 'services/ECard.service';
import moment from 'moment';
import { ConvertToBase64API } from 'services/Base64.service';

const Ecard = (props) => {
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: '#ffffffee',
      color: '#000',
      display: 'block',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    backdropCheck: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#6CC17A',
    },
    backdropCheckp: {
      fontSize: '30px',
      fontWeight: 'bold',
    },
  }));

  let { id } = useParams();
  var idName = id.split('_');
  var uid = idName[0].split('=')[1];
  var bid = idName[1].split('=')[1];

  // const [qrlink, setqrlink] = React.useState(`https://gcdmsstaging.web.app/e-card/uid=${uid}_bid=${bid}`)
  const [qrlink, setqrlink] = React.useState(
    `${SERVER_NAME}e-card/uid=${uid}_bid=${bid}`,
  );

  const myRef = React.createRef();
  const descRef = React.useRef();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);

  const classes = useStyles();

  const [profilePic, setprofilePic] = React.useState('');
  const [signPic, setsignPic] = React.useState('');

  const [famiyDetails, setFamilyDetails] = useState([]);
  const [nomineeDetails, setNomineeDetails] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElpassbook_nominee, setAnchorElpassbook_nominee] =
    React.useState(null);
  const [anchorElninty, setAnchorElninty] = React.useState(null);
  const [anchorElration, setAnchorElration] = React.useState(null);

  const [aadhaarDetails, setAadhaarDetails] = useState(null);

  const [loadingBackDrop, setLoadingBackDrop] = useState(false);
  const [loading, setLoading] = React.useState(false);

  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [ecardData, setEcardData] = useState(null);
  const [dependentName, setDependentName] = useState(null);

  const [dependentLabel, setDependentLabel] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [userPhoto, setUserPhoto] = useState(null);
  const [signaturePhoto, setSignaturePhoto] = useState(null);

  const [allValues, setAllValues] = React.useState({
    language: users.profile_details.language,
    users: users.profile_details.users,
  });

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const callECardDetails = async (uid) => {
    try {
      const response = await ECardServiceAPI(+uid);
      if (response.success) {
        setLoadingBackDrop(false);
        setEcardData(response.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (uid) {
      setLoadingBackDrop(true);
      callECardDetails(uid);
    }
  }, [uid]);

  useEffect(() => {
    if (ecardData) {
      console.log('🚀 ~ useEffect ~ ecardData:', ecardData);
      const familyData = ecardData?.family?.map((fam) => fam);

      let dependentname = '';
      let dependentlabel = '';
      const dependentSingle = ecardData?.family?.filter(
        (fam) => fam.relation === 'Father' || fam.relation === 'Mother',
      );
      const dependentMarried = ecardData?.family?.filter(
        (fam) => fam.relation === 'Wife' || fam.relation === 'Husband',
      );

      if (ecardData.marital_status === 'Single') {
        dependentlabel = 'ತಂದೆ ಹೆಸರು / Father Name';
        dependentname = ecardData?.family?.filter(
          (fam) => fam.relation === 'Father',
        );
      } else if (ecardData.marital_status === 'Married') {
        if (ecardData.gender === 'Male') {
          dependentlabel = 'ಪತ್ನಿ ಹೆಸರು /Wife Name';
          dependentname = ecardData?.family?.filter(
            (fam) => fam.relation === 'Wife',
          );
        } else if (ecardData.gender === 'Female') {
          dependentlabel = 'ಪತಿ ಹೆಸರು / Husband Name';
          dependentname = ecardData?.family?.filter(
            (fam) => fam.relation === 'Husband',
          );
        }
      } else if (ecardData?.marital_status === 'N/A') {
        if (
          ecardData.family.findIndex((fam) => fam.relation === 'Husband') > -1
        ) {
          dependentlabel = 'ಪತಿ ಹೆಸರು / Husband Name';
          dependentname = ecardData?.family?.filter(
            (fam) => fam.relation === 'Husband',
          );
        } else if (
          ecardData.family.findIndex((fam) => fam.relation === 'Father') > -1
        ) {
          dependentlabel = 'ತಂದೆ ಹೆಸರು / Father Name';
          dependentname = ecardData?.family?.filter(
            (fam) => fam.relation === 'Father',
          );
        } else {
          dependentlabel = null;
        }
      }
      const nomineeData = ecardData?.family?.filter((fam) => fam.is_nominee);

      setNomineeDetails(nomineeData && nomineeData[0]);
      setDependentLabel(dependentlabel);
      setDependentName(dependentname && dependentname[0]);
      setFamilyDetails(familyData);
    }
  }, [ecardData]);

  const handleImageLoad = () => {
    setLoaded(true);
  };

  const printDocument = () => {
    const dwnldicn = document.getElementById('dwnldicn');

    dwnldicn.style.padding = '10px';
    const input = document.getElementById('divToPrint');

    setLoading(true);
    setOpenBackdrop(true);
    html2canvas(input, {
      scrollY: -window.scrollY,
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');

        const pdf = new jsPDF();

        pdf.addImage(imgData, 'JPEG', 50, 10, 105, 238);

        window.open(
          pdf.output('bloburl'),
          '_blank',
          'toolbar=0,location=0,menubar=0',
        );
      })
      .then(() => {
        dwnldicn.style.padding = '0px';
        setLoading(false);
        setOpenBackdrop(false);
      });
  };

  const callBase64Convert = async (imageID) => {
    try {
      const response = await ConvertToBase64API(imageID);

      return response?.data;
    } catch (error) {}
  };

  useEffect(() => {
    const fetchData = async () => {
      if (ecardData?.user_photo_id) {
        const userPhotoResponse = await callBase64Convert(
          ecardData.user_photo_id,
          'userPhoto',
        );

        setUserPhoto(userPhotoResponse?.base_64_content);
      }
      if (ecardData?.inspector_signature) {
        const signatureResponse = await callBase64Convert(
          ecardData.inspector_signature,
          'signature',
        );

        setSignaturePhoto(signatureResponse?.base_64_content);
      }
    };

    fetchData();
  }, [ecardData]);

  return (
    <>
      <>
        <Row className="top-div">
          {loadingBackDrop ? <SimpleBackdrop /> : null}
          <Row className="topbar-row">
            <Col xs={12} md={8} lg={9} className="top-bar-col">
              <div className="logo-div-profile">
                <img id="logo" src={logo} alt="..." className="logo-img" />
                <h1 className="logo-text">
                  Karnataka Building & Other Construction Workers Welfare Board
                  (KBOCWWB)
                  <br />
                  Government of Karnataka
                </h1>
              </div>
            </Col>
          </Row>
        </Row>
        <div className="d-flex justify-content-center align-items-center">
          <Row className="schemes-title-row-red">
            <Col xs={12} md={9}>
              {users.login_user == null ? null : (
                <a href="/dashboard-user" className="cal-icon-hover-icn">
                  <img alt="..." className="cal-icon hover-icn" src={back} />
                </a>
              )}

              <h1>E-Card</h1>
            </Col>
          </Row>

          <Row className="m-0">
            {loading ? (
              <Backdrop
                className={classes.backdrop}
                open={openBackdrop}
                onClick={loading ? null : handleCloseBackdrop}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            ) : null}
          </Row>
        </div>

        <div className="d-flex justify-content-center flex-row">
          <div
            id="divToPrint"
            className="my-4 "
            style={{
              backgroundColor: '#fff',
              width: '60%',
              minHeight: 'auto',
              marginLeft: 'auto',
              marginRight: 'auto',
              textAlign: 'center',
              background: '#FFFFFF 0% 0% no-repeat padding-box',
              border: '2px solid #00000029',
              // boxShadow: '0px 3px 8px #00000029',
            }}
          >
            <img
              src={downloadicn}
              id="dwnldicn"
              alt="..."
              className="download-icon"
              onClick={printDocument}
            />
            <div className="logo-div-ack">
              <img
                id="logo"
                src={logo}
                alt="..."
                className="logo-img"
                style={{ marginLeft: '50px' }}
              />

              <h1 className="logo-text idcard" style={{ fontSize: '22px' }}>
                ಕರ್ನಾಟಕ ಕಟ್ಟಡ ಮತ್ತು ಇತರೆ ನಿರ್ಮಾಣ ಕಾರ್ಮಿಕರ ಕಲ್ಯಾಣ ಮಂಡಳಿ
                (ಕಕಇನಿಕಾಕಮಂ) <br />
                Karnataka Building & Other Construction Workers Welfare Board
                (KBOCWWB) <br />
              </h1>
            </div>

            <Row className="ack-table-row idcard profile_qr mt-5">
              <Col xs={6} className="ack-col">
                <div className="picupload idcard">
                  {!loaded && (
                    <div className="d-flex justify-content-center align-items-center">
                      <CircularProgress />
                    </div>
                  )}
                  <img
                    alt="..."
                    width={'200'}
                    height={'200'}
                    // src={`${SERVER}/upload/v2/file/${
                    //   ecardData?.user_photo_id
                    // }?token=${getTokenCookie()}`}
                    src={`data:image/png;base64, ${userPhoto}`}
                    onLoad={handleImageLoad}
                  />
                </div>
              </Col>
              <Col xs={6} className="ack-col">
                <div className="picupload idcard ">
                  <QRCode value={qrlink} size="200" />
                </div>
              </Col>
            </Row>

            <Row className="ack-table-row idcard mt-5">
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>ಹೆಸರು / Name:</p>
              </Col>

              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>{ecardData?.name}</p>
              </Col>
            </Row>
            {dependentLabel !== null && (
              <Row className="ack-table-row idcard">
                <Col xs={6} className="ack-col">
                  <p style={{ fontSize: '22px' }} className="100">
                    {dependentLabel} :
                  </p>
                </Col>

                <Col xs={6} className="ack-col">
                  <p style={{ fontSize: '22px' }}>{dependentName?.name}</p>
                </Col>
              </Row>
            )}

            <Row className="ack-table-row idcard">
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }} className="w-75">
                  ಜನ್ಮ ದಿನಾಂಕ :<br /> Date of Birth
                </p>
              </Col>
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>{ecardData?.date_of_birth}</p>
              </Col>
            </Row>

            <Row className="ack-table-row idcard">
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>ಲಿಂಗ / Gender :</p>
              </Col>
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>{ecardData?.gender}</p>
              </Col>
            </Row>

            <Row className="ack-table-row idcard">
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>Marital Status :</p>
              </Col>
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>{ecardData?.marital_status}</p>
              </Col>
            </Row>

            <Row className="ack-table-row idcard">
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>ವಿಳಾಸ / Address :</p>
              </Col>
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '20px', width: 'auto' }}>
                  {ecardData?.address}
                </p>
              </Col>
            </Row>

            <Row className="ack-table-row idcard mt-3">
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>
                  ನೋಂದಣಿ ಸಂಖ್ಯೆ / Registration ID :
                </p>
              </Col>

              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px', marginTop: '3px' }}>
                  {ecardData?.registration_number}
                </p>
              </Col>
            </Row>
            <Row className="ack-table-row idcard mt-3">
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>
                  ನೋಂದಣಿ ದಿನಾಂಕ / Registration Date :
                </p>
              </Col>
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>
                  {moment(
                    ecardData?.approved_date,
                    'DD/MM/YYYY hh:mm:ss A',
                  ).format('DD/MM/YYYY')}
                </p>
              </Col>
            </Row>

            <Row className="ack-table-row idcard">
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>ಈ ದಿನಾಂಕದಿಂದ / From Date :</p>
              </Col>
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>
                  {ecardData?.validity_from_date}
                </p>
              </Col>
            </Row>
            <Row className="ack-table-row idcard">
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>ಇಲ್ಲಿಯವರೆಗೆ / To Date :</p>
              </Col>
              <Col xs={6} className="ack-col">
                {/* <p>{ToDate1}</p> */}
                <p style={{ fontSize: '22px' }}>
                  {ecardData?.validity_to_date}
                </p>
              </Col>
            </Row>

            <Row className="ack-table-row idcard">
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>
                  ಕೆಲಸದ ಸ್ವರೂಪ :<br />
                  Nature of Work
                </p>
              </Col>
              <Col xs={6} className="ack-col">
                {/* <p>Work</p> */}

                <p style={{ fontSize: '22px' }}>{ecardData?.nature_of_work}</p>
              </Col>
            </Row>
            <Row className="ack-table-row idcard">
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>
                  ಮೊಬೈಲ್ ಸಂಖ್ಯೆ :<br /> Mobile Number
                </p>
              </Col>

              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>{ecardData?.mobile_no}</p>
              </Col>
            </Row>
            <Row className="ack-table-row"></Row>

            <div className="d-flex justify-content-center align-items-center">
              <Row className="ack-table-row idcard">
                <Col xs={12} className="ack-col nomineeTitle">
                  <p style={{ fontSize: '22px' }}>
                    ನಾಮನಿರ್ದೇಶಿತರ ವಿವರಗಳು / Nominee Details
                  </p>
                </Col>
              </Row>
            </div>
            <Row className="ack-table-row idcard">
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>
                  ನಾಮನಿರ್ದೇಶಿತರ ಹೆಸರು :<br />
                  Nominee Name
                </p>
              </Col>
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>{nomineeDetails?.name}</p>
              </Col>
            </Row>
            <Row className="ack-table-row idcard">
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>ಸಂಬಂಧ / Relationship :</p>
              </Col>
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>{nomineeDetails?.relation}</p>
              </Col>
            </Row>
            <Row className="ack-table-row idcard">
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>ವಯಸ್ಸು / Age :</p>
              </Col>
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>{nomineeDetails?.age}</p>
              </Col>
            </Row>
            {famiyDetails && famiyDetails.length > 1 ? (
              <>
                <Row className="ack-table-row"></Row>
                <Row className="ack-table-row idcard">
                  <Col
                    xs={12}
                    className="ack-col nomineeTitle d-flex justify-content-center"
                  >
                    <p style={{ fontSize: '22px' }}>
                      ಕುಟುಂಬದ ವಿವರಗಳು / Family Details
                    </p>
                  </Col>
                </Row>
                <Row
                  className="d-flex justify-content-center"
                  style={{ fontSize: '22px' }}
                >
                  <table
                    className="e-card-table-border"
                    style={{ width: '90%' }}
                  >
                    <tr>
                      <th className="e-card-table-border">SL.No</th>
                      <th className="e-card-table-border">Name</th>
                      <th className="e-card-table-border">Age</th>
                      <th className="e-card-table-border">Relationship</th>
                      <th className="e-card-table-border">Gender</th>
                    </tr>

                    {famiyDetails &&
                      famiyDetails
                        .filter((data) => data.is_nominee === false)
                        .map((row, index) => (
                          <tr>
                            <td className="e-card-table-border">{index + 1}</td>
                            <td className="e-card-table-border">{row.name}</td>
                            <td className="e-card-table-border">{row.age}</td>
                            <td className="e-card-table-border">
                              {row.relation}
                            </td>
                            <td className="e-card-table-border">
                              {row.relation === 'Father' ||
                              row.relation === 'Husband' ||
                              row.relation === 'Son' ||
                              row.relation === 'Brother'
                                ? 'Male'
                                : row.relation === 'Mother' ||
                                    row.relation === 'Wife' ||
                                    row.relation === 'Daughter' ||
                                    row.relation === 'Sister'
                                  ? 'Female'
                                  : row.relation === 'Guardian'
                                    ? 'Male / Female'
                                    : ''}
                            </td>
                          </tr>
                        ))}
                  </table>
                </Row>
              </>
            ) : null}

            <Row className="ack-table-row"></Row>

            <div className="d-flex justify-content-around align-items-center">
              <div className="mx-5">
                <p className="w-100 pt-4">
                  ಕಾರ್ಮಿಕರ ಸಹಿ /<br /> Labour Signature
                </p>
                <div
                  className="picupload idcard"
                  style={{
                    width: '200px',
                    height: '84px',
                    border: '2px solid grey',
                  }}
                ></div>
              </div>

              <div className="mx-5">
                <p className="w-100 pt-4">
                  ಕಾರ್ಮಿಕ ನಿರೀಕ್ಷಕರ ಸಹಿ /<br />
                  Labour Inspector Signature
                </p>
                <div
                  className="picupload idcard"
                  style={{ width: '200px', height: '84px' }}
                >
                  {!loaded && (
                    <div className="d-flex justify-content-center align-items-center">
                      <CircularProgress />
                    </div>
                  )}
                  {ecardData?.inspector_signature ? (
                    <img
                      src={`data:image/png;base64, ${signaturePhoto}`}
                      alt="..."
                      width={'200'}
                      height={'84'}
                      style={{ border: '2px solid grey' }}
                      onLoad={handleImageLoad}
                    />
                  ) : (
                    <img src={NoImage} alt="..." width={'180'} height={'80'} />
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end align-items-center">
              <div className="d-flex justify-content-center flex-column mx-5">
                <span>ವೃತ್ತ:{ecardData?.circle}</span>
                <span>ತಾಲೂಕು:{ecardData?.taluk}</span>
                <span>ಜಿಲ್ಲೆ:{ecardData?.district}</span>
                <span>ಅನುಮೋದನೆಗೊಂಡ ದಿನಾಂಕ:{ecardData?.approved_date}</span>
                <span>
                  ಇ-ಕಾರ್ಡ್ ಮುದ್ರಿತ ದಿನಾಂಕ:{' '}
                  {ecardData?.approved_date.split(' ')[0]}
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Ecard;
