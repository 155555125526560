import axios from "axios";
import { SERVER } from "store/actions/user.actions";
import { getTokenCookie } from "utils/tools";

export const CheckUserAPI = async (payload) => {
  try {
    const { data } = await axios.post(
      `${SERVER}/schemes/v2/continuation-pension`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const CheckUserByMobileNoAPI = async (payload) => {
  try {
    const { data } = await axios.post(
      `${SERVER}/schemes/continuation-pension-by-mobile`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const InsertPensionAPI = async (payload) => {
  try {
    const { data } = await axios.put(
      `${SERVER}/schemes/v2/continuation-pension`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const UpdatePensionAPI = async (payload) => {
  try {
    const { data } = await axios.patch(
      `${SERVER}/schemes/v2/continuation-pension`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};



export const CheckUserAPIforBackLogFuneralAccidentDeath = async (payload) => {
  try {
    const { data } = await axios.post(
      `${SERVER}/schemes/v1/backlogFuneralAccidentDeath`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};


export const UpdatebacklogFuneralAccidentDeath = async (payload) => {
  try {
    const { data } = await axios.post(
      `${SERVER}/schemes/v1/UpdatebacklogFuneralAccidentDeath`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};