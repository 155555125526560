export const RENEWAL_SAKALA_DAYS = 15;

export const HOSPITAL_SCHEME_VALUE = 16;

export const HOSPITAL_HEADER_KEYS = [
  'Hospital/Clinic name',
  'Name of the Hospital where  child was born',
];

export const SCHEMES_UNDER_MEDICAL = [16, 22, 23];
