import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import logo from "assets/images/Karnataka_logo_100_Pixels.png";
import { Translate } from "react-redux-i18n";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextareaAutosize,
} from "@material-ui/core";

import {
  // Link,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

import {
  EKYCWEBHOOK,
  getAadhaarDetails,
  getUserOnVaultReferenceNumber,
  updateEKYCVaultRefNumber,
} from "./Aadhaar/Aadhaar.service";

// import Select from '@mui/material/Select';

// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import closeIcon from "assets/images/close.png";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import CancelIcon from "@mui/icons-material/Cancel";
import FolderIcon from "@mui/icons-material/Folder";

import axios from "axios";
import { SERVER } from "store/actions/user.actions";
import { getTokenCookie, Required, showToast } from "utils/tools";
import moment from "moment";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import uploadFileAPI from "utils/fileupload";
import { backlogFuneralAccidentDeathFileUpload } from "./FIleUpload";
import { useSelector } from "react-redux";

import successIcon from "assets/images/check (3).svg";
import SimpleBackdrop from "components/loadingScreen/Backdrop";
import backIcon from "assets/images/Icon awesome-arrow-circle-left.svg";
import {
  CheckUserAPI,
  // InsertPensionAPI,
  CheckUserAPIforBackLogFuneralAccidentDeath,
  UpdatebacklogFuneralAccidentDeath,
  CheckUserByMobileNoAPI,
} from "services/CheckUserPension.service";
import {
  cityData,
  districtData,
  talukData,
  villageData,
} from "components/SevaSindhu/LabourDetails/AddressLogic";
import { getSubmissionLocationAPI } from "components/SevaSindhu/SevaSindhuServices/SevaSindhu.service";
import { ContinuationPensionFileUpload } from "./FIleUpload";
import {
  BASE_URL,
  // SERVER,
  // addLabourPersonalDetails,
  // getUserRegistrationDetails,
} from "store/actions/user.actions";
import { pl } from "date-fns/locale";
import { set } from "store";


const BacklogFormFuneral = () => {

  const location = useLocation();
  const { labourUserID, isEdited } = useParams();

  // const users = useSelector((state) => state.users);
  // const deptUserID = users?.getDeptUserRole?.data[0]?.department_user_id;
  const [mobileNumber, setMobileNumber] = useState(null);
  const [applicantName, setApplicantName] = useState(null);
  const [deathAge, setdeathAge] = useState(null);
  const [applicantAge, setApplicantAge] = useState(null);
  const [deathPlace, setDeathPlace] = useState(null);
  const [causeofDeath, setCauseofDeath] = useState(null);
  const [relationship, setRelationship] = useState(null);
  const [scheme_avail_id, setScheme_avail_id] = useState(null);
  const [labour_user_id, setLabour_user_id] = useState(null);

  const [registrationNumber, setRegistrationNumber] = useState(null);
  const [referenceNumber, setReferenceNumber] = useState(null);
  const [permanentAddress, setPermanentAddress] = useState(null);


  const [causeofDeathId, setCauseofDeathId] = useState(null);
  const [deathPlaceId, setDeathPlaceId] = useState(null);
  const [relationshipId, setRelationshipId] = useState(null);
  const [applicantAgeId, setApplicantAgeId] = useState(null);
  const [applicantNameId, setApplicantNameId] = useState(null);
  const [permanentAddressId, setPermanentAddressId] = useState(null);
  const [registrationDateId, setRegistrationDateId] = useState(null);
  const [dodId, setDodId] = useState(null);
  const [deathAgeId, setDeathAgeId] = useState(null);
  const [mobileNumberId, setMobileNumberId] = useState(null);
  const [referenceNumberId, setReferenceNumberId] = useState(null);
  const [department_user_id, setDepartment_user_id] = useState(null);

  // const [stateID, setStateID] = useState(null);
  // const [districtID, setDistrictID] = useState(null);
  // const [talukID, setTalukID] = useState(null);
  // const [cityID, setCityID] = useState(null);
  // const [villageID, setVillageID] = useState(null);
  // const [circleID, setCircleID] = useState(null);

  // const [stateName, setStateName] = useState(null);
  // const [districtName, setDistrictName] = useState(null);
  // const [talukName, setTalukName] = useState(null);
  // const [cityName, setCityName] = useState(null);
  // const [villageName, setVillageName] = useState(null);
  // const [circleName, setCircleName] = useState(null);

  // const [district, setDistrict] = useState(null);
  // const [tauluk, setTaluk] = useState(null);
  // const [city, setCity] = useState(null);
  // const [village, setVillage] = useState(null);
  // const [circle, setCircle] = useState(null);

  const [dod, setDod] = useState(null);

  // const [pensionAvailDate, setPensionAvaliDate] = useState(null);
  // const [pensionRecievedDate, setPensionRecievedDate] = useState(null);

  const [registrationDate, setRegistrationDate] = useState(null);
  // const [validityFromDate, setValidityFromDate] = useState(null);
  // const [validityToDate, setValidityToDate] = useState(null);

  const [isVerified, setIsVerified] = useState(false);
  const [isMobileVerified, setIsMobileVerified] = useState(false);
  const [isUserVerified, setIsUserVerified] = useState(false);
  const [isAadhaarVerified, setisAadhaarVerified] = useState(false)
  const [loading, setLoading] = useState(false);
  const [numberLoading, setNumberLoading] = useState(false);

  const [submitLoader, setSubmitLoader] = useState(false);

  const labourCardFileRef = useRef(null);
  const aadhaarCardFileRef = useRef(null);
  const nomineeaadhaarFileRef = useRef(null);
  const deathcertificate = useRef(null);
  const [nomineeaadhaar, setnomineeaadhaar] = useState([]);
  const benificiaryaadhar = useRef(null);


  const [labourCardDoc, setLabourCardDoc] = useState([]);
  const [nomineeaadhaarCardDoc, setnomineeaadhaarCardDoc] = useState([]);
  const [deathcertificateDoc, setdeathcertificateDoc] = useState([]);
  const [benificiaryaadharDoc, setbenificiaryaadharDoc] = useState([]);




  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

  const [isTransferred, setIsTransferred] = useState(false);
  const [isDataFound, setIsDataFound] = useState(false);
  const [circleData, setCircleData] = useState(null);

  const [sevaSindhuID, setSevaSindhuID] = useState(null);

  // const [gender, setGender] = useState(null);

  // const [dataUri, setDataUri] = useState('');

  // Address Drop Down API's CALL

  // useEffect(() => {
  //   if (deptUserID && !isDataFound) {
  //     axios
  //       .get(`${SERVER}/admin/alc-district?departmentUserID=${deptUserID}`, {
  //         headers: {
  //           Authorization: `Bearer ${getTokenCookie()}`,
  //         },
  //       })
  //       .then((res) => {
  //         setDistrict(res.data.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // }, [deptUserID, isDataFound]);

  // useEffect(() => {
  //   if (districtID) {
  //     axios
  //       .get(`${SERVER}/global/get_taluk_details?district_id=${districtID}`)
  //       .then((res) => {
  //         setTaluk(res.data.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // }, [districtID]);

  // useEffect(() => {
  //   if (talukID) {
  //     axios
  //       .get(`${SERVER}/global/get_city_details?taluk_id=${talukID}`)
  //       .then((res) => {
  //         setCity(res.data.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // }, [talukID]);

  // useEffect(() => {
  //   if (cityID) {
  //     axios
  //       .get(`${SERVER}/global/get_village_details?city_id=${cityID}`)
  //       .then((res) => {
  //         setVillage(res.data.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // }, [cityID]);

  // useEffect(() => {
  //   if (districtID && talukID && cityID) {
  //     const circlePayload = {
  //       district_id: districtID,
  //       taluk_id: null,
  //       gram_panchayat_id: null,
  //       village_area_ward: null,
  //     };
  //     axios
  //       .post(`${SERVER}/config/circle/get_circles_on_area`, circlePayload, {
  //         headers: {
  //           Authorization: `Bearer ${getTokenCookie()}`,
  //         },
  //       })
  //       .then((res) => {
  //         // setCircleID(res.data.data);

  //         const filteredArr = res.data.data.reduce((acc, current) => {
  //           const x = acc.find((item) => item.circle_id === current.circle_id);
  //           if (!x) {
  //             return acc.concat([current]);
  //           } else {
  //             return acc;
  //           }
  //         }, []);

  //         setCircle(filteredArr);
  //       });
  //   }
  // }, [districtID, talukID, cityID]);

  // const verifyAadharNo = async () => {
  //   setisAadhaarVerified(true);


  // }

  const [showProfileImage, setShowProfileImage] = useState(null);
  const [imageEKYCID, setImageEKYCID] = useState(null);

  const [vaultRefNumber, setVaultRefNumber] = useState(null);

  const aadhaar = async () => {
    // useEffect(() => {

    const currentUrl = window.location.toString();
    // const queryString = new URLSearchParams(location.search);
    // if (currentUrl.includes("Success")) {
    // const vaultRefNo = queryString.get("vaultrefno");
    const vaultRefNo = '4736068402104452';
    // if (vaultRefNo) {
    setVaultRefNumber(vaultRefNo);
    callGetUserOnVaultReferenceNumber(vaultRefNo);
    // }
    // } else if (currentUrl.includes("Failure")) {
    //   showToast("ERROR", "Aadhaar Verification failed, please try again.");
    // }
    // }, [location.search]);
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }


  const callGetUserOnVaultReferenceNumber = async (vaultRefNo) => {
    try {
      const payLoad = {
        key: "aadhar_no",
        value: vaultRefNo,
        board_id: 1,
      };

      // const response = await getUserOnVaultReferenceNumber(payLoad);

      // if (response.data.length !== 0) {
      //   showToast(
      //     "ERROR",
      //     "Aadhaar already registered with other mobile number"
      //   );

      //   return;
      // }

      const { data: aadhaarData } = await getAadhaarDetails(vaultRefNo);

      console.log("aadhaarData", aadhaarData);
      // const updateEKYCPayLoad = {
      //   labourUserId: +labourUserID,
      //   aadharNo: vaultRefNo,
      // };

      if (aadhaarData.length > 0) {
        const aadhaarDOB =
          aadhaarData && moment().diff(aadhaarData[0].dob, "years");
        if (aadhaarDOB >= 18 && aadhaarDOB < 60) {
          // const updateEKYCResponse =
          //   await updateEKYCVaultRefNumber(updateEKYCPayLoad);
          // if (updateEKYCResponse.success) {
          showToast("SUCCESS", "Aadhaar Verified Successfully");
          // Allow
          setShowProfileImage(
            `data:image/png;base64,${aadhaarData[0].document_link}`
          );
          // setImageEKYCID(aadhaarData[0].image);
          // Split into FirstName,MiddleName,LastName
          // console.log('ghg', data[0].dob && moment(data[0].dob, 'YYYY-MM-DD'));
          // var first_name = "";
          // var middle_name = "";
          // var last_name = "";
          // var nameArr = aadhaarData[0].name.split(" ");

          // if (nameArr.length === 1) {
          //   first_name = nameArr[0];
          // } else if (nameArr.length === 2) {
          //   first_name = nameArr[0];
          //   last_name = nameArr[1];
          // } else if (nameArr.length >= 3) {
          //   first_name = nameArr[0];
          //   middle_name = nameArr[1];
          //   last_name = nameArr[2];
          // }
          // const fullName = data[0].name.split(' ');
          // const lastName = fullName.pop(); // 'Panakkal'
          // const middleName = fullName.pop();
          // const firstName = fullName.join(' ');
          // const deathAge = new Date(dod).getFullYear() - new Date(aadhaarDOB).getFullYear() - (new Date(dod).getMonth() < new Date(aadhaarDOB).getMonth() || (new Date(dod).getMonth() === new Date(aadhaarDOB).getMonth() && new Date(dod).getDate() < new Date(aadhaarDOB).getDate()) ? 1 : 0);

          setisAadhaarVerified(true);
          // setApplicantName(first_name + last_name);
          // if (deathAge) {
          //   setdeathAge(deathAge);
          // }
          // setDob(moment().diff(aadhaarData[0].dob, "years"));
          // setBirthPlace(aadhaarData[0].co);
          // setPermanentAddress(aadhaarData[0].house_no + ',' + aadhaarData[0].street + ',' + aadhaarData[0].locality + ',' + aadhaarData[0].landmark + ',' + aadhaarData[0].village_city_town + ',' + aadhaarData[0].district + ',' + aadhaarData[0].sub_district + ',' + aadhaarData[0].state + ',' + aadhaarData[0].pincode);
          // setPermanentAddress(
          //   [
          //     aadhaarData[0].house_no,
          //     aadhaarData[0].street,
          //     aadhaarData[0].locality,
          //     aadhaarData[0].landmark,
          //     aadhaarData[0].village_city_town,
          //     aadhaarData[0].district,
          //     aadhaarData[0].sub_district,
          //     aadhaarData[0].state,
          //     aadhaarData[0].pincode
          //   ]
          //     .filter(Boolean) // Removes undefined, null, or empty values
          //     .join(', ') // Joins non-empty values with a comma and space
          // );

          // setPersonalFields((prev) => ({
          //   ...prev,
          //   first_name: aadhaarData[0].name,
          // }));
          // setDob(aadhaarData[0].dob && moment(aadhaarData[0].dob));

          // setPersonalFields((prev) => ({
          //   ...prev,
          //   gender:
          //     aadhaarData[0].gender === "M"
          //       ? 27
          //       : aadhaarData[0].gender === "F"
          //         ? 28
          //         : 29,
          // }));
          // }
        } else {
          showToast(
            "ERROR",
            `Your age is ${aadhaarDOB}. As per the KBOCWWB ACT you are not eligible for the registration`
          );
        }
      } else {
        setisAadhaarVerified(false);
        showToast("ERROR", "Aadhaar Verification failed, please try again. ");
      }
    } catch (error) {
      showToast("ERROR", "Something went wrong");
    }
  };




  const handleVerifyAadhaar = async () => {
    try {
      //Production Data

      const payLoad = {
        responseRedirectURL: `${BASE_URL}/profile/personal/${labourUserID}`,
      };

      // Local Production Data

      // const payLoad = {
      //   responseRedirectURL: `http://localhost:3000/profile/personal/${labourUserID}`,
      // };

      // DEV DATA
      //  const payLoad = {
      //    responseRedirectURL: `http://gcdmsdbac.hostg.in:5353/profile/personal/${labourUserID}`,
      //  };

      const response = await EKYCWEBHOOK(payLoad);

      const { token } = response.data;
      // const {token} = response.data.token;

      // window.open(
      //   `https://dbt.karnataka.gov.in/HSMService/EKYCService.aspx?key=0dac67ae-00bb-47fb-8c10-f5fc1776e91d&token=${token}`,
      //   '_self',
      // );
      // window.open(
      //   `https://dbt.karnataka.gov.in/HSMService/EKYCService.aspx?key=0dac67ae-00bb-47fb-8c10-f5fc1776e91d&token=${token}`,
      //   "_self"
      // );
      aadhaar();

    } catch (error) {
      showToast(
        "ERROR",
        "Something went wrong! Please try again after some time."
      );
    }
  };

  const verifySevaSindhu = async () => {
    // console.log("verifying seva sindhu");
    if (registrationNumber && referenceNumber) {
      setLoading(true);
      const payload = {
        regNo: registrationNumber,
        refNo: referenceNumber,
      };
      try {
        const { data } = await CheckUserAPIforBackLogFuneralAccidentDeath(payload);
        console.log("data", data);
        setLoading(false);
        setIsUserVerified(true);
        if (data?.length > 0) {
          // if (data[0]?.department_user_id !== deptUserID) {
          //   showToast(
          //     "ERROR",
          //     "Application cannot be submitted under this jurisdiction"
          //   );
          //   setIsUserVerified(false);
          //   setIsVerified(false);
          //   return;
          // }
          if (data[0]?.is_verified_continuation_of_pension) {
            showToast("ERROR", "Application has already been submited.");
            setIsUserVerified(false);
            setIsVerified(false);
            return;
          }
          // if (data[0]?.is_transferred === true) {
          setIsVerified(true);
          // setIsTransferred(data[0]?.is_transferred);
          // setMobileNumber(data[0]?.mobileno);
          // setDob(data[0]?.DOB);
          // setDod(data[0]?.DOD);
          // setStateName(data[0]?.state_name);
          // setDistrictName(data[0]?.district_name);
          // setTalukName(data[0]?.taluk_name);
          // setCityName(data[0]?.city_town_gram_panchayat_name);
          // setVillageName(data[0]?.village_area_name);

          // setPermanentAddress(data[0]?.permanent_address);
          // }
          setIsDataFound(false);
          // console.log("data", data[0]?.data[0], "******************");
          // console.log("data[0]?.data[0].field_value", data[0]?.data[0].field_value);

          // if (data[0]?.data[0].field == 'Cause of death') {
          //   setCauseofDeath(data[0]?.data[0].field_value);
          // } else if (data[0]?.data[0].field == 'Death Age') {
          //   setdeathAge(data[0]?.data[0].field_value);
          // } else if (data[0]?.data[0].field == 'Place of death') {
          //   setDeathPlace(data[0]?.data[0].field_value);
          // } else if (data[0]?.data[0].field == 'Date of death') {
          //   setDod(data[0]?.data[0].field_value);
          // } else if (data[0]?.data[0].field === 'Age at the time of death') {
          //   setApplicantAge(data[0]?.data[0].field_value);
          // } else if (data[0]?.data[0].field == 'Age of Applicant') {
          //   setApplicantName(data[0]?.data[0].field_value);
          // } else if (data[0]?.data[0].field == 'Permanent Address') {
          //   setPermanentAddress(data[0]?.data[0].field_value);
          // }

          const loop = data[0]?.data.map((item) => {
            if (item.field === "Cause of death") {
              setCauseofDeath(item.field_value);
              setCauseofDeathId(item.field_id);
            }
            if (item.field === "Place of death") {
              setDeathPlace(item.field_value);
              setDeathPlaceId(item.field_id);
            }
            if (item.field === "Date of death") {
              setDod(item.field_value);
              setDodId(item.field_id);
            }
            if (item.field === "Age at the time of death") {
              setdeathAge(item.field_value);
              setDeathAgeId(item.field_id);
            }
            if (item.field === "Age of Applicant") {
              setApplicantAge(item.field_value);
              setApplicantAgeId(item.field_id);
            }

            if (item.field === "Name of the Applicant") {
              setApplicantName(item.field_value);
              setApplicantNameId(item.field_id);
            }

            if (item.field === "Permanent Address") {
              setPermanentAddress(item.field_value);
              setPermanentAddressId(item.field_id);
            }

            if (item.field === "Relationship with Beneficiary") {
              setRelationship(item.field_value);
              setRelationshipId(item.field_id);
            }


          });
          setScheme_avail_id(data[0]?.scheme_availed_id);
          setLabour_user_id(data[0]?.labour_user_id);
          setDepartment_user_id(data[0]?.department_user_id);
          // console.log("loop", loop);
          // setCauseofDeath(data[0]?.data[0].causeofDeath);
          // setdeathAge(data[0]?.data[0].deathAge);
          // setDeathPlace(data[0]?.data[0].deathPlace);


          // setCircleName(data[0]?.circle_name);
          // setApplicantName(data[0]?.applicantname);
          // setBirthPlace(data[0]?.deathPlace);
          // setCauseofDeath(data[0]?.data[0].causeofDeath);
          // setdeathAge(data[0]?.deathAge);
          // setApplicantAge(data[0]?.applicantAge);
          callGetLocationDetails(data[0]?.submissionlocation);
          setRegistrationDate(data[0]?.registrationdate);
          // setValidityFromDate(data[0]?.validity_fromdate);
          // setValidityToDate(data[0]?.validity_todate);
          setSevaSindhuID(data[0]?.id);
          // setGender(data[0]?.gender);
          // setCircleID(data[0]?.circle_id);
        }
      } catch (error) {
        setLoading(false);
        if (error?.response?.data?.errors?.msg) {
          showToast("ERROR", `${error?.response?.data?.errors?.msg}`);
        }
        showToast("ERROR", "Something went wrong");
      }
    } else {
      showToast("ERROR", "Please enter registration and reference number");
    }
  };

  const verifyMobileNumber = async () => {
    if (mobileNumber) {
      setNumberLoading(true);

      const pensionDetails = await CheckUserByMobileNoAPI({
        mobile: mobileNumber,
      });

      if (pensionDetails?.data && pensionDetails?.data?.length > 0) {
        setNumberLoading(false);
        setIsMobileVerified(false);
        setIsVerified(false);
        showToast("ERROR", "The Mobile Number is Already Manually Registered");
        return;
      }

      axios
        .get(`${SERVER}/user/${mobileNumber}`)
        .then((res) => {
          setNumberLoading(false);
          setIsMobileVerified(false);
          setIsVerified(false);
          showToast("ERROR", "Mobile Number Already Registered, Please Login!");
        })
        .catch((err) => {
          if (err.response.data.error) {
            setNumberLoading(false);
            setIsMobileVerified(true);
            setIsVerified(true);
          }
        });
    } else {
      showToast("ERROR", "Please enter mobile number");
    }
  };

  const handleReset = () => {
    setMobileNumber("");
    setApplicantName("");
    setDeathPlace("");
    setCauseofDeath("");
    setdeathAge("");
    setApplicantAge("");
    setRegistrationNumber("");
    setReferenceNumber("");
    setDod(null);
    setPermanentAddress("");
    // setPensionAvaliDate(null);
    // setPensionRecievedDate(null);

    setnomineeaadhaarCardDoc([]);
    setLabourCardDoc([]);
    setnomineeaadhaar([]);
    setdeathcertificateDoc([]);
    setbenificiaryaadharDoc([]);
    setIsVerified(false);
    setIsMobileVerified(false);
    setIsUserVerified(false);
    setisAadhaarVerified(false);
    setIsSubmitSuccess(false);
  };
  /* FILE UPLOAD CHANGE */
  const handleFileChange = (ev) => {
    if (ev.target.name === "nomineeaadhaar") {
      if (
        ev.target.files[0].type === "image/png" ||
        ev.target.files[0].type === "image/jpeg" ||
        ev.target.files[0].type === "image/jpg" ||
        ev.target.files[0].type === "application/pdf"
      ) {
        if (ev.target.files[0].size < 2000001) {
          const newFiles = [...nomineeaadhaarCardDoc, ...ev.target.files];
          setnomineeaadhaarCardDoc(newFiles);
        } else {
          showToast("ERROR", "Please upload file size less than 2MB.");
        }
      } else {
        showToast("ERROR", "Please upload file in PNG/JPEG or PDF");
      }
    } else if (ev.target.name === "labourPhoto") {
      if (
        ev.target.files[0].type === "image/png" ||
        ev.target.files[0].type === "image/jpeg" ||
        ev.target.files[0].type === "image/jpg" ||
        ev.target.files[0].type === "application/pdf"
      ) {
        if (ev.target.files[0].size < 2000001) {
          const newFiles = [...nomineeaadhaar, ...ev.target.files];
          setnomineeaadhaar(newFiles);
        } else {
          showToast("ERROR", "Please upload file size less than 2MB.");
        }
      } else {
        showToast("ERROR", "Please upload file in PNG/JPEG or PDF");
      }
    } else if (ev.target.name === "labourCard") {
      if (
        ev.target.files[0].type === "image/png" ||
        ev.target.files[0].type === "image/jpeg" ||
        ev.target.files[0].type === "image/jpg" ||
        ev.target.files[0].type === "application/pdf"
      ) {
        if (ev.target.files[0].size < 2000001) {
          const newFiles = [...labourCardDoc, ...ev.target.files];
          setLabourCardDoc(newFiles);
        } else {
          showToast("ERROR", "Please upload file size less than 2MB.");
        }
      } else {
        showToast("ERROR", "Please upload file in PNG/JPEG or PDF");
      }
    } else if (ev.target.name === "deathcertificate") {
      if (
        ev.target.files[0].type === "image/png" ||
        ev.target.files[0].type === "image/jpeg" ||
        ev.target.files[0].type === "image/jpg" ||
        ev.target.files[0].type === "application/pdf"
      ) {
        if (ev.target.files[0].size < 2000001) {
          const newFiles = [...deathcertificateDoc, ...ev.target.files];
          setdeathcertificateDoc(newFiles);
        } else {
          showToast("ERROR", "Please upload file size less than 2MB.");
        }
      } else {
        showToast("ERROR", "Please upload file in PNG/JPEG or PDF");
      }
    } else if (ev.target.name === "benificiaryaadhar") {
      if (
        ev.target.files[0].type === "image/png" ||
        ev.target.files[0].type === "image/jpeg" ||
        ev.target.files[0].type === "image/jpg" ||
        ev.target.files[0].type === "application/pdf"
      ) {
        if (ev.target.files[0].size < 2000001) {
          const newFiles = [...benificiaryaadharDoc, ...ev.target.files];
          setbenificiaryaadharDoc(newFiles);
        } else {
          showToast("ERROR", "Please upload file size less than 2MB.");
        }
      } else {
        showToast("ERROR", "Please upload file in PNG/JPEG or PDF");
      }
    }


  };

  const handleRemoveFile = (index, documentType) => {
    if (documentType === "labourCard") {
      const newFiles = [...labourCardDoc];
      newFiles.splice(index, 1);
      setLabourCardDoc(newFiles);
      labourCardFileRef.current.value = "";
    } else if (documentType === "aadhaarCard") {
      const newFiles = [...nomineeaadhaarCardDoc];
      newFiles.splice(index, 1);
      setnomineeaadhaarCardDoc(newFiles);
      aadhaarCardFileRef.current.value = "";
    } else if (documentType === "nomineeaadhaar") {
      const newFiles = [...nomineeaadhaar];
      newFiles.splice(index, 1);
      setnomineeaadhaar(newFiles);
      nomineeaadhaarFileRef.current.value = "";
    } else if (documentType === "deathcertificate") {
      const newFiles = [...deathcertificateDoc];
      newFiles.splice(index, 1);
      setdeathcertificateDoc(newFiles);
      deathcertificate.current.value = "";
    } else if (documentType === "benificiaryaadhar") {
      const newFiles = [...benificiaryaadharDoc];
      newFiles.splice(index, 1);
      setbenificiaryaadharDoc(newFiles);
      benificiaryaadhar.current.value = "";
    }
  };

  const updateFuneralData = async (
    labourCardDoc,
    nomineeaadhaarCardDoc,
    benificiaryaadharDoc,
    deathcertificateDoc
  ) => {
    const payload = {
      boardID: 1,
      departmentUserID: +department_user_id,
      sevasindhuID: +sevaSindhuID,
      mobile: +mobileNumber,
      regNo: registrationNumber,
      refNo: referenceNumber,
      registrationDate: moment(registrationDate).format("YYYY-MM-DD"),
      applicantName: applicantName,
      dod: moment(dod).format("YYYY-MM-DD"),
      causeofDeath: causeofDeath,
      deathAge: +deathAge,
      deathPlace: deathPlace,
      relationship: relationship,
      applicantAge: +applicantAge,
      scheme_avail_id: +scheme_avail_id,
      labour_user_id: +labour_user_id,
      permanentAddress: permanentAddress,

      placeofDeathId: deathPlaceId,
      causeofDeathId: causeofDeathId,
      relationshipId: relationshipId,
      applicantAgeId: applicantAgeId,
      applicantNameId: applicantNameId,
      permanentAddressId: permanentAddressId,
      registrationDateId: registrationDateId,
      dodId: dodId,
      deathAgeId: deathAgeId,
      mobileNumberId: mobileNumberId,
      referenceNumberId: referenceNumberId,



      nomineeaadhaar: nomineeaadhaar,
      // aadharCard: [],
      labourCard: labourCardDoc,
      deathCertificate: deathcertificateDoc,
      benificiaryaadhar: benificiaryaadharDoc,
      nomineeaadhaarCardDoc: nomineeaadhaarCardDoc
    };

    try {
      console.log(payload, 'payload**************')
      const response = await UpdatebacklogFuneralAccidentDeath(payload);
      // if (response.success) {
      setSubmitLoader(false);
      setIsSubmitSuccess(true);
      showToast(
        "SUCCESS",
        "Application Submitted successfully."
        // For further process,kindly generate the E-card in the 
        // citizen login to allow for continuation of pension"
      );
      // }

    } catch (err) {
      setSubmitLoader(false);
      if (err?.response?.data?.errors?.msg) {
        showToast("ERROR", `${err?.response?.data?.errors?.msg}`);
      } else {
        showToast("ERROR", "Something went wrong");
      }
    }
  };

  const handleSubmitData = async (ev) => {
    if (
      mobileNumber &&
      applicantName &&
      dod &&
      permanentAddress &&
      causeofDeath &&
      applicantAge &&
      deathPlace &&
      deathAge &&
      relationship &&

      labourCardDoc &&
      labourCardDoc.length > 0 &&
      nomineeaadhaarCardDoc &&
      nomineeaadhaarCardDoc.length > 0 &&
      deathcertificateDoc &&
      deathcertificateDoc.length > 0 &&
      benificiaryaadharDoc &&
      benificiaryaadharDoc.length > 0
    ) {
      setSubmitLoader(true);
      console.log(labourCardDoc, 'labourCardDoc')
      const uploadFiles = await backlogFuneralAccidentDeathFileUpload(
        labourCardDoc,
        nomineeaadhaarCardDoc,
        deathcertificateDoc,
        benificiaryaadharDoc
      );

      if (uploadFiles.success) {
        updateFuneralData(
          uploadFiles.labourCardDoc,
          uploadFiles.nomineeaadhaarCardDoc,
          uploadFiles.benificiaryaadharDoc,
          uploadFiles.deathcertificateDoc,
          causeofDeath,
          deathAge,
          deathPlace,
          dod,
          relationship,
          applicantAge,
          mobileNumber,
          applicantName,
          permanentAddress,
          sevaSindhuID,
          registrationNumber,
          referenceNumber,
          registrationDate,

        );
      } else {
        showToast("ERROR", "Please Upload documents");
      }
    } else {
      showToast("ERROR", "Please fill all the mandatory details");
    }



    // console.log(mobileNumber, '**************')
    // const response = await ContinuationPensionFileUpload(
    //   labourCardDoc,
    //   nomineeaadhaarCardDoc,
    //   benificiaryaadharDoc,
    //   deathcertificateDoc
    // );

    // if (response.success) {
    //   updateFuneralData(
    //     response.labourCardDoc,
    //     response.nomineeaadhaar,
    //     response.benificiaryaadhar,
    //     response.deathcertificate,
    //   );

    // } else {
    //   showToast("ERROR", "Please Upload documents");
    // }
    //   } else {
    //     showToast("ERROR", "Please fill all the mandatory details");
    // }
  };

  const callGetLocationDetails = async (submissionLocation) => {
    try {
      const payLoad = {
        ref_seva_sindhu_circle_id: +submissionLocation,
      };
      const response = await getSubmissionLocationAPI(payLoad);
      if (response.success) {
        setCircleData(response.data);
      }
    } catch (error) {
      showToast("ERROR", "Something went wrong");
    }
  };

  // useEffect(() => {
  //   if ((isTransferred === null || isTransferred === false) && circleData) {
  //     console.log('called');
  //     const _districtData = districtData(circleData && circleData);

  //     setDistrict(_districtData);
  //     const _talukData = talukData(circleData && circleData);
  //     setTaluk(_talukData);
  //     const _villageData = villageData(circleData && circleData);
  //     setVillage(_villageData);

  //     const _cityData = cityData(circleData && circleData);

  //     if (talukID) {
  //       const result = _cityData.filter((_data) => _data.taluk_id === talukID);
  //       setCity(result);
  //     }
  //     if (cityID) {
  //       const result = _villageData.filter(
  //         (_data) => _data.gram_panchayat_city_town_id === cityID,
  //       );

  //       setVillage(result);
  //     }
  //   }
  // }, [isTransferred, circleData, talukID, cityID]);

  return (
    <>
      {submitLoader && <SimpleBackdrop />}

      <div>
        <div className='root'>
          <Row className='top-div '>
            <Row className='topbar-row'>
              <Col xs={12} md={4} lg={6} className='top-bar-col'>
                <div className='logo-div-profile'>
                  <a href='/admin/dashboard/20'>
                    <img id='logo' src={logo} alt='...' className='logo-img' />
                  </a>
                  <h1 className='logo-text'>
                    {/* Karnataka Building & Other Construction<br />Workers Welfare Board (KBOCWWB) */}
                    <Translate value='header.title' /> <br />
                    <Translate value='header.sub' />
                  </h1>
                </div>
              </Col>
            </Row>
          </Row>
        </div>
        <div className='d-flex justify-content-start align-items-start ps-3 mt-3'>
          <Link to={"/admin/dashboard/20"}>
            <img src={backIcon} alt='' />
          </Link>
        </div>
        <div className='d-flex justify-content-start align-items-center ms-5 gap-5'>
          {!isSubmitSuccess && (
            <div className='d-flex justify-content-start align-items-center gap-5'>
              <Col md={6}>
                <TextField
                  className='w-100'
                  id='standard-basic'
                  label='Registration number'
                  variant='standard'
                  value={registrationNumber}
                  disabled={isUserVerified}
                  // inputProps={{ minLength: 10, maxLength: 10 }}
                  onChange={(ev) => setRegistrationNumber(ev.target.value)}
                />
              </Col>
              <Col md={6}>
                <TextField
                  className='w-100'
                  id='standard-basic'
                  label='Reference number'
                  variant='standard'
                  value={referenceNumber}
                  disabled={isUserVerified}
                  // inputProps={{ minLength: 10, maxLength: 10 }}
                  onChange={(ev) => setReferenceNumber(ev.target.value)}
                />
              </Col>
              <Col className='mt-3 d-flex'>
                {loading ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Button
                    disabled={
                      !registrationNumber || !referenceNumber || isUserVerified
                    }
                    onClick={verifySevaSindhu}
                    variant={isUserVerified ? "success" : "primary"}
                    className='mx-3'
                  >
                    {isUserVerified ? "Verified" : "Verify"}
                  </Button>
                )}

                <Button
                  disabled={!registrationNumber || !referenceNumber}
                  onClick={handleReset}
                  variant='primary'
                >
                  {"Reset"}
                </Button>
              </Col>
            </div>
          )}
        </div>



        {isUserVerified && (
          <div className='d-flex justify-content-start align-items-center ms-5 gap-5'>
            {!isSubmitSuccess && (
              <div className='d-flex justify-content-start align-items-center gap-5 w-100'>
                <Col md={3}>
                  <TextField
                    className='w-100'
                    id='standard-basic'
                    label='Nominee Mobile number'
                    variant='standard'
                    value={mobileNumber}
                    disabled={isMobileVerified || isTransferred}
                    inputProps={{ minLength: 10, maxLength: 10 }}
                    onChange={(ev) =>
                      setMobileNumber(ev.target.value.replace(/[^0-9]/g, ""))
                    }
                  />
                </Col>
                {!isTransferred && (
                  <Col md={3} className='mt-3 d-flex'>
                    {numberLoading ? (
                      <Box sx={{ display: "flex" }}>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <Button
                        disabled={
                          (mobileNumber && mobileNumber.length < 10) ||
                          isMobileVerified
                        }
                        onClick={verifyMobileNumber}
                        variant={isMobileVerified ? "success" : "primary"}
                        className=''
                      >
                        {isMobileVerified ? "Verified" : "Verify"}
                      </Button>
                    )}

                    {/* <Button
                      disabled={!mobileNumber}
                      onClick={handleReset}
                      variant="primary"
                    >
                      {'Reset'}
                    </Button> */}
                  </Col>
                )}
              </div>
            )}
          </div>
        )}


        {isUserVerified && (
          <div className='d-flex justify-content-start align-items-center ms-5 gap-5'>
            {!isSubmitSuccess && (
              <div className='d-flex justify-content-start align-items-center gap-5 w-100'>
                <Col md={3}>
                  <TextField
                    className='w-100'
                    id='standard-basic'
                    label='Verify Nominee Aadhar No'
                    variant='standard'
                    value={nomineeaadhaar}
                    disabled={isAadhaarVerified}
                    inputProps={{ minLength: 12, maxLength: 12 }}
                    onChange={(ev) =>
                      setnomineeaadhaar(ev.target.value.replace(/[^0-9]/g, ""))
                    }
                  />
                </Col>
                {!isTransferred && (
                  <Col md={3} className='mt-3 d-flex'>
                    {numberLoading ? (
                      <Box sx={{ display: "flex" }}>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <Button
                        disabled={
                          (nomineeaadhaar && nomineeaadhaar.length < 12) ||
                          isAadhaarVerified
                        }
                        onClick={handleVerifyAadhaar}
                        variant={isAadhaarVerified ? "success" : "primary"}
                        className=''
                      >
                        {isAadhaarVerified ? "Verified" : "Verify"}
                      </Button>
                    )}

                    {/* <Button
                      disabled={!mobileNumber}
                      onClick={handleReset}
                      variant="primary"
                    >
                      {'Reset'}
                    </Button> */}
                  </Col>
                )}
              </div>
            )}
          </div>
        )}

        <div className='d-flex justify-content-center align-items-center px-3 py-2'>
          <div>
            {isSubmitSuccess ? (
              <>
                <div className='d-flex justify-content-center align-items-center flex-column'>
                  <img width={80} height={80} src={successIcon} alt='...' />
                  <h2 className='my-3'>Successfully Submitted</h2>
                  <Button variant='primary' color='info' onClick={handleReset}>
                    Click here to submit another application
                  </Button>
                </div>
              </>
            ) : null}

            <Row className='px-5 py-2'>
              {/* <Col xs={12} md={4} className="mt-3"></Col> */}

              <Row className='mt-3'>
                {!isSubmitSuccess && isVerified ? (
                  <>
                    <Col xs={12} md={4}>
                      <Required
                        title={"Name of the Applicant"}
                      />
                      <TextField
                        className='w-100'
                        id='standard-basic'
                        // label="Name of the Beneficiary"
                        variant='standard'
                        disabled={isDataFound}
                        value={applicantName}
                        onChange={(ev) => setApplicantName(ev.target.value)}
                      />
                    </Col>

                    <Col xs={12} md={4}>
                      <Required
                        title={"Place of Death"}
                      />
                      <TextField
                        className='w-100'
                        id='standard-basic'
                        variant='standard'
                        disabled={isDataFound}
                        value={deathPlace}
                        onChange={(ev) => setDeathPlace(ev.target.value)}
                      />
                    </Col>

                    <Col xs={12} md={4}>
                      <Required
                        title={"Cause of Death"}
                      />
                      <TextField
                        className='w-100'
                        id='standard-basic'
                        variant='standard'
                        disabled={isDataFound}
                        value={causeofDeath}
                        onChange={(ev) => setCauseofDeath(ev.target.value)}
                      />
                    </Col>



                    <Col xs={12} md={4}>
                      <Required title={"Date of Death"} />
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          className='w-100 '
                          // className="datepicker"
                          // margin="normal"

                          format='DD/MM/YYYY'

                          maxDate={
                            // !isTransferred &&
                            moment(Date())
                          }
                          value={dod}
                          onChange={(date) => {
                            setDod(date);
                          }}
                          placeholder='DOD'
                          disabled={isTransferred}
                        />
                      </MuiPickersUtilsProvider>
                    </Col>


                    <Col xs={12} md={4}>
                      <Required
                        title={"Age at the time of Death"}
                      />
                      <TextField
                        className='w-100'
                        id='standard-basic'
                        // label="Name of the Beneficiary"
                        variant='standard'
                        disabled={isDataFound}
                        value={deathAge}
                        onChange={(ev) => setdeathAge(ev.target.value)}
                      />
                    </Col>

                    <Col xs={12} md={4}>
                      <Required
                        title={"Age of Nominee"}
                      />
                      <TextField
                        className='w-100'
                        id='standard-basic'
                        // label="Name of the Beneficiary"
                        variant='standard'
                        disabled={isDataFound}
                        value={applicantAge}
                        onChange={(ev) => setApplicantAge(ev.target.value)}
                      />
                    </Col>

                    <Col xs={12} md={4}>
                      <Required title={"Permanent address"} />

                      <TextareaAutosize
                        id='standard-basic'
                        className='w-100'
                        variant='standard'
                        value={permanentAddress}
                        disabled={isTransferred}
                        onChange={(ev) => setPermanentAddress(ev.target.value)}
                      />
                    </Col>

                    {/* •	Relationship with Beneficiary(Dropdown). */}

                    <Col xs={12} md={4}>
                      <Required
                        title={"Relationship with Beneficiary"}
                      />
                      <Select
                        className='w-100'
                        value={relationship}
                        onChange={(ev) => setRelationship(ev.target.value)}
                      >
                        <MenuItem value={relationship}>
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"Father"}>Father</MenuItem>
                        <MenuItem value={"Mother"}>Mother</MenuItem>
                        <MenuItem value={"Husband"}>Husband</MenuItem>
                        <MenuItem value={"Wife"}>Wife</MenuItem>
                        <MenuItem value={"Son"}>Son</MenuItem>
                        <MenuItem value={"Daughter"}>Daughter</MenuItem>
                        <MenuItem value={"Brother"}>Brother</MenuItem>
                        <MenuItem value={"Sister"}>Sister</MenuItem>
                        <MenuItem value={"Other"}>Other</MenuItem>
                      </Select>
                    </Col>


                    <div className=''>
                      <Row>
                        <Col md={6}>
                          <div className=''>
                            <Required
                              className='mt-3 mb-2 fs-5'
                              title={<Translate value='Labour Card' />}
                            />
                            {labourCardDoc && labourCardDoc.length > 0 ? (
                              labourCardDoc.map((file, index) => (
                                <FormControl
                                  variant='outlined'
                                  fullWidth
                                  className='w-75'
                                >
                                  <TextField
                                    style={{ border: "2px solid grey" }}
                                    variant='outlined'
                                    placeholder='Click Browse to Upload'
                                    // name="mail_id"
                                    disabled
                                    value={file.name}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position='end'>
                                          {
                                            <IconButton
                                              onClick={() =>
                                                handleRemoveFile(
                                                  index,
                                                  "labourCard"
                                                )
                                              }
                                            >
                                              <CancelIcon />
                                            </IconButton>
                                          }
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </FormControl>
                              ))
                            ) : (
                              <FormControl fullWidth className='w-75'>
                                <TextField
                                  variant='outlined'
                                  style={{ border: "2px solid grey" }}
                                  placeholder='Click Browse to Upload'
                                  disabled
                                />
                              </FormControl>
                            )}

                            <input
                              type='file'
                              ref={labourCardFileRef}
                              onChange={handleFileChange}
                              multiple
                              hidden
                              name='labourCard'
                            />
                            <Button
                              className='mx-3 py-2'
                              onClick={() => labourCardFileRef.current.click()}
                            >
                              <FolderIcon />
                              Browse
                            </Button>
                          </div>
                        </Col>

                        <Col md={6}>
                          <div className=''>
                            <Required
                              className='mt-3 mb-2 fs-5'
                              title={<Translate value='Nominee Aadhar' />}
                            />
                            {nomineeaadhaarCardDoc && nomineeaadhaarCardDoc.length > 0 ? (
                              nomineeaadhaarCardDoc.map((file, index) => (
                                <FormControl
                                  variant='outlined'
                                  fullWidth
                                  className='w-75'
                                >
                                  <TextField
                                    style={{ border: "2px solid grey" }}
                                    variant='outlined'
                                    placeholder='Click Browse to Upload'
                                    // name="mail_id"
                                    disabled
                                    value={file.name}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position='end'>
                                          {
                                            <IconButton
                                              onClick={() =>
                                                handleRemoveFile(
                                                  index,
                                                  "nomineeaadhaar"
                                                )
                                              }
                                            >
                                              <CancelIcon />
                                            </IconButton>
                                          }
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </FormControl>
                              ))
                            ) : (
                              <FormControl fullWidth className='w-75'>
                                <TextField
                                  variant='outlined'
                                  style={{ border: "2px solid grey" }}
                                  placeholder='Click Browse to Upload'
                                  disabled
                                />
                              </FormControl>
                            )}

                            <input
                              type='file'
                              ref={nomineeaadhaarFileRef}
                              onChange={handleFileChange}
                              multiple
                              hidden
                              name='nomineeaadhaar'
                            />
                            <Button
                              className='mx-3 py-2'
                              onClick={() => nomineeaadhaarFileRef.current.click()}
                            >
                              <FolderIcon />
                              Browse
                            </Button>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className=''>
                            <Required
                              className='mt-3 mb-2 fs-5'
                              title={
                                <Translate value='Death Certificate' />
                              }
                            />
                            {deathcertificateDoc && deathcertificateDoc.length > 0 ? (
                              deathcertificateDoc.map((file, index) => (
                                <FormControl
                                  variant='outlined'
                                  fullWidth
                                  className='w-75'
                                >
                                  <TextField
                                    style={{ border: "2px solid grey" }}
                                    variant='outlined'
                                    placeholder='Click Browse to Upload'
                                    // name="mail_id"
                                    disabled
                                    value={file.name}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position='end'>
                                          {
                                            <IconButton
                                              onClick={() =>
                                                handleRemoveFile(
                                                  index,
                                                  "deathcertificate"
                                                )
                                              }
                                            >
                                              <CancelIcon />
                                            </IconButton>
                                          }
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </FormControl>
                              ))
                            ) : (
                              <FormControl fullWidth className='w-75'>
                                <TextField
                                  variant='outlined'
                                  style={{ border: "2px solid grey" }}
                                  placeholder='Click Browse to Upload'
                                  disabled
                                />
                              </FormControl>
                            )}

                            <input
                              type='file'
                              ref={deathcertificate}
                              onChange={handleFileChange}
                              multiple
                              hidden
                              name='deathcertificate'
                            />
                            <Button
                              className='mx-3 py-2'
                              onClick={() => deathcertificate.current.click()}
                            >
                              <FolderIcon />
                              Browse
                            </Button>
                          </div>
                        </Col>




                        <Col md={6}>
                          <div className=''>
                            <Required
                              className='mt-3 mb-2 fs-5'
                              title={
                                <Translate value='Benificiary Aadhar Number' />
                              }
                            />
                            {benificiaryaadharDoc && benificiaryaadharDoc.length > 0 ? (
                              benificiaryaadharDoc.map((file, index) => (
                                <FormControl
                                  variant='outlined'
                                  fullWidth
                                  className='w-75'
                                >
                                  <TextField
                                    style={{ border: "2px solid grey" }}
                                    variant='outlined'
                                    placeholder='Click Browse to Upload'
                                    // name="mail_id"
                                    disabled
                                    value={file.name}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position='end'>
                                          {
                                            <IconButton
                                              onClick={() =>
                                                handleRemoveFile(
                                                  index,
                                                  "benificiaryaadhar"
                                                )
                                              }
                                            >
                                              <CancelIcon />
                                            </IconButton>
                                          }
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </FormControl>
                              ))
                            ) : (
                              <FormControl fullWidth className='w-75'>
                                <TextField
                                  variant='outlined'
                                  style={{ border: "2px solid grey" }}
                                  placeholder='Click Browse to Upload'
                                  disabled
                                />
                              </FormControl>
                            )}

                            <input
                              type='file'
                              ref={benificiaryaadhar}
                              onChange={handleFileChange}
                              multiple
                              hidden
                              name='benificiaryaadhar'
                            />
                            <Button
                              className='mx-3 py-2'
                              onClick={() => benificiaryaadhar.current.click()}
                            >
                              <FolderIcon />
                              Browse
                            </Button>
                          </div>
                        </Col>



                      </Row>
                    </div>
                    <Col xs={12} md={6} className='w-100 text-center'>
                      <p className='text-danger font-weight-bolder fs-5 my-4'>
                        Note: Supported file format is JPG/JPEG/PNG, PDF and
                        maximum file size is 2 MB.
                      </p>
                    </Col>
                  </>
                ) : null}
              </Row>

              {!isSubmitSuccess && isVerified ? (
                <Row className='mt-5 d-flex justify-content-end w-100'>
                  <Col
                    xs={12}
                    md={4}
                    className='d-flex justify-content-end align-items-center gap-2'
                  >
                    <Button className='' variant='danger' size='lg'>
                      <Link
                        style={{ color: "inherit" }}
                        to={"/admin/dashboard/20"}
                      >
                        Back
                      </Link>
                    </Button>

                    <Button
                      onClick={handleSubmitData}
                      className=''
                      variant='success'
                      size='lg'
                      disabled={submitLoader}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              ) : null}
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default BacklogFormFuneral;
