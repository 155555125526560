import {
  Box,
  CircularProgress,
  LinearProgress,
  Skeleton,
  Typography,
} from '@mui/material';
import React from 'react';

export default function Loader() {
  return <Skeleton variant="rectangular" width={210} height={118} />;
}
export function CircularLoader() {
  return <CircularProgress />;
}
export function LinearLoader() {
  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
  );
}
