import React, { lazy, useContext, useState } from 'react';
import Header from './Header';
import { Col, Row } from 'react-bootstrap';
import accident from 'assets/images/Accident-01.svg';
import Medical2 from 'assets/images/Medical assistance-01.svg';
import { SchemesList } from './schemesList';
import back from 'assets/images/Icon awesome-arrow-circle-left.svg';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import language from 'assets/images/translate (1).svg';
import { setLocaleWithFallback } from 'store/actions/user.actions';
import search from 'assets/images/Icon ionic-ios-search.svg';
import MarriageAssistance from 'assets/images/marriage assistance-01.svg';
// import usericon from "assets/images/Icon awesome-user-circle.svg";
// import ambedkar from "assets/images/ambedkar.svg";
// import ashadeepa from "assets/images/ashadeepa.svg";
// import medicalcamp from "assets/images/medicalcamp.svg";
// import sports from "assets/images/sports.svg";
import disability from 'assets/images/Continuation of disability pension-01.svg';
import continuationPension from 'assets/images/Continuation-of-pension-01.svg';
// import closeIcon from "assets/images/close-01.svg";
import pension from 'assets/images/Disability Pension-01.svg';
// import loginlogo from "assets/images/Admin SVG.svg";
// import education from "assets/images/Education Assitance-01.svg";
// import bmtc from "assets/images/DUlicate id card-01.svg";
// import pre from "assets/images/Pre Coaching (UPSC and KPSC) Application 2.svg";
// import Funeral from 'assets/images/Funeral-01.svg'
import Funeral from 'assets/images/Funeral and EX -Gratia.png';
import Group from 'assets/images/Group 70.svg';
// import ksrtc from "assets/images/ksrtc bus pass-01.svg";
// import LPG from "assets/images/LPG CONNECTION-01.svg";
import Maga from 'assets/images/Maga-01.svg';
// import newpension from "assets/images/newpension.png";
// import accident_education from "assets/images/accident_education.png";
// import pmyogiman from "assets/images/pmyogiman.png";
// import registration from "assets/images/Continuation of existing registration-01 (1).svg";
import medical from 'assets/images/medical assisatance.svg';
// import ent from "assets/images/ENT AND WHEELCHAIR-01.svg";
// import UPSC from "assets/images/Pre Coaching (UPSC and KPSC) Application 2.svg";
// import pregnant from 'assets/images/pregnant woman-01.svg'
import pregnant from 'assets/images/images-02.svg';
// import duplicate from "assets/images/DUlicate id card-01.svg";
// import Tool from "assets/images/shrama samathya Tool kit-01.svg";
import Header2 from './Header2';
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";
// import Slide from "@mui/material/Slide";
// import Button2 from "@mui/material/Button";
// import EducationStatus from "components/schemes/EducationStatus";
import SuspenseProvider from 'components/context/SuspenseProvider';
import { DataContext } from 'context/config';
const SchemeStatusModal = lazy(
  () => import('components/adminPortal/SchemeStatus/SchemeStatusModal'),
);
const ApplicationStatusModal = lazy(
  () => import('components/adminPortal/SchemeStatus/ApplicationStatusModal'),
);

const modalIndex = {
  1: 'registration',
  2: 'renewal',
  3: 'scheme',
};
const SchemesHomePage = (props) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const i18nlang = useSelector((state) => state.i18n);

  const { data: configData } = useContext(DataContext);

  const [open, setOpen] = React.useState(0);

  const [allValues, setAllValues] = useState({
    language: users.profile_details.language,
    users: users.profile_details.users,
  });
  const handleChange1 = (e) => {
    console.log('kkkkk', e.target.value);
    if (e.target.value === 'ka') {
      setAllValues({
        ...allValues,
        descriptionsKannada: true,
        [e.target.name]: e.target.value,
      });
    } else {
      setAllValues({
        ...allValues,
        descriptionsKannada: false,
        [e.target.name]: e.target.value,
      });
    }

    if (e.target.name === 'language')
      dispatch(setLocaleWithFallback(e.target.value));
  };
  const schemesData = {
    accident: 'accident',
  };

  const handleClickOpen = (value) => {
    setOpen(value);
  };

  const handleClose = () => {
    setOpen(0);
  };

  return (
    <div
      className=""
      onClick={() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }}
    >
      <Header2 lang={allValues.language} />
      <div className="d-flex justify-content-end">
        <Col
          xs={12}
          md={4}
          lg={3}
          className="d-flex justify-content-end mt-2 me-2"
        >
          <Select
            className="select-language"
            style={{ width: '100%' }}
            variant="outlined"
            labelId="demo-simple-select-required-label"
            value={allValues.language}
            name="language"
            displayEmpty
            onChange={handleChange1}
          >
            <MenuItem value="">
              <ListItemIcon>
                <img alt="..." src={language} className="language-img" />
              </ListItemIcon>
              <ListItemText primary="Select Language" />
            </MenuItem>
            {/* <MenuItem value="en">English</MenuItem>
                                        <MenuItem value="ka">Kannada</MenuItem> */}
            <MenuItem value="en">
              <ListItemText primary="English" />
            </MenuItem>
            <MenuItem value="ka">
              <ListItemText primary="ಕನ್ನಡ" />
            </MenuItem>
          </Select>
        </Col>
      </div>
      <Row className="container-fluid mt-3">
        <div className="mb-5 d-flex justify-content-center gap-2">
          <>
            <Button
              variant="outlined"
              color="error"
              onClick={() => handleClickOpen(2)}
              hidden={
                configData && configData?.disbleLabourCardStatus == 'Y'
                  ? true
                  : false
              }
            >
              Click Here to View Labour Card Status
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => handleClickOpen(3)}
              hidden={
                configData && configData?.disbleDBTSchemeStatus == 'Y'
                  ? true
                  : false
              }
            >
              Click Here to View Application DBT Status
            </Button>
            {/* <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle className="d-flex justify-content-center align-items-center text">
                {"Education Assistance Status"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  <EducationStatus />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Close</Button>
              </DialogActions>
            </Dialog> */}
          </>
        </div>
        <div className="schemes-home-div container-fluid m-auto ps-4">
          <h3 className="schemesHome-title container-fluid">
            <Translate value="schemesBoardTitle1" />
          </h3>
          <p className="schemes-instructions container-fluid">
            <Translate value="kbocwwbcontent1" />
          </p>
          <p className="schemes-instructions container-fluid">
            <Translate value="kbocwwbcontent2" />
          </p>
          <p className="schemes-instructions container-fluid">
            <Translate value="kbocwwbcontent3" />
          </p>
          {/* <p className='schemes-instructions container-fluid'>
        <Translate value='kbocwwbcontent4'/> 
        </p> */}
          <div
            className="my-5 d-flex justify-content-center flex-wrap"
            style={{ gap: 30 }}
          >
            <div>
              <Link to={'/login'}>
                <div className="schemesHome-title container-fluid d-flex align-items-center justify-content-center">
                  <button className="login-button2">
                    Register as Construction Worker / Login
                  </button>
                </div>
              </Link>
            </div>
            <br />
            {configData && configData?.showSurvey == 'N' ? null : (
              <div>
                <Link to={'/survey'}>
                  <div className="schemesHome-title container-fluid d-flex align-items-center justify-content-center">
                    <button className="login-button2">
                      Verify Date Of Birth Mismatch with Respect to Aadhaar and
                      Labour Card (Only for people who are register on 2019 or
                      before)
                    </button>
                  </div>
                </Link>
              </div>
            )}
          </div>
          <h3 className="schemesHome-title container-fluid">
            <Translate value="schemeTranslate" />
          </h3>
        </div>
      </Row>
      <div className="schemes-list-div">
        <div className="container-fluid">
          <Row className="">
            <Col
              xs={12}
              sm={4}
              md={4}
              className="mapping-cards-col schemes"
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}
            >
              <Link to="/accident">
                <div className="mapping-card schemes">
                  <img src={accident} alt="..." />
                  <p style={{ fontSize: '18px', lineHeight: '23px' }}>
                    <Translate value="accidentSchemeTitle" />
                  </p>
                </div>
              </Link>
            </Col>
            <Col
              xs={12}
              sm={4}
              md={4}
              className="mapping-cards-col schemes"
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}
            >
              <Link to="/ailments">
                <div className="mapping-card schemes">
                  <img src={medical} alt="..." />
                  <p style={{ fontSize: '18px', lineHeight: '23px' }}>
                    <Translate value="ailmentsSchemeTitle" />
                  </p>
                </div>
              </Link>
            </Col>
            <Col
              xs={12}
              sm={4}
              md={4}
              className="mapping-cards-col schemes"
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}
            >
              <Link to="/thayimagu">
                <div className="mapping-card schemes">
                  <img src={Maga} alt="..." />
                  <p style={{ fontSize: '18px', lineHeight: '23px' }}>
                    <Translate value="thayimaguTitle" />
                  </p>
                </div>
              </Link>
            </Col>
            <Col
              xs={12}
              sm={4}
              md={4}
              className="mapping-cards-col schemes"
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}
            >
              <Link to="/disabilityContinuePension">
                <div className="mapping-card schemes">
                  <img src={disability} alt="..." />
                  <p style={{ fontSize: '18px', lineHeight: '23px' }}>
                    <Translate value="contdisablepensionTitle" />
                  </p>
                </div>
              </Link>
            </Col>
            {/* <Col xs={12} sm={4} md={4} className="mapping-cards-col schemes ">
        <Link to='/registrationSchemeoneyear'>
        <div className='mapping-card schemes'>
        <img src={registration} alt='...' />
            <p>Continuation of Existing Registration for next one year/three years</p>
        </div>
        </Link>
        </Col> */}
            <Col
              xs={12}
              sm={4}
              md={4}
              className="mapping-cards-col schemes"
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}
            >
              <Link to="/continuePension">
                <div className="mapping-card schemes">
                  <img src={continuationPension} alt="..." />
                  <p style={{ fontSize: '18px', lineHeight: '23px' }}>
                    <Translate value="contpensionTitle" />
                  </p>
                </div>
              </Link>
            </Col>
            <Col
              xs={12}
              sm={4}
              md={4}
              className="mapping-cards-col schemes"
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}
            >
              <Link to="/delivery">
                <div className="mapping-card schemes">
                  <img src={pregnant} alt="..." />
                  <p style={{ fontSize: '18px', lineHeight: '23px' }}>
                    <Translate value="deliveryTitle" />
                  </p>
                </div>
              </Link>
            </Col>
            <Col
              xs={12}
              sm={4}
              md={4}
              className="mapping-cards-col schemes"
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}
            >
              <Link to="/disablePension">
                <div className="mapping-card schemes">
                  <img src={pension} alt="..." />
                  <p style={{ fontSize: '18px', lineHeight: '23px' }}>
                    <Translate value="disablePensionTitle" />
                  </p>
                </div>
              </Link>
            </Col>
            {/* <Col
              xs={12}
              sm={4}
              md={4}
              className="mapping-cards-col schemes"
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}
            >
              <Link to="/education">
                <div className="mapping-card schemes">
                  <img src={education} alt="..." />
                  <p style={{ fontSize: '18px', lineHeight: '23px' }}>
                    <Translate value="laboureducationTitle" />
                  </p>
                </div>
              </Link>
            </Col> */}
            {/* <Col xs={12} sm={4} md={4} className="mapping-cards-col schemes ">
        <Link to='/ent'>
        <div className='mapping-card schemes'>
        <img src={ent} alt='...' />
            <p>ENT and Wheelchair Scheme</p>
        </div>
        </Link>
        </Col> */}
            <Col
              xs={12}
              sm={4}
              md={4}
              className="mapping-cards-col schemes "
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}
            >
              <Link to="/funeral">
                <div className="mapping-card schemes">
                  <img src={Funeral} alt="..." />
                  <p style={{ fontSize: '18px', lineHeight: '23px' }}>
                    <Translate value="funeralTitle" />
                  </p>
                </div>
              </Link>
            </Col>
            {/* <Col xs={12} sm={4} md={4} className="mapping-cards-col schemes ">
        <Link to='/identitycard'>
        <div className='mapping-card schemes'>
        <img src={duplicate} alt='...' />
            <p>Issue of duplicate Identity Card</p>
        </div>
        </Link>
        </Col> */}
            {/* <Col xs={12} sm={4} md={4} className="mapping-cards-col schemes ">
        <Link to='/ksrtc'>
        <div className='mapping-card schemes'>
        <img src={ksrtc} alt='...' />
            <p>KSRTC Bus Pass Assistance</p>
        </div>
        </Link>
        </Col> */}
            {/* <Col xs={12} sm={4} md={4} className="mapping-cards-col schemes ">
        <Link to='/lpg'>
        <div className='mapping-card schemes'>
        <img src={LPG} alt='...' />
            <p>LPG Connection Facility</p>
        </div>
        </Link>
        </Col> */}
            <Col
              xs={12}
              sm={4}
              md={4}
              className="mapping-cards-col schemes "
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}
            >
              <Link to="/marriage">
                <div className="mapping-card schemes">
                  <img src={MarriageAssistance} alt="..." />
                  <p style={{ fontSize: '18px', lineHeight: '23px' }}>
                    <Translate value="marriageTitle" />
                  </p>
                </div>
              </Link>
            </Col>
            <Col
              xs={12}
              sm={4}
              md={4}
              className="mapping-cards-col schemes "
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}
            >
              <Link to="/medical">
                <div className="mapping-card schemes">
                  <img src={Medical2} alt="..." />
                  <p style={{ fontSize: '18px', lineHeight: '23px' }}>
                    <Translate value="medicalTitle" />
                  </p>
                </div>
              </Link>
            </Col>
            <Col
              xs={12}
              sm={4}
              md={4}
              className="mapping-cards-col schemes "
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}
            >
              <Link to="/pension">
                <div className="mapping-card schemes">
                  <img src={Group} alt="..." />
                  <p style={{ fontSize: '18px', lineHeight: '23px' }}>
                    <Translate value="pensionTitle" />
                  </p>
                </div>
              </Link>
            </Col>
            {/* <Col
              xs={12}
              sm={4}
              md={4}
              className="mapping-cards-col schemes"
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}
            >
              <Link to="/shrama">
                <div className="mapping-card schemes">
                  <img src={Tool} alt="..." />
                  <p style={{ fontSize: '18px', lineHeight: '23px' }}>
                    <Translate value="shramaTitle" />
                  </p>
                </div>
              </Link>
            </Col> */}
            {/* <Col
              xs={12}
              sm={4}
              md={4}
              className="mapping-cards-col schemes"
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}
            >
              <Link to="/bmtc">
                <div className="mapping-card schemes">
                  <img src={bmtc} alt="..." />
                  <p style={{ fontSize: '18px', lineHeight: '23px' }}>
                    <Translate value="bmtcschemeTitle" />
                  </p>
                </div>
              </Link>
            </Col> */}
            {/* <Col
              xs={12}
              sm={4}
              md={4}
              className="mapping-cards-col schemes"
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}
            >
              <Link to="/precoaching">
                <div className="mapping-card schemes">
                  <img src={pre} alt="..." />
                  <p style={{ fontSize: '18px', lineHeight: '23px' }}>
                    <Translate value="precoachingTitle" />
                  </p>
                </div>
              </Link>
            </Col> */}
          </Row>
        </div>
      </div>
      <div className="d-flex justify-content-end mt-5 me-5 mb-5">
        <Link
          onClick={() => {
            props.history.goBack();
          }}
        >
          <Button className="back-btn-mainScreens">
            <ArrowBackIosIcon color="secondary" className="back-icon" />
            <Translate value="backbtn" />
          </Button>
        </Link>
      </div>
      {open > 0 && modalIndex[open] === 'scheme' && (
        <SuspenseProvider>
          <SchemeStatusModal closeHandler={handleClose} />
        </SuspenseProvider>
      )}
      {open > 0 && modalIndex[open] !== 'scheme' && (
        <SuspenseProvider>
          <ApplicationStatusModal closeHandler={handleClose} />
        </SuspenseProvider>
      )}
    </div>
  );
};

export default SchemesHomePage;
