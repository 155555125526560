import register from "assets/images/register-01.svg";
import { Col, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";

const BacklogDashboard = () => {
  return (
    <>
      <Row className="userpage-row">

        <Col xs={0} sm={0} md={1} className="blankcol"></Col>
        <Col
          xs={6}
          sm={6}
          md={2}
          className="dashboard-registration-col"
        >
          <Link
            style={{ color: "black" }}
            to={{
              pathname: "/admin/dashboard/BacklogFormFuneral",
              state: { backlog: 'FuneralAssistance' }
            }}
          >
            <div className="dashboard-registration-div ">
              <div>
                <p>
                  <br />
                  Funeral Assistance

                </p>
                <div>
                  <img alt="..." src={register} />
                </div>
              </div>

            </div>
          </Link>
        </Col>

        <Col
          xs={6}
          sm={6}
          md={2}
          className="dashboard-registration-col"
        >
          <Link
            style={{ color: "black" }}
            to={{
              pathname: "/admin/dashboard/backlogformaccidentDeath",
              state: { backlog: 'DeathAccidentAssistance' }
            }}
          >
            <div className="dashboard-registration-div-approved ">
              <div>
                <p>
                  <br />
                  Accidental Assistance
                </p>
                <div>
                  <img alt="..." src={register} />
                </div>
              </div>

            </div>
          </Link>
        </Col>
      </Row>
    </>
  );
};

export default BacklogDashboard;