import { Grid } from '@mui/material';
import React, { useState } from 'react';
import Card from './Card';
import AdminHospitalList from 'components/adminPortal/hospitals/AdminHospitalList';
import back from '../../assets/images/Icon awesome-arrow-circle-left.svg';
import { withBackButton } from 'HOC/WithBackButton';
import { LocalHospital } from '@mui/icons-material';
function Home(props) {
  const [view, setView] = useState('');
  const HospitalListWithBackButton = withBackButton(
    AdminHospitalList,
    back,
    setView,
  );
  return (
    <>
      {view === '' && (
        <div style={{ width: '60%', marginInline: 'auto' }}>
          <Grid container spacing={1} style={{ marginInline: 'auto' }}>
            <Grid item xs={6} lg={2.5}>
              <Card
                icon={LocalHospital}
                name="Hospital List"
                handleCard={() => {
                  setView('hospital');
                }}
              />
            </Grid>
          </Grid>
        </div>
      )}
      {view === 'hospital' && <HospitalListWithBackButton />}
    </>
  );
}

export default Home;
