import axios from 'axios';
import { useState } from 'react';
import { SERVER } from 'store/actions/user.actions';
import { getTokenCookie } from 'utils/tools';

export default function usePostApi({ url, method = 'post', options = {} }) {
  const [responseData, setResponseData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const postApiCall = async (data) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios({
        url: SERVER + url,
        method,
        data,
        headers: options?.headers ?? {},
      });

      if (response) {
        setResponseData(response.data);
      }
    } catch (err) {
      setError(err?.response?.data);
    } finally {
      setLoading(false);
    }
  };

  return { data: responseData, error, loading, postApiCall };
}
