import React, { useEffect, useMemo } from 'react';
import StatusModal from '../StatusModal';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import usePostApi from 'components/apiRequest/usePostApi';
import { showToast } from 'utils/tools';
import { headersWithToken } from 'http/customHeaders';
import { apiURL } from 'http/url';

const TOAST_MESSAGE = {
  1: 'Hospital Disabled Successfully',
  0: 'Hospital Enabled Successfully',
};

export default function HospitalModal({
  data,
  closeHandler,
  schemeInfo = {},
  isEnable = false,
}) {
  console.log('schemeInfo', schemeInfo);
  const {
    data: response,
    error,
    loading,
    postApiCall,
  } = usePostApi({
    url: apiURL.hospitalStatus,
    method: 'patch',
    options: {
      headers: headersWithToken(),
    },
  });
  const handleDisable = () => {
    postApiCall({
      field_name: data?.field_value ?? data.value,
      field_status: isEnable ? 0 : 1,
      schemeId: schemeInfo?.id,
    });
  };
  useEffect(() => {
    if (response) {
      showToast('SUCCESS', isEnable ? TOAST_MESSAGE['1'] : TOAST_MESSAGE['0']);
      closeHandler();
    }
  }, [response]);
  useEffect(() => {
    if (error) {
      showToast('ERROR', error.message);
      closeHandler();
    }
  }, [error]);
  return (
    <StatusModal>
      <StatusModal.Title
        render={() => {
          return (
            <Box sx={{ position: 'relative' }}>
              <Typography textAlign={'center'} variant="h5" color={'red'}>
                Confirm Hospital {isEnable ? 'Disable' : 'Enable'} ?
              </Typography>
              <IconButton
                sx={{ position: 'absolute', right: -15, top: -10 }}
                onClick={closeHandler}
              >
                <Close />
              </IconButton>
            </Box>
          );
        }}
      />
      <StatusModal.Content
        render={() => {
          return (
            <>
              <Typography style={{ display: 'inline', fontWeight: 600 }}>
                <span style={{ color: 'black', fontWeight: 'normal' }}>
                  Hospital:{' '}
                </span>
                {data?.field_value ?? data.value}
              </Typography>

              <Typography style={{ fontWeight: 600 }} color={'red'}>
                <span style={{ color: 'black', fontWeight: 'normal' }}>
                  {' '}
                  Scheme:
                </span>{' '}
                {schemeInfo?.name}
              </Typography>
            </>
          );
        }}
      ></StatusModal.Content>
      <StatusModal.Actions
        render={() => (
          <>
            <Button variant="contained" color="info" onClick={closeHandler}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleDisable}
              disabled={loading ?? false}
            >
              Confirm
            </Button>
          </>
        )}
      ></StatusModal.Actions>
    </StatusModal>
  );
}
