import "date-fns";
import {
  FormControl,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListItemText,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import {
  PhoneAndroid,
  CameraAlt,
  Cached,
  FormatColorReset,
} from "@material-ui/icons";
import React, { useEffect } from "react";
import { Col, Row, Button, OverlayTrigger } from "react-bootstrap";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Webcam from "react-webcam";
import { Link } from "react-router-dom";
// import folder from 'assets/images/folder (3).svg'
import folder from "assets/images/Folderwhite-01-01.svg";
// import webcam from 'assets/images/web-cam.svg'
import webcam from "assets/images/web cam-01.svg";
// import aadhar from 'assets/images/id-card.svg'
import aadharRed from "assets/images/Aadhar black-01.svg";
import aadhar from "assets/images/Aadhar black-01.svg";
// import pan from 'assets/images/credit-card.svg'
import panRed from "assets/images/PAN red-01.svg";
import pan from "assets/images/PAN Black-01.svg";
import checkgreen from "assets/images/Subtraction 1.svg";
import checkgrey from "assets/images/Subtraction 2.svg";
import male from "assets/images/Mask Group 1.svg";
import female from "assets/images/Mask Group 2.svg";
import radioon from "assets/images/Icon ionic-ios-radio-button-on-1.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  addLabourPersonalDetails,
  BASE_URL,
  deleteReduxData,
  getCatalogDetails,
  getFile,
  getStateDetails,
  getUser,
  getUserRegistrationDetails,
  SERVER,
  TS_USERNAME,
  updateGetFile,
  updatePersonalData,
  uploadFile,
} from "store/actions/user.actions";
import { getTokenCookie, Required, saveLog, showToast } from "utils/tools";
import axios from "axios";
import cookie from "react-cookies";
import validator from "validator";
import { Translate } from "react-redux-i18n";
import moment from "moment";
import { useParams } from "react-router-dom";
const MobileEKYC = (props) => {
  const myRef = React.createRef();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);

  const [phone, setPhone] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [aadhaarToken, setAadhaarToken] = React.useState(null);
  const [redirectback, setRedirectback] = React.useState(false);
  const [vaultRefNumber, setVaultRefNumber] = React.useState(null);
  const [aadharValidated, setAadharValidated] = React.useState(false);
  const [aadharDuplicate, setaadharDuplicate] = React.useState(false);
  const [nextButtonClicked, setNextButtonClicked] = React.useState(false);
  const [vaultRef, setVaultRef] = React.useState(null);
  const [profilePic, setprofilePic] = React.useState("");
  const [imageEKYC, setImageEKYC] = React.useState(null);
  const [dob, setDob] = React.useState(users.personal_details.dob);
  const [getUserID, setGetUserID] = React.useState("");
  const [getToken, setGetToken] = React.useState("");
  const [sessionUserID, setSessionUserID] = React.useState(null);
  const [allValues, setAllValues] = React.useState({
    language: "",
    selectedDate: null,
    phoneNumber: "",
    maritalStatus: "",
    category: "",
    migrantState: "",
    isMigrant: false,
    pancheck: false,
    aadharcheck: false,
    imgfile: "",
    webcamEnabled: false,
    open: false,
    setOpen: false,
    openDatePicker: false,
    formcontrolnum: 1,
    aadharnum1: null,
    aadharnum2: null,
    aadharnum3: null,
    aadhar: null,
    firstname: "",
    middlename: "",
    lastname: "",
    email: "",
    fatherHusbandName: "",
    pan: "",
    gender: null,
    dob: null,
    documentUploadResponse: "",
    aadhaarFetchedData: null,
    // firstnameError:false,
    // firstnameErrorMessage:"",
    // lastnameError:false,
    // lastnameErrorMessage:"",
    // panError:false,
    // emailError:false
  });

  const params = useParams();

  useEffect(() => {
    if (window.location.toString().includes("user_id")) {
      console.log("PROPSQP", props.location.search);
      var queryParam = JSON.stringify(props.location.search);
      var querySub = queryParam
        .substring(queryParam.lastIndexOf("user_id=") + 8)
        .split(" ")[0];
      var queryCut = querySub.slice(0, 8);
      var uID = queryCut.substring(0, queryCut.length - 1);
      var queryParam2 = JSON.stringify(props.location.search);
      var querySub2 = queryParam2
        .substring(queryParam2.lastIndexOf("token=") + 6)
        .split(" ")[0];
      var token = querySub2.substring(0, querySub2.length - 1);
      console.log(uID, "qP");
      console.log(token, "tokeen");
      localStorage.setItem("USERID", uID);
      localStorage.setItem("TOKEN", token);
      // setGetUserID(uID)
      // setGetToken(token)
    }
  }, []);

  // var UID = params.user_id
  // localStorage.setItem("userID",UID)

  // setSessionUserID(localStorage.getItem("userID"));

  var currentDateTime = Date.now();
  var randNum = Math.floor(100000 + Math.random() * 900000);

  useEffect(() => {
    // console.log("in useEffect1")

    // if (users.generate_otp !== null) {
    //     setPhone(users.generate_otp.data.phone_number)
    //     // setAllValues({...allValues, phoneNumber: users.generate_otp.data.phone_number})
    //     dispatch(getUser(users.generate_otp.data.phone_number))
    // }
    // else if (users.generate_otp_registration !== null) {
    //     setPhone(users.generate_otp_registration.data.phone_number)
    //     // setAllValues({...allValues, phoneNumber: users.generate_otp_registration.data.phone_number})
    //     dispatch(getUser(users.generate_otp_registration.data.phone_number))
    // }
    // else {
    //     props.history.push("/")
    // }

    // dispatch(deleteReduxData("getFile"))
    // dispatch(deleteReduxData("uploadFile"))
    // dispatch(deleteReduxData("addLabourPersonalDetails"))
    // sessionStorage.removeItem('profilePic')

    // setAllValues({
    //     ...allValues,
    //     upload_file: null
    // })

    if (window.location.toString().includes("Success") && !aadharValidated) {
      var queryString = JSON.stringify(props.location.search);

      var vaultRefN = queryString
        .substring(queryString.lastIndexOf("refno=") + 6)
        .split(" ")[0];
      var vaultRefNo = vaultRefN.substring(0, vaultRefN.length - 1);
      setVaultRefNumber(vaultRefNo);

      var data = JSON.stringify({
        key: "aadhar_no",
        value: vaultRefNo,
        board_id: 1,
      });

      var config = {
        method: "post",
        url: SERVER + "/user/get-user",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("TOKEN"),
        },
        data: data,
      };

      axios(config).then(function (response) {
        setLoading(false);
        console.log(
          "checkAadhaarDuplicate response:" +
            JSON.stringify(response.data, undefined, 2)
        );

        var check = true;
        var aadharDuplicate = response.data.data;
        console.log("aadharDuplicate:" + response.data.data);
        setaadharDuplicate(response.data);
      });
    } else if (window.location.toString().includes("Failure")) {
      showToast("ERROR", "Aadhaar Verification failed, please try again.");
    }
  }, []);

  useEffect(() => {
    if (aadharDuplicate) {
      console.log("inAadharDuplicate", aadharDuplicate.data);
      if (aadharDuplicate.data.length === 0) {
        console.log(aadharDuplicate.data, "aadhaarData");

        setNextButtonClicked(true);

        console.log("props12", vaultRefNumber);

        var data1 = JSON.stringify({
          labour_user_id: localStorage.getItem("USERID"),
          vault_ref_no: vaultRefNumber,
        });

        var config = {
          method: "post",
          url: SERVER + "/global/update_ekyc_vault_number",
          headers: {
            "Content-Type": "application/json",
          },
          data: data1,
        };
        axios(config)
          .then(function (response) {
            setAadharValidated(true);
          })
          .catch(function (error) {
            console.log(error);
            if (error.response.data.errors.msg.msg !== undefined) {
              showToast("ERROR", error.response.data.errors.msg.msg);
            }
          });
      } else {
        showToast("ERROR", "Aadhaar Already Registered!");
      }
    }
  }, [aadharDuplicate]);

  useEffect(() => {
    if (vaultRefNumber !== null && vaultRefNumber !== undefined) {
      sessionStorage.setItem("vaultRefNumber", vaultRefNumber);
    } else if (
      users.getUserRegistrationDetails !== null &&
      users.getUserRegistrationDetails.personal_details !== null &&
      users.getUserRegistrationDetails.personal_details !== undefined &&
      users.getUserRegistrationDetails.personal_details[0] !== undefined &&
      users.getUserRegistrationDetails.personal_details[0] !== null &&
      users.getUserRegistrationDetails.personal_details[0].aadhar_no !== null
    ) {
      setVaultRef(
        users.getUserRegistrationDetails.personal_details[0].aadhar_no
      );
    }
  }, [vaultRefNumber]);

  useEffect(() => {
    console.log("in useEffect5");

    if (aadharValidated) {
      var data1 = JSON.stringify({
        labour_user_id: localStorage.getItem("USERID"),
      });

      var config = {
        method: "post",
        url: SERVER + "/user/get_ad_details",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("TOKEN"),
        },
        data: data1,
      };
      axios(config)
        .then(function (response) {
          // showToast("SUCCESS", "Aadhar Verified")
          console.log("ResponseEKYC", response.data.data[0].id);
          if (
            response.data.data[0] !== undefined &&
            response.data.data[0] !== null
          ) {
            showToast("SUCCESS", "Aadhaar Verified Successfully");
            setTimeout(() => {
              window.location.replace("/goback/aadhar-verified");
            }, 3000);

            var first_name = "";
            var middle_name = "";
            var last_name = "";
            var nameArr = response.data.data[0].name.split(" ");
            console.log("nameArr: " + nameArr[0]);
            if (nameArr.length === 1) {
              first_name = nameArr[0];
            } else if (nameArr.length === 2) {
              first_name = nameArr[0];
              last_name = nameArr[1];
            } else if (nameArr.length >= 3) {
              first_name = nameArr[0];
              middle_name = nameArr[1];
              last_name = nameArr[2];
            }
            console.log("first_name: " + first_name);
            console.log("middle_name: " + middle_name);
            console.log("last_name: " + last_name);

            var gender2 = null;
            if (response.data.data[0].gender === "M") {
              gender2 = "27";
            } else if (response.data.data[0].gender === "F") {
              gender2 = "28";
            } else {
              gender2 = "29";
            }
            console.log("gender: " + gender2);

            cookie.save(
              "AadhaarFetchedData",
              JSON.stringify(response.data.data[0]),
              { path: "/" }
            );

            setAllValues({
              ...allValues,
              aadharcheck: true,
              aadhaarFetchedData: response.data.data[0],
              is_aadhar_verified: true,
              firstname: first_name,
              middlename: middle_name,
              lastname: last_name,
              gender: gender2,
            });
            setDob(response.data.data[0].dob);

            setLoading(false);
            var base64Str = response.data.data[0].document_link;
            setprofilePic(`data:image/png;base64,${base64Str}`);

            setImageEKYC(response.data.data[0].image);

            sessionStorage.setItem(
              "profilePicEKYC",
              response.data.data[0].document_link
            );
          }
        })
        .catch(function (error) {
          console.log(error);
          if (error.response.data.errors !== undefined) {
            showToast("ERROR", error.response.data.errors);
          }
        });
    }
  }, [aadharValidated]);

  useEffect(() => {
    console.log("in useEffect10");

    if (redirectback) {
      console.log("inside_redirect");
    }
  }, [redirectback]);

  const handleEKYC = () => {
    //Production Data

    var data = JSON.stringify({
      deptCode: "9",
      integrationKey: "0dac67ae-00bb-47fb-8c10-f5fc1776e91d",
      integrationPassword: "-t-69hpP8K?Do-VX",
      txnNo: randNum,
      txnDateTime: currentDateTime,
      serviceCode: "3",
      responseRedirectURL: `${BASE_URL}/mobile-registration`,
    });

    //DEVQA
    // var data = JSON.stringify({
    //     "deptCode": "9",
    //     "integrationKey": "a6ed232e-0bd9-4c24-89d7-67ba59bef639",
    //     "integrationPassword": "@895u5Xxe-2!7!!Y",
    //     "txnNo": randNum,
    //     "txnDateTime": currentDateTime,
    //     "serviceCode": "3",
    //     "responseRedirectURL": "https://gcdmsalpha.hostg.in:7701/mobile-registration"
    // });

    // localhost
    // var data = JSON.stringify({
    //     "deptCode": "9",
    //     "integrationKey": "a6ed232e-0bd9-4c24-89d7-67ba59bef639",
    //     "integrationPassword": "@895u5Xxe-2!7!!Y",
    //     "txnNo": randNum,
    //     "txnDateTime": currentDateTime,
    //     "serviceCode": "3",
    //     "responseRedirectURL": "http://localhost:3000/mobile-registration"
    // });

    var config = {
      method: "post",
      url: SERVER + "/global/get_ekyc_token",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config).then(function (response) {
      setLoading(false);
      console.log(
        "checkAadhaarDuplicate response:" +
          JSON.stringify(response.data.token.Token)
      );
      setAadhaarToken(response.data.token.Token);
      var aadharToken = response.data.token.Token;
      if (aadharToken !== null) {
        //Production Data

        window.open(
          "https://dbt.karnataka.gov.in/HSMService/EKYCService.aspx?key=0dac67ae-00bb-47fb-8c10-f5fc1776e91d&token=" +
            aadharToken,
          "_self"
        );

        // window.open("https://dbt.karnataka.gov.in:8443/HSM_Service_ASPX/EKYCService.aspx?key=a6ed232e-0bd9-4c24-89d7-67ba59bef639&token="+aadharToken, "_self")

        setRedirectback(true);
      }
    });
  };

  // console.log("Params:",params.user_id);
  // console.log("STORAGE",sessionUserID);
  // const EKYCVerification = !window.location.toString().includes("Success")
  return (
    <div>
      <div className='ekyc-verify d-flex justify-content-center'>
        <h3 style={{ marginRight: "20px", marginTop: "10px" }}>
          {" "}
          Click here to verify your Aadhaar
        </h3>
        <Button
          className='aadhar-verify-btn'
          variant='outline-primary'
          // Rowor="primary"
          // className={buttonClassname}
          // disabled={loading}
          // onClick={handleValidateClick}
          onClick={handleEKYC}
        >
          Verify
        </Button>
        {loading && <CircularProgress size={24} className='buttonProgress' />}
      </div>
    </div>
  );
};

export default MobileEKYC;
