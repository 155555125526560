import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { SERVER } from 'store/actions/user.actions';

export default function useApi({ url, method = 'get', data, options = {} }) {
  const [responseData, setResponseData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios({
        method: method,
        url: SERVER + url,
        data: data ?? undefined, // Include data only for non-GET requests
        headers: options?.headers,
      });

      setResponseData(response.data);
    } catch (err) {
      setError(err.message || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (url) {
      fetchData();
    }
  }, [url, fetchData]);

  return { data: responseData, error, loading, refetch: fetchData };
}
