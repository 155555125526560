import { Paper } from '@mui/material';
import React from 'react';

function Card(props) {
  const { image, icon: Icon, name, handleCard } = props;
  return (
    <Paper
      sx={{
        cursor: 'pointer',
        minHeight: '200px',
        '&:hover': {
          backgroundColor: '#f5f5f5',
          boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
          transition: 'background-color 0.3s ease-in-out',
        },
      }}
      onClick={() => handleCard()}
    >
      <div
        className="d-flex align-items-center flex-column p-3 gap-3"
        style={{ height: '100%' }}
      >
        {image && <img src={image} alt="" width={'100'} height={'100'} />}
        {Icon ? <Icon sx={{ fontSize: 100, color: 'red' }} /> : null}
        <p style={{ fontSize: '25px', fontWeight: 'lighter' }}>{name}</p>
      </div>
    </Paper>
  );
}

export default Card;
