import React, { useCallback, useEffect, useRef, useState } from 'react';
import StatusModal from '../StatusModal';

import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { apiURL } from 'http/url';
import { headersWithToken } from 'http/customHeaders';
import useApi from 'components/apiRequest/useApi';
import moment from 'moment';
import { LinearLoader } from 'components/ui/Loader';
import { showToast } from 'utils/tools';

export default function DisabledHospitalList(props) {
  const [filteredText, setFilteredText] = useState('');
  const [schemeId, setSchemeID] = useState(null);
  // console.log('DisabledHospitalList ~ schemeId:', schemeId);
  const { data, error, loading } = useApi({
    url: apiURL.hospitalList + '/0',
    options: { headers: headersWithToken() },
  });
  const handleScheme = (id) => {
    setSchemeID(id);
  };
  const { closeHandler } = props;
  const handleDisable = (row) => {
    if (row) {
      props.handleModal('disable', { ...row });
    }
  };
  useEffect(() => {
    showToast('error', error?.message ?? 'SOMETHING WENT WRONG');
  }, [error]);
  return (
    <StatusModal>
      <StatusModal.Title
        render={() => {
          return (
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Typography fontWeight={'bold'} color={'error'}>
                Disabled Hospital List
              </Typography>
              <IconButton
                onClick={closeHandler}
                sx={{ position: 'absolute', right: -15, top: -10 }}
              >
                <Close />
              </IconButton>
            </Box>
          );
        }}
      ></StatusModal.Title>
      <StatusModal.Content
        render={() => {
          return (
            <>
              <Autocomplete
                name="schemaId"
                getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                options={props.schemeList}
                sx={{ minWidth: '50%', paddingBottom: '0.5rem' }}
                onChange={(_, newValue) => {
                  if (newValue) {
                    handleScheme(newValue.id);
                  } else {
                    handleScheme(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    placeholder={'Select Scheme'}
                    sx={{
                      mb: 2,
                      my: 'auto',
                      '.MuiInputBase-input': {
                        padding: '4px !important',
                        borderRadius: 1,
                      },
                      '& .MuiInputBase-root': {
                        px: '2px !important',
                        py: '3px !important',
                        lineHeight: 2,
                        border: '1px solid black',
                        borderRadius: 4,
                      },
                    }}
                    name="schemaId"
                    {...params}
                    variant="outlined"
                  />
                )}
              />
              <TextField
                placeholder="Search Hospitals"
                sx={{
                  width: '100%',
                  border: '1px solid gray',
                  borderRadius: 1,
                }}
                onChange={(e) => setFilteredText(e.target.value)}
              ></TextField>
              {loading ? (
                <LinearLoader />
              ) : (
                <TableContainer sx={{ maxeight: '50vh', overflow: 'auto' }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: '25%' }}>
                          <Typography fontWeight={'medium'}>Date</Typography>
                        </TableCell>
                        <TableCell sx={{ width: '70%' }}>
                          <Typography fontWeight={'medium'}>
                            Name of Hospitals
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ width: '70%' }}>
                          <Typography fontWeight={'medium'}>Scheme</Typography>
                        </TableCell>
                        <TableCell sx={{ width: '10%' }}>
                          <Typography
                            textAlign={'center'}
                            fontWeight={'medium'}
                          >
                            Actions
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(filteredText
                        ? data.data
                            .filter((item) =>
                              schemeId ? +item.scheme_id === schemeId : item,
                            )
                            .filter((item) =>
                              item.value
                                .toLowerCase()
                                .includes(filteredText.toLowerCase()),
                            )
                        : data?.data.filter(
                            (item) => +item.scheme_id === schemeId,
                          )
                      )?.map((row, index) => (
                        <TableRow>
                          <TableCell>
                            {moment(row.added_date).format('DD-MM-YYYY')}
                          </TableCell>
                          <TableCell>{row.value}</TableCell>
                          <TableCell>
                            {
                              props.schemeList
                                .filter((scheme) => scheme.isactive)
                                .filter(
                                  (scheme) => scheme.id == row.scheme_id,
                                )[0]?.name
                            }
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="success"
                              onClick={() => handleDisable(row)}
                            >
                              Enable
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </>
          );
        }}
      ></StatusModal.Content>
    </StatusModal>
  );
}
