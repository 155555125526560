import "date-fns";
import {
  FormControl,
  TextField,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  InputAdornment,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import logo from "assets/images/Karnataka_logo_100_Pixels.png";
import language from "assets/images/translate (1).svg";
import { useDispatch, useSelector } from "react-redux";
import {
  get_schemes,
  SERVER,
  setLocaleWithFallback,
} from "store/actions/user.actions";
import { Link } from "react-router-dom";
import { getTokenCookie, showToast } from "utils/tools";
import search from "assets/images/Icon ionic-ios-search.svg";
import MarriageAssistance from "assets/images/marriage assistance-01.svg";
import usericon from "assets/images/Icon awesome-user-circle.svg";
import accident from "assets/images/Accident-01.svg";
import disability from "assets/images/Continuation of disability pension-01.svg";
import continuationPension from "assets/images/Continuation-of-pension-01.svg";
import closeIcon from "assets/images/close-01.svg";
import pension from "assets/images/Disability Pension-01.svg";
import education from "assets/images/Education Assitance-01.svg";
// import Funeral from 'assets/images/Funeral-01.svg'
import Funeral from "assets/images/Funeral and EX -Gratia.png";
import Group from "assets/images/Group 70.svg";
import ksrtc from "assets/images/ksrtc bus pass-01.svg";
import LPG from "assets/images/LPG CONNECTION-01.svg";
import Maga from "assets/images/Maga-01.svg";
import medical from "assets/images/medical assisatance.svg";
import Medical2 from "assets/images/Medical assistance-01.svg";
import UPSC from "assets/images/Pre Coaching (UPSC and KPSC) Application 2.svg";
// import pregnant from 'assets/images/pregnant woman-01.svg'
import pregnant from "assets/images/images-02.svg";
import Tool from "assets/images/shrama samathya Tool kit-01.svg";
import duplicateId from "assets/images/DUlicate id card-01.svg";
import HomeButton from "assets/images/Icon material-home.svg";
import axios from "axios";
import logout from "assets/images/Logout.svg";
import { Tooltip } from "@material-ui/core";

// <<<<<<< kannada-translation-schemes
// import { Translate } from '@material-ui/icons';
import { Translate } from "react-redux-i18n";
// =======
import moment from "moment";
// >>>>>>> main
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { checkSevaSindhuAvailedAPI } from "../SchemeServices/scheme.service";
import { ChildListAPI } from "services/ThaiMagu.service";

const KBOCWWBSchemeHomePage = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const i18nlang = useSelector((state) => state.i18n);
  const history = useHistory();

  const [schemes, setSchemes] = useState(null);
  const [schemesAvailed, setSchemesAvailed] = useState(null);
  const [renewalDate, setRenewalDate] = useState(null);
  const [query, setQuery] = useState(null);
  const [filteredSchemes, setFilteredSchemes] = useState(null);
  const [childList, setChildList] = useState(null);

  const [translate, setTranslate] = useState({
    lang: users.profile_details.language,
    kannadaSelected: i18nlang.locale === "ka" ? true : false,
  });

  const userID = users && users.user.id;
  const isActiveUser = users?.user?.ISACTIVEUSER;
  const isSchemeExtended = users?.user?.is_scheme_extended;
  const renewalDiff = moment(renewalDate).diff(moment(), "days");

  const callThayiMaguList = async (labourUserID) => {
    try {
      const response = await ChildListAPI(labourUserID);
      setChildList(response.data);
    } catch (error) {
      showToast("ERROR", `Something went wrong`);
    }
  };

  useEffect(() => {
    if (userID) {
      callThayiMaguList(userID);
    }
  }, [userID]);

  useEffect(() => {
    axios
      .get(`${SERVER}/schemes/get_schemes?board_id=1`)
      .then(({ data }) => {
        setSchemes(data.data);
        setFilteredSchemes(data.data);
      })
      .catch((err) => {
        if (err) {
          showToast("ERROR", "Something went wrong");
        } else {
          showToast("ERROR", "Something went wrong");
        }
      });
  }, []);

  useEffect(() => {
    if (userID) {
      const payLoad = {
        board_id: 1,
        labour_user_id: userID,
        scheme_id: 0,
        check_for: "all",
      };
      axios
        .post(`${SERVER}/schemes/check_user_availed_schemes`, payLoad, {
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
        })
        .then(({ data }) => {
          setSchemesAvailed(data.data);

          // const selfData = data.data.self;
        })
        .catch((err) => {
          if (err) {
            showToast("ERROR", "Something went wrong");
          } else {
            showToast("ERROR", "Something went wrong");
          }
        });
    }
  }, [userID]);

  useEffect(() => {
    if (query) {
      const keys = ["name"];
      const result = schemes.filter((item) =>
        keys.some((key) => {
          return (
            item["name"].includes(query) ||
            item["name"].toLowerCase().includes(query) ||
            item["name"].toUpperCase().includes(query)
          );
        })
      );
      setFilteredSchemes(result);
    } else {
      setFilteredSchemes(schemes);
    }
  }, [query, schemes]);

  useEffect(() => {
    if (userID) {
      const payLoad = {
        user_id: userID,
      };
      axios
        .post(`${SERVER}/user/get-renewal-date`, payLoad, {
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
        })
        .then(({ data }) => {
          setRenewalDate(data.data.recordsets[0][0].next_renewal_date);
          // const selfData = data.data.self;
        })
        .catch((err) => {
          if (err) {
            showToast("ERROR", "Something went wrong");
          } else {
            showToast("ERROR", "Something went wrong");
          }
        });
    }
  }, [userID]);

  const callSevaSindhuAvailed = async (labourUserID, schemeID) => {
    try {
      if (
        users &&
        users.getUserRegistrationDetails &&
        users.getUserRegistrationDetails.personal_details[0].is_sevasindhu === 1
      ) {
        const payLoad = {
          labourUserId: labourUserID,
          schemeId: schemeID,
        };
        const result = await checkSevaSindhuAvailedAPI(payLoad);
        return result;
      } else {
        return;
      }
    } catch (error) {
      console.log(error.response);
      if (error) {
        return error.response.data;
      } else {
        return "Scheme Already Availed";
      }
    }
  };

  const handleChange = (event) => {
    if (event.target.value === "ka") {
      setTranslate((prev) => ({
        ...prev,
        kannadaSelected: true,
        [event.target.name]: event.target.value,
      }));
    } else {
      setTranslate((prev) => ({
        ...prev,
        kannadaSelected: false,
        [event.target.name]: event.target.value,
      }));
    }

    if (event.target.name === "lang")
      dispatch(setLocaleWithFallback(event.target.value));
  };

  const handleSchemes = async (scheme) => {
    const labourAge =
      users &&
      users.getUserRegistrationDetails.personal_details[0].date_of_birth;
    const diffAge = moment().diff(labourAge, "years");
    const dateOfRegistration =
      users &&
      users.getUserRegistrationDetails.personal_details[0].approved_date;
    const registrationDate = moment().diff(dateOfRegistration, "years");

    if (scheme.name === "Marriage Assistance") {
      // const isSevaSindhuAvailed = await callSevaSindhuAvailed(
      //   userID,
      //   scheme.id,
      // );

      const foundSchemes = schemesAvailed.self.find(
        (data) => data.scheme_name === scheme.name
      );
      const foundSchemesFamily = schemesAvailed.family.find(
        (data) => data.scheme_name === scheme.name
      );

      const foundAccidentAssistance = schemesAvailed.self.find(
        (data) => data.scheme_name === "Accident Assistance"
      );

      const deathApplied = foundAccidentAssistance.death_status;

      const sumTotalAviled =
        foundSchemes.total_aviled_by_user +
        foundSchemesFamily.total_aviled_by_user;

      if (deathApplied === 0) {
        if (diffAge < 60 || isSchemeExtended === 1) {
          if (registrationDate >= 1) {
            if (isActiveUser === 1) {
              if (foundSchemes.total_count_can_be_availed !== sumTotalAviled) {
                if (
                  foundSchemes.is_pending_for_approval === 0 &&
                  foundSchemesFamily.is_pending_for_approval === 0
                ) {
                  history.push(
                    `/scheme/${scheme.name}_id=${scheme.id}/${scheme.role_hierarchy_master_id}/${foundSchemes.total_count_can_be_availed}/${sumTotalAviled}`
                  );
                } else {
                  showToast("ERROR", "Your Scheme is already been applied.");
                }
              } else {
                showToast("ERROR", "Scheme Avail Limit Reached!");
              }
            } else {
              showToast("ERROR", "Please Renew!");
            }
          } else {
            showToast(
              "ERROR",
              "You have not completed 1 Year of Registration!"
            );
          }
        } else {
          showToast(
            "ERROR",
            "This service is unavailable for the age above 60 years"
          );
        }
      } else {
        showToast(
          "ERROR",
          "This service is unavailable as you have availed for Accident Assistance for the Death"
        );
      }
    } else if (scheme.name === "Medical Assistance") {
      // const isSevaSindhuAvailed = await callSevaSindhuAvailed(
      //   userID,
      //   scheme.id,
      // );

      const foundSchemes = schemesAvailed.self.find(
        (data) => data.scheme_name === scheme.name
      );
      const foundSchemesFamily = schemesAvailed.family.find(
        (data) => data.scheme_name === scheme.name
      );

      const sumTotalAviled =
        foundSchemes.total_aviled_by_user +
        foundSchemesFamily.total_aviled_by_user;

      if (diffAge < 60 || isSchemeExtended === 1) {
        if (isActiveUser === 1) {
          if (foundSchemes.total_count_can_be_availed !== sumTotalAviled) {
            if (
              foundSchemes.is_pending_for_approval === 0 &&
              foundSchemesFamily.is_pending_for_approval === 0
            ) {
              history.push(
                `/scheme/${scheme.name}_id=${scheme.id}/${scheme.role_hierarchy_master_id}/${foundSchemes.total_count_can_be_availed}/${sumTotalAviled}`
              );
            } else {
              showToast("ERROR", "Your Scheme is already been applied.");
            }
          } else {
            showToast("ERROR", "Scheme Avail Limit Reached!");
          }
        } else {
          showToast("ERROR", "Please Renew");
        }
      } else {
        showToast(
          "ERROR",
          "This service is unavailable for the age above 60 years"
        );
      }
    } else if (scheme.name === "Accident Assistance") {
      const foundSchemes = schemesAvailed.self.find(
        (data) => data.scheme_name === scheme.name
      );
      const foundSchemesFamily = schemesAvailed.family.find(
        (data) => data.scheme_name === scheme.name
      );
      const deathApplied = foundSchemes.death_status;
      const sumTotalAviled =
        foundSchemes.total_aviled_by_user +
        foundSchemesFamily.total_aviled_by_user;
      const foundSchemeDisability = schemesAvailed.self.find(
        (data) => data.scheme_name === "Disability Pension"
      );

      if (deathApplied === 0) {
        if (diffAge < 60 || isSchemeExtended === 1) {
          if (foundSchemeDisability.total_aviled_by_user === 0) {
            if (foundSchemes.total_count_can_be_availed !== sumTotalAviled) {
              if (
                foundSchemes.is_pending_for_approval === 0 &&
                foundSchemesFamily.is_pending_for_approval === 0
              ) {
                history.push(
                  `/scheme/${scheme.name}_id=${scheme.id}/${scheme.role_hierarchy_master_id}/${foundSchemes.total_count_can_be_availed}/${sumTotalAviled}`
                );
              } else {
                showToast("ERROR", "Your Scheme is already been applied.");
              }
            } else {
              showToast("ERROR", "Scheme Avail Limit Reached!");
            }
          } else {
            showToast(
              "ERROR",
              "You are not eligible, since Disability Pension is already availed."
            );
          }
        } else {
          showToast(
            "ERROR",
            "This service is unavailable for the age above 60 years"
          );
        }
      } else {
        showToast(
          "ERROR",
          "This service is unavailable as you have availed for Accident Assistance for the Death"
        );
      }
    } else if (scheme.name === "Assistance For Major Ailments") {
      const foundSchemes = schemesAvailed.self.find(
        (data) => data.scheme_name === scheme.name
      );
      const foundSchemesFamily = schemesAvailed.family.find(
        (data) => data.scheme_name === scheme.name
      );

      const sumTotalAviled =
        foundSchemes.total_aviled_by_user +
        foundSchemesFamily.total_aviled_by_user;

      const foundAccidentAssistance = schemesAvailed.self.find(
        (data) => data.scheme_name === "Accident Assistance"
      );

      const deathApplied = foundAccidentAssistance.death_status;

      if (deathApplied === 0) {
        if (diffAge < 60 || isSchemeExtended === 1) {
          if (isActiveUser === 1) {
            if (foundSchemes.total_count_can_be_availed !== sumTotalAviled) {
              if (
                foundSchemes.is_pending_for_approval === 0 &&
                foundSchemesFamily.is_pending_for_approval === 0
              ) {
                history.push(
                  `/scheme/${scheme.name}_id=${scheme.id}/${scheme.role_hierarchy_master_id}/${foundSchemes.total_count_can_be_availed}/${sumTotalAviled}`
                );
              } else {
                showToast("ERROR", "Your Scheme is already been applied.");
              }
            } else {
              showToast("ERROR", "Scheme Avail Limit Reached!");
            }
          } else {
            showToast("ERROR", "Please Renew");
          }
        } else {
          showToast(
            "ERROR",
            "This service is unavailable for the age above 60 years"
          );
        }
      } else {
        showToast(
          "ERROR",
          "This service is unavailable as you have availed for Accident Assistance for the Death"
        );
      }
    } else if (scheme.name === "Education Assistance 2023-24") {
      const foundSchemes = schemesAvailed.self.find(
        (data) => data.scheme_name === scheme.name
      );
      const foundSchemesFamily = schemesAvailed.family.find(
        (data) => data.scheme_name === scheme.name
      );

      const sumTotalAviled =
        foundSchemes.total_aviled_by_user +
        foundSchemesFamily.total_aviled_by_user;
      if (diffAge < 60) {
        if (isActiveUser === 1) {
          if (foundSchemes.total_count_can_be_availed !== sumTotalAviled) {
            history.push(
              `/scheme/${scheme.name}_id=${scheme.id}/${scheme.role_hierarchy_master_id}`
            );
          } else {
            showToast("ERROR", "Scheme Avail Limit Reached!");
          }
        } else {
          showToast("ERROR", "Please Renew");
        }
      } else {
        showToast(
          "ERROR",
          "This service is unavailable for the age above 60 years"
        );
      }
    } else if (scheme.name === "Delivery Assistance") {
      const foundSchemes = schemesAvailed.self.find(
        (data) => data.scheme_name === scheme.name
      );
      const foundSchemesFamily = schemesAvailed.family.find(
        (data) => data.scheme_name === scheme.name
      );

      const sumTotalAviled =
        foundSchemes.total_aviled_by_user +
        foundSchemesFamily.total_aviled_by_user;

      const foundAccidentAssistance = schemesAvailed.self.find(
        (data) => data.scheme_name === "Accident Assistance"
      );

      const deathApplied = foundAccidentAssistance.death_status;

      if (deathApplied === 0 || isSchemeExtended === 1) {
        if (diffAge < 60) {
          if (isActiveUser === 1) {
            if (
              users &&
              users.getUserRegistrationDetails.personal_details[0]
                .catalog_value_gender_id === 28
            ) {
              if (foundSchemes.total_count_can_be_availed !== sumTotalAviled) {
                if (
                  foundSchemes.is_pending_for_approval === 0 &&
                  foundSchemesFamily.is_pending_for_approval === 0
                ) {
                  history.push(
                    `/scheme/${scheme.name}_id=${scheme.id}/${scheme.role_hierarchy_master_id}/${foundSchemes.total_count_can_be_availed}/${sumTotalAviled}`
                  );
                } else {
                  showToast("ERROR", "Your Scheme is already been applied.");
                }
              } else {
                showToast("ERROR", "Scheme Avail Limit Reached!");
              }
            } else {
              showToast("ERROR", "You are not eligible");
            }
          } else {
            showToast("ERROR", "Please Renew");
          }
        } else {
          showToast(
            "ERROR",
            "This service is unavailable for the age above 60 years"
          );
        }
      } else {
        showToast(
          "ERROR",
          "This service is unavailable as you have availed for Accident Assistance for the Death"
        );
      }
    } else if (scheme.name === "Thayi Magu Sahaya Hasta") {
      const foundSchemes = schemesAvailed.self.find(
        (data) => data.scheme_name === scheme.name
      );
      const foundSchemesFamily = schemesAvailed.family.find(
        (data) => data.scheme_name === scheme.name
      );
      // const foundRenewal = schemesAvailed.renewal.find(
      //   (data) => data.scheme_name === 'Thayi Magu Sahaya Hasta',
      // );

      // const RenewalChild1 =
      //   foundRenewal &&
      //   foundRenewal.total_renewa_count_can_be_availed_child_1 !==
      //     foundRenewal &&
      //   foundRenewal.child_1_renewal_status;

      // const RenewalChild2 =
      //   foundRenewal &&
      //   foundRenewal.total_renewa_count_can_be_availed_child_2 !==
      //     foundRenewal &&
      //   foundRenewal.child_2_renewal_status;

      const sumTotalAviled =
        foundSchemes.total_aviled_by_user +
        foundSchemesFamily.total_aviled_by_user;

      // const foundAccidentAssistance = schemesAvailed.self.find(
      //   (data) => data.scheme_name === 'Accident Assistance',
      // );

      // const deathApplied = foundAccidentAssistance.death_status;

      // if (deathApplied === 0) {

      if (diffAge < 60 || isSchemeExtended === 1) {
        if (isActiveUser === 1) {
          if (
            users &&
            users.getUserRegistrationDetails.personal_details[0]
              .catalog_value_gender_id === 28
          ) {
            if (
              foundSchemes.total_count_can_be_availed !== sumTotalAviled ||
              childList.length > 0
            ) {
              // if (
              //   foundSchemes.is_pending_for_approval === 0 &&
              //   foundSchemesFamily.is_pending_for_approval === 0
              // ) {
              history.push(
                `/scheme/${scheme.name}_id=${scheme.id}/${scheme.role_hierarchy_master_id}/${foundSchemes.total_count_can_be_availed}/${sumTotalAviled}`
              );
              // } else {
              //   showToast('ERROR', 'Your Scheme is already been applied.');
              // }
            } else {
              showToast("ERROR", "Scheme Avail Limit Reached!");
            }
          } else {
            showToast("ERROR", "You are not eligible");
          }
        } else {
          showToast("ERROR", "Please Renew");
        }
      } else {
        showToast(
          "ERROR",
          "This service is unavailable for the age above 60 years"
        );
      }
      // } else {
      //   showToast(
      //     'ERROR',
      //     'This service is unavailable as you have availed for Accident Assistance for the Death',
      //   );
      // }
    } else if (scheme.name === "Funeral Expense and Death Assistance") {
      const foundSchemes = schemesAvailed.self.find(
        (data) => data.scheme_name === scheme.name
      );
      const foundSchemesFamily = schemesAvailed.family.find(
        (data) => data.scheme_name === scheme.name
      );

      const sumTotalAviled =
        foundSchemes.total_aviled_by_user +
        foundSchemesFamily.total_aviled_by_user;
      if (diffAge < 60 || isSchemeExtended === 1) {
        if (foundSchemes.total_count_can_be_availed !== sumTotalAviled) {
          if (
            foundSchemes.is_pending_for_approval === 0 &&
            foundSchemesFamily.is_pending_for_approval === 0
          ) {
            history.push(
              `/scheme/${scheme.name}_id=${scheme.id}/${scheme.role_hierarchy_master_id}/${foundSchemes.total_count_can_be_availed}/${sumTotalAviled}`
            );
          } else {
            showToast("ERROR", "Your Scheme is already been applied.");
          }
        } else {
          showToast("ERROR", "Scheme Avail Limit Reached!");
        }
      } else {
        showToast(
          "ERROR",
          "This service is unavailable for the age above 60 years"
        );
      }
    } else if (scheme.name === "Pension Scheme") {
      const foundSchemes = schemesAvailed.self.find(
        (data) => data.scheme_name === scheme.name
      );
      const foundSchemesFamily = schemesAvailed.family.find(
        (data) => data.scheme_name === scheme.name
      );
      // console.log(diffAge);
      const sumTotalAviled =
        foundSchemes.total_aviled_by_user +
        foundSchemesFamily.total_aviled_by_user;

      const foundAccidentAssistance = schemesAvailed.self.find(
        (data) => data.scheme_name === "Accident Assistance"
      );

      const deathApplied = foundAccidentAssistance.death_status;

      if (deathApplied === 0) {
        if (users && users.user.pension_eligible_status === 1) {
          if (foundSchemes.total_count_can_be_availed !== sumTotalAviled) {
            if (
              foundSchemes.is_pending_for_approval === 0 &&
              foundSchemesFamily.is_pending_for_approval === 0
            ) {
              history.push(
                `/scheme/${scheme.name}_id=${scheme.id}/${scheme.role_hierarchy_master_id}/${foundSchemes.total_count_can_be_availed}/${sumTotalAviled}`
              );
            } else {
              showToast("ERROR", "Your Scheme is already been applied.");
            }
          } else {
            showToast("ERROR", "Scheme Avail Limit Reached!");
          }
        } else if (diffAge === 60) {
          if (users && users.user.pension_eligible_status === 1) {
            if (foundSchemes.total_count_can_be_availed !== sumTotalAviled) {
              if (
                foundSchemes.is_pending_for_approval === 0 &&
                foundSchemesFamily.is_pending_for_approval === 0
              ) {
                history.push(
                  `/scheme/${scheme.name}_id=${scheme.id}/${scheme.role_hierarchy_master_id}/${foundSchemes.total_count_can_be_availed}/${sumTotalAviled}`
                );
              } else {
                showToast("ERROR", "Your Scheme is already been applied.");
              }
            } else {
              showToast("ERROR", "Scheme Avail Limit Reached!");
            }
          } else {
            showToast(
              "ERROR",
              `${users && users.user.pension_eligible_status_message}`
            );
          }
        } else {
          showToast("ERROR", "Your age should be 60 Years");
        }
      } else {
        showToast(
          "ERROR",
          "This service is unavailable as you have availed for Accident Assistance for the Death"
        );
      }
    } else if (scheme.name === "Continuation of Pension") {
      const foundSchemes = schemesAvailed.self.find(
        (data) => data.scheme_name === scheme.name
      );
      const foundSchemesFamily = schemesAvailed.family.find(
        (data) => data.scheme_name === scheme.name
      );

      const checkPension = schemesAvailed.self.find(
        (data) => data.scheme_name === "Pension Scheme"
      );

      const eligibleForContinuationofPension =
        users?.user?.continuation_of_pension_eligible;

      const sumTotalAviled =
        foundSchemes.total_aviled_by_user +
        foundSchemesFamily.total_aviled_by_user;

      const foundAccidentAssistance = schemesAvailed.self.find(
        (data) => data.scheme_name === "Accident Assistance"
      );

      const deathApplied = foundAccidentAssistance.death_status;

      if (deathApplied === 0) {
        if (diffAge < 61) {
          showToast(
            "ERROR",
            `Your age is ${diffAge}.Not Eligible for continuation of pension`
          );
          return;
        }
        if (
          (checkPension.total_aviled_by_user > 0 &&
            checkPension.is_pending_for_approval === 0) ||
          eligibleForContinuationofPension === 1
        ) {
          // if (moment().month() === 11) {
          if (foundSchemes.total_count_can_be_availed !== sumTotalAviled) {
            if (
              foundSchemes.is_pending_for_approval === 0 &&
              foundSchemesFamily.is_pending_for_approval === 0
            ) {
              history.push(
                `/scheme/${scheme.name}_id=${scheme.id}/${scheme.role_hierarchy_master_id}/${foundSchemes.total_count_can_be_availed}/${sumTotalAviled}`
              );
            } else {
              showToast("ERROR", "Your Scheme is already been applied.");
            }
          } else {
            showToast("ERROR", "Scheme Avail Limit Reached!");
          }
          // } else {
          //   showToast('ERROR', 'Only Available in the month of December');
          // }
        } else {
          showToast("ERROR", "You are not eligible");
        }
      } else {
        showToast(
          "ERROR",
          "This service is unavailable as you have availed for Accident Assistance for the Death"
        );
      }
    } else if (scheme.name === "Disability Pension") {
      const foundSchemes = schemesAvailed.self.find(
        (data) => data.scheme_name === scheme.name
      );
      const foundSchemesFamily = schemesAvailed.family.find(
        (data) => data.scheme_name === scheme.name
      );

      const sumTotalAviled =
        foundSchemes.total_aviled_by_user +
        foundSchemesFamily.total_aviled_by_user;
      const foundSchemeAccident = schemesAvailed.self.find(
        (data) => data.scheme_name === "Accident Assistance"
      );

      const foundAccidentAssistance = schemesAvailed.self.find(
        (data) => data.scheme_name === "Accident Assistance"
      );

      const deathApplied = foundAccidentAssistance.death_status;

      if (deathApplied === 0) {
        if (diffAge < 60 || isSchemeExtended === 1) {
          if (foundSchemeAccident.total_aviled_by_user === 0) {
            if (foundSchemes.total_count_can_be_availed !== sumTotalAviled) {
              if (
                foundSchemes.is_pending_for_approval === 0 &&
                foundSchemesFamily.is_pending_for_approval === 0
              ) {
                history.push(
                  `/scheme/${scheme.name}_id=${scheme.id}/${scheme.role_hierarchy_master_id}/${foundSchemes.total_count_can_be_availed}/${sumTotalAviled}`
                );
              } else {
                showToast("ERROR", "Your Scheme is already been applied.");
              }
            } else {
              showToast("ERROR", "Scheme Avail Limit Reached!");
            }
          } else {
            showToast(
              "ERROR",
              "You are not eligible, since Accident Assistance is already availed."
            );
          }
        } else {
          showToast(
            "ERROR",
            "This service is unavailable for the age above 60 years"
          );
        }
      } else {
        showToast(
          "ERROR",
          "This service is unavailable as you have availed for Accident Assistance for the Death"
        );
      }
    } else if (scheme.name === "Continuation of Disability Pension") {
      const foundSchemes = schemesAvailed.self.find(
        (data) => data.scheme_name === scheme.name
      );
      const foundSchemesFamily = schemesAvailed.family.find(
        (data) => data.scheme_name === scheme.name
      );

      const checkDisabilityPension = schemesAvailed.self.find(
        (data) => data.scheme_name === "Disability Pension"
      );

      const sumTotalAviled =
        foundSchemes.total_aviled_by_user +
        foundSchemesFamily.total_aviled_by_user;

      const foundAccidentAssistance = schemesAvailed.self.find(
        (data) => data.scheme_name === "Accident Assistance"
      );

      const deathApplied = foundAccidentAssistance.death_status;

      if (deathApplied === 0) {
        if (
          (checkDisabilityPension.total_aviled_by_user > 0 &&
            checkDisabilityPension.is_pending_for_approval === 0) ||
          users?.user?.disability_of_pension_eligible === 1
        ) {
          // if (moment().month() === 11) {
          if (foundSchemes.total_count_can_be_availed !== sumTotalAviled) {
            if (
              foundSchemes.is_pending_for_approval === 0 &&
              foundSchemesFamily.is_pending_for_approval === 0
            ) {
              history.push(
                `/scheme/${scheme.name}_id=${scheme.id}/${scheme.role_hierarchy_master_id}/${foundSchemes.total_count_can_be_availed}/${sumTotalAviled}`
              );
            } else {
              showToast("ERROR", "Your Scheme is already been applied.");
            }
          } else {
            showToast("ERROR", "Scheme Avail Limit Reached!");
          }
          // } else {
          //   showToast('ERROR', 'Only Available in the month of December');
          // }
        } else {
          showToast("ERROR", "You are not eligible");
        }
      } else {
        showToast(
          "ERROR",
          "This service is unavailable as you have availed for Accident Assistance for the Death"
        );
      }
    }
  };

  return (
    <>
      <div className="root">
        <Row className="top-div schemes">
          <Row className="topbar-row">
            <Col xs={12} md={8} lg={9} className="top-bar-col">
              <div className="logo-div-profile">
                <a href="/dashboard-user">
                  <img id="logo" src={logo} alt="..." className="logo-img" />
                </a>
                <h1 className="logo-text">
                  <Translate value="header.title" /> <br />
                  <Translate value="header.sub" />
                </h1>
              </div>
            </Col>
            <Col xs={12} md={4} lg={3}>
              <Select
                className="select-language"
                style={{ width: "100%" }}
                variant="outlined"
                labelId="demo-simple-select-required-label"
                value={translate.lang}
                name="lang"
                displayEmpty
                onChange={handleChange}
              >
                <MenuItem value="">
                  <ListItemIcon>
                    <img alt="..." src={language} className="language-img" />
                  </ListItemIcon>
                  <ListItemText primary="Select Language" />
                </MenuItem>
                {/* <MenuItem value="en">English</MenuItem>
                                    <MenuItem value="ka">Kannada</MenuItem> */}
                <MenuItem value="en">
                  <ListItemText primary="English" />
                </MenuItem>
                <MenuItem value="ka">
                  <ListItemText primary="ಕನ್ನಡ" />
                </MenuItem>
              </Select>
            </Col>
          </Row>
        </Row>
      </div>

      <Row className="schemes-title-row">
        <Col xs={12} md={8}>
          <div className="schemes-homebutton-row">
            <a href="/dashboard-user">
              <img className="schemes-homebutton" alt="..." src={HomeButton} />
            </a>
            <h2>
              <Translate value="schemes" />
            </h2>
          </div>
        </Col>
        <Col className="notifications-logout-usericon-labour-schemes">
          <div
            className="usericon-header-logout"
            style={{ marginLeft: "100px" }}
          >
            <img alt="..." src={usericon} className="usericon-img" />
            <p>{users.user !== null && users.user.first_name}</p>
            <Tooltip title="Logout" placement="top-start" arrow interactive>
              <img
                alt="..."
                src={logout}
                className="logout-img"
                onClick={() => {
                  history.push("/login");
                }}
                style={{ marginLeft: "40px" }}
              />
            </Tooltip>
          </div>
        </Col>
      </Row>

      <Row className="search-form schemes container-fluid">
        {/* <p className="mb-4 fieldtitle">Enter Mobile Number / Aadhar Number / Registration ID</p> */}
        {/* <p className="mb-4 fieldtitle searchscheme-title">Search Schemes</p> */}
        <p className="mb-4 fieldtitle searchscheme-title">
          <Translate value="searchschemes" />
        </p>
        <FormControl fullWidth className="phno-field searchbar-schemes">
          <TextField
            placeholder={"Example : Medical Assistance"}
            type="text"
            onChange={(ev) => setQuery(ev.target.value)}
            value={query}
            // error={allValues.searchValueError}
            // helperText={allValues.searchValueError ? "Please enter correct data" : ""}
            variant="outlined"
            InputProps={
              query && query !== ""
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <img
                          alt="..."
                          src={closeIcon}
                          onClick={() => setQuery("")}
                          className="language-img"
                        />
                      </InputAdornment>
                    ),
                  }
                : {
                    startAdornment: (
                      <InputAdornment position="end">
                        <img alt="..." src={search} className="language-img" />
                      </InputAdornment>
                    ),
                  }
            }
          ></TextField>
        </FormControl>
      </Row>
      <Row className="search-form schemes cards container-fluid">
        {filteredSchemes &&
          filteredSchemes.length &&
          filteredSchemes.map(
            (row) =>
              (users?.user?.continuation_of_pension_eligible === 1
                ? row.name === "Continuation of Pension"
                : users?.user?.disability_of_pension_eligible === 1
                  ? row.name === "Continuation of Disability Pension"
                  : row.name === "Medical Assistance" ||
                    row.name === "Assistance For Major Ailments" ||
                    row.name === "Funeral Expense and Death Assistance" ||
                    row.name === "Marriage Assistance" ||
                    row.name === "Disability Pension" ||
                    row.name === "Pension Scheme" ||
                    row.name === "Accident Assistance" ||
                    row.name === "Delivery Assistance" ||
                    row.name === "Thayi Magu Sahaya Hasta" ||
                    row.name === "Continuation of Pension" ||
                    row.name === "Continuation of Disability Pension") && (
                <Col
                  xs={12}
                  sm={4}
                  md={4}
                  className="mapping-cards-col schemes"
                >
                  <div
                    className="mapping-card schemes"
                    onClick={() => handleSchemes(row)}
                  >
                    <img
                      src={
                        row.name === "Marriage Assistance"
                          ? MarriageAssistance
                          : row.name === "Pension Scheme"
                            ? Group
                            : row.name === "Continuation of Pension"
                              ? continuationPension
                              : row.name === "Disability Pension"
                                ? pension
                                : row.name ===
                                    "Continuation of Disability Pension"
                                  ? disability
                                  : row.name === "Accident Assistance"
                                    ? accident
                                    : row.name === "Delivery Assistance"
                                      ? pregnant
                                      : row.name ===
                                          "Funeral Expense and Death Assistance"
                                        ? Funeral
                                        : row.name === "Medical Assistance"
                                          ? Medical2
                                          : row.name ===
                                              "Assistance For Major Ailments"
                                            ? medical
                                            : row.name ===
                                                "Thayi Magu Sahaya Hasta"
                                              ? Maga
                                              : row.name ===
                                                  "Education Assistance 2023-24"
                                                ? education
                                                : row.name ===
                                                    "Shrama Samarthya Toolkit"
                                                  ? Tool
                                                  : null
                      }
                      alt="..."
                    />
                    <p>
                      {translate.kannadaSelected ? row.name_kannada : row.name}
                    </p>
                  </div>
                </Col>
              )
          )}
      </Row>
    </>
  );
};

export default KBOCWWBSchemeHomePage;
